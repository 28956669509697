import gql from 'graphql-tag';
import { getUserId } from '../../localStorages';

export default ({ id, reason, status, executeFutureOrderNow }) => {
  const inputStr = reason
    ? `{ id: "${id}",reason: "${reason}", status: ${status}, executeFutureOrderNow: ${!!executeFutureOrderNow}, userId: "${getUserId()}"}`
    : `{ id: "${id}", status: ${status}, executeFutureOrderNow: ${!!executeFutureOrderNow}, userId: "${getUserId()}"}`;
  const updateOrderStatus = `updateOrderStatus(input: ${inputStr})`;
  return gql`
  mutation {
    ${updateOrderStatus} {
      id
      reason
      status
    }
  }
`;
};
