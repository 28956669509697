import { call, put, takeLatest, all, takeEvery, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import {
  searchOrders,
  getOrderDetail,
  createOrder,
  modifyOrder,
  updateOrderStatus,
  getSubscriptionByAccountId,
  getSubscriptionAssets,
  getServiceUnitsBySubscriptionId,
  getPriceUnitsByServiceUnitId,
  getServiceUnitsByAccountId,
  getBundleDetail,
  getDependentOffers,
  getOmsConfig,
  modifyOmsConfig,
  createOmsConfig,
  getProvSequenceConfig,
  modifyProvSequenceConfig,
  createProvSequenceConfig,
  getFlexAttributesMapping,
  modifyFlexAttributeMapping,
  createFlexAttributeMapping,
  createBillingAccounts,
  processSplitOrder,
  getOrderReasonCodes,
  modifyOrderReasonCodes,
  createOrderReasonCodes,
  getOrderSplitOpportunity,
  saveSplitOpportunity,
  undoProcessSplitOrder,
  searchProvisioningAttributesConfig,
  modifyProvisioningAttributesConfig,
  createProvisioningAttributesConfig,
  buildOrderProvisioningAttributes,
  getGatewayApiMapByName,
  getOrderOmsTasks,
  getOrderProvisioningOutboundTasks,
  getOrderProvisioningInboundTasks,
  processAccountAndOrder,
  getOrderAudit,
  convertJsonToOrder,
} from '../../api';
import { Messages } from '../../constantsApp';
import { formatStringUrl } from '../../utils/utils';
import { queryRequest, mutationRequest } from '../../utils/request';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';
import { makeGetCcpPropertiesConfig } from '../App/selectors';

export function* searchOrdersSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchOrders({ page, size, filter, sort }));
    if (response.searchOrders) {
      yield put(actions.searchOrdersSuccess(response.searchOrders));
    } else {
      yield put(actions.searchOrdersFailure('Failed to Fetch data!'));
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb();
  }
}

export function* getOrderSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOrderDetail(payload));
    if (response.getOrderById) {
      const newOrder = response.getOrderById;
      // if (newOrder.services && newOrder.services.length)
      //   newOrder.services = newOrder.services.map(val => {
      //     const { packageId, bundleId, ...rest } = val;
      //     let isPackage = false;
      //     let isBundle = false;
      //     let isPriceOffer = false;
      //     if (packageId) {
      //       isPackage = true;
      //     } else if (bundleId) {
      //       isBundle = true;
      //     } else {
      //       isPriceOffer = true;
      //     }
      //     return { ...rest, packageId, bundleId, isPackage, isBundle, isPriceOffer };
      //   });
      yield put(actions.getOrderDetailSuccess(newOrder));
      if (cb) cb({ success: true, data: response.getOrderById });
    } else {
      if (cb) cb({ success: false, data: null });
      yield put(actions.getOrderDetailFailure(formatStringUrl(Messages.noOrder, payload)));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getOrderDetailFailure(formatStringUrl(Messages.noOrder, payload)));
  }
}

export function* createOrderSaga({ payload, cb = () => {} }) {
  const { isFromCreate, ...newPayload } = payload;
  yield put(isConnecting());
  try {
    const listProperties = yield select(makeGetCcpPropertiesConfig());
    let isOrderWrapper = false;
    if (listProperties && listProperties.length) {
      const accountOrderWrapperAPI = listProperties.find(val => val.property === 'accountOrderWrapperAPI');
      isOrderWrapper = accountOrderWrapperAPI && accountOrderWrapperAPI.value === 'true';
    }
    const apiCreaete = isOrderWrapper ? processAccountAndOrder : createOrder;
    const dataCreate = isOrderWrapper ? { order: newPayload } : newPayload;
    const response = yield call(mutationRequest, apiCreaete(dataCreate));
    yield put(actions.createOrderSuccess());

    yield put(isEndConnected());
    if (response.createOrder && response.createOrder.id && !isOrderWrapper) {
      yield put(push(`/orders/${response.createOrder.id}/detail`, { data: response.createOrder, isFromCreate }));
      yield cb({ success: true, id: response.createOrder.id });
      toast.success(i18n.t('common:apiMessage.createOrderSuccessfully'));
    }
    if (response.processAccountAndOrder && response.processAccountAndOrder.orderId && isOrderWrapper) {
      yield put(push(`/orders/${response.processAccountAndOrder.orderId}/detail`));
      yield cb({ success: true, id: response.processAccountAndOrder.orderId });
      toast.success(i18n.t('common:apiMessage.createOrderSuccessfully'));
    }
  } catch (err) {
    yield put(isEndConnected());
    // toast(err.message ? err.message : 'Order created failed!', { type: toast.TYPE.ERROR });
    cb({});
  }
}

export function* getServiceUnitsAcSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getServiceUnitsByAccountId(payload));
    if (response.getServiceUnitsByAccountId) {
      if (cb) {
        cb({ success: true, data: response.getServiceUnitsByAccountId });
      }
    } else {
      cb({ success: false, data: response && response.getServiceUnitsByAccountId });
    }
  } catch (err) {
    cb({ success: false, data: '' });
  }
}

export function* modifyOrderSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    const response = yield call(mutationRequest, modifyOrder(dataCreate));
    yield put(actions.modifyOrderSuccess(response.modifyOrder));
    yield cb({ success: true });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.modifyOrderSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    // toast(`Order modify failed! ${err}`, { type: toast.TYPE.ERROR });
    cb({ success: false });
  }
}

export function* updateOrderStatusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updateOrderStatus(payload));
    if (response.updateOrderStatus) {
      yield put(actions.updateOrderStatusSuccess(response.updateOrderStatus));
      if (cb) yield cb({ success: true });
      yield put(isEndConnected());
      toast.success(i18n.t('common:apiMessage.updateOrderStatusSuccessfully'));
    }
  } catch (err) {
    yield put(isEndConnected());
    // toast("Order's status updated failed!", { type: toast.TYPE.ERROR });
    if (cb) cb({ success: false });
  }
}

export function* getSubscriptionByAccountIdSaga({ payload, cb = () => {}, isNoGetAsset }) {
  yield put(isConnecting());

  try {
    yield put(actions.getSubscriptionAssetsSuccess([]));
    const response = yield call(queryRequest, getSubscriptionByAccountId(payload));
    if (response.getSubscriptionByAccountId) {
      if (cb) cb(true, response.getSubscriptionByAccountId);
      yield put(actions.getSubscriptionByAccountIdSuccess(response.getSubscriptionByAccountId));

      // yield response.getSubscriptionByAccountId.map(({ id }) => {
      //   console.info('id', id);
      //   return put(actions.getServiceUnitsBySubscriptionId(id));
      // });
      if (response.getSubscriptionByAccountId && response.getSubscriptionByAccountId.length) {
        if (
          !isNoGetAsset ||
          (isNoGetAsset &&
            response.getSubscriptionByAccountId.length &&
            response.getSubscriptionByAccountId.filter(val => val.status !== 'CLOSED').length === 1)
        ) {
          yield all(
            response.getSubscriptionByAccountId.map(({ id, status }) => {
              if (status === 'CLOSED') return null;
              return put(actions.getSubscriptionAssets({ id, status }));
            })
          );
        }
      }
    } else {
      const message = `Can not get subscription with account id ${payload}`;
      if (cb) cb(false, message);
      yield put(actions.getSubscriptionByAccountIdFailure(`Can not get subscription with account id ${payload}`));
    }
    yield put(isEndConnected());
  } catch (err) {
    const message = `Can not get subscription with account id ${payload}`;
    if (cb) cb(false, message);
    yield put(isEndConnected());
    yield put(actions.getSubscriptionByAccountIdFailure(`Can not get subscription with account id ${payload}`));
  }
}

export function* getSubscriptionAssetsSaga({ payload }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getSubscriptionAssets(payload));
    if (response.getSubscriptionAssets) {
      yield put(actions.getSubscriptionAssetsSuccess(response.getSubscriptionAssets));
    } else {
      yield put(
        actions.getSubscriptionAssetsFailure(`Can not get subscription assets with subscription id ${payload}`)
      );
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getSubscriptionAssetsFailure(`Can not get subscription assets with subscription id ${payload}`));
  }
}

export function* getServiceUnitsBySubscriptionIdSaga({ payload }) {
  try {
    const response = yield call(queryRequest, getServiceUnitsBySubscriptionId(payload));
    if (response.getServiceUnitsBySubscriptionId) {
      yield put(actions.getServiceUnitsBySubscriptionIdSuccess(response.getServiceUnitsBySubscriptionId));
    } else {
      yield put(
        actions.getServiceUnitsBySubscriptionIdFailure(`Can not get service units with subscription id ${payload}`)
      );
    }
  } catch (err) {
    yield put(
      actions.getServiceUnitsBySubscriptionIdFailure(`Can not get service units with subscription id ${payload}`)
    );
  }
}

export function* getPriceUnitsByServiceUnitIdSaga({ payload }) {
  try {
    const response = yield call(queryRequest, getPriceUnitsByServiceUnitId(payload));
    if (response.getPriceUnitsByServiceUnitId) {
      yield put(actions.getPriceUnitsByServiceUnitIdSuccess(response.getPriceUnitsByServiceUnitId));
    } else {
      yield put(actions.getPriceUnitsByServiceUnitIdFailure(`Can not get price units with service unit id ${payload}`));
    }
  } catch (err) {
    yield put(actions.getPriceUnitsByServiceUnitIdFailure(`Can not get price units with service unit id ${payload}`));
  }
}

export function* getBundleDetailSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getBundleDetail(payload));
    if (response.getBundleById) {
      if (cb) cb({ success: true, data: response.getBundleById });
    }
  } catch (err) {
    if (cb) cb({ success: false });
  }
}

export function* getDependentOffersSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDependentOffers(payload));
    if (response.getDependentOffers) {
      if (cb) cb({ success: true, data: response.getDependentOffers });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* searchOmsConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOmsConfig(payload));
    if (response.getOmsConfig) {
      yield put(actions.searchOmsSuccess(response.getOmsConfig));
    }
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.getOmsConfig });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* createOmsConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createOmsConfig(payload));
    if (response.createOmsConfig) {
      if (cb) cb({ success: true, data: response.createOmsConfig });
      toast.success(i18n.t('common:apiMessage.createOrderSequenceSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* modifyOmsConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyOmsConfig(payload));
    if (response.modifyOmsConfig) {
      toast.success(i18n.t('common:apiMessage.modifyOrderSequenceSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyOmsConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

// Provisioning Task Sequence
export function* searchProvisioningTaskConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getProvSequenceConfig(payload));
    if (response.getProvSequenceConfig) {
      yield put(actions.searchProvisioningTaskSequenceSuccess(response.getProvSequenceConfig));
    }
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.getProvSequenceConfig });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* createProvisioningTaskConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createProvSequenceConfig(payload));
    if (response.createProvSequenceConfig) {
      if (cb) cb({ success: true, data: response.createProvSequenceConfig });
      toast.success(i18n.t('common:apiMessage.createProvisioningTaskSequenceSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* modifyProvisioningTaskConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyProvSequenceConfig(payload));
    if (response.modifyProvSequenceConfig) {
      toast.success(i18n.t('common:apiMessage.modifyProvisioningTaskSequenceSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyProvSequenceConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

// Provisioning Mapping
export function* getFlexAttributesMappingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getFlexAttributesMapping(payload));
    if (response.getFlexAttributesMapping) {
      yield put(actions.searchProvisioningTaskSequenceSuccess(response.getFlexAttributesMapping));
    }
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.getFlexAttributesMapping });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* createFlexAttributeMappingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createFlexAttributeMapping(payload));
    if (response.createFlexAttributeMapping) {
      if (cb) cb({ success: true, data: response.createFlexAttributeMapping });
      toast.success(i18n.t('common:apiMessage.createProvisioningMappingSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* modifyFlexAttributeMappingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyFlexAttributeMapping(payload));
    if (response.modifyFlexAttributeMapping) {
      toast.success(i18n.t('common:apiMessage.modifyProvisioningMappingSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyFlexAttributeMapping });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* createBillingAccountsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createBillingAccounts(payload));
    if (response.createBillingAccounts) {
      if (cb) cb({ success: true, data: response.createBillingAccounts });
      toast.success(i18n.t('common:apiMessage.createBillingAccountSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* processSplitOrderSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, processSplitOrder(payload));
    if (response.processSplitOrder) {
      if (cb) cb({ success: true, data: response.processSplitOrder });
      toast.success(i18n.t('common:apiMessage.splitOrderAccountSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

// Order Reason Code
export function* getOrderReasonCodesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOrderReasonCodes(payload));
    if (response.getOrderReasonCodes) {
      yield put(actions.getOrderReasonCodesSuccess(response.getOrderReasonCodes));
    }
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.getOrderReasonCodes });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* createOrderReasonCodesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createOrderReasonCodes(payload));
    if (response.createOrderReasonCodes) {
      if (cb) cb({ success: true, data: response.createOrderReasonCodes });
      toast.success(i18n.t('common:apiMessage.createOrderReasonCodesSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* modifyOrderReasonCodesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyOrderReasonCodes(payload));
    if (response.modifyOrderReasonCodes) {
      toast.success(i18n.t('common:apiMessage.modifyOrderReasonCodesSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyOrderReasonCodes });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getOrderSplitOpportunitySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOrderSplitOpportunity(payload));
    if (response.getOrderSplitOpportunity) {
      if (cb) cb({ success: true, data: response.getOrderSplitOpportunity });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* saveSplitOpportunitySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, saveSplitOpportunity(payload));
    if (response.saveSplitOpportunity) {
      toast.success(i18n.t('common:apiMessage.saveSplitOpportunity'));
      if (cb) cb({ success: true, data: response.saveSplitOpportunity });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* undoProcessSplitOrderSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, undoProcessSplitOrder(payload));
    if (response.undoProcessSplitOrder) {
      toast.success(i18n.t('common:apiMessage.undoProcessSplitOrder'));
      if (cb) cb({ success: true, data: response.undoProcessSplitOrder });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

// Provisioning Attributes Config
export function* searchProvisioningAttributesConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchProvisioningAttributesConfig(payload));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchProvisioningAttributesConfig });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* createProvisioningAttributesConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createProvisioningAttributesConfig(payload));
    if (response.createProvisioningAttributesConfig) {
      if (cb) cb({ success: true, data: response.createProvisioningAttributesConfig });
      toast.success(i18n.t('common:apiMessage.createProvisioningAttributesConfigSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* modifyProvisioningAttributesConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyProvisioningAttributesConfig(payload));
    if (response.modifyProvisioningAttributesConfig) {
      toast.success(i18n.t('common:apiMessage.modifyProvisioningAttributesConfigSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyProvisioningAttributesConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* buildOrderProvisioningAttributesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, buildOrderProvisioningAttributes(payload));
    if (response.buildOrderProvisioningAttributes) {
      if (cb) cb({ success: true, data: response.buildOrderProvisioningAttributes });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getGatewayApiMapByNameSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getGatewayApiMapByName(payload));
    if (response.getGatewayApiMapByName) {
      if (cb) cb({ success: true, data: response.getGatewayApiMapByName });
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* getOrderOmsTasksSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOrderOmsTasks(payload));
    if (response.getOrderOmsTasks) {
      if (cb) cb({ success: true, data: response.getOrderOmsTasks });
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* getOrderProvisioningOutboundTasksSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOrderProvisioningOutboundTasks(payload));
    if (response.getOrderProvisioningOutboundTasks) {
      if (cb) cb({ success: true, data: response.getOrderProvisioningOutboundTasks });
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* getOrderProvisioningInboundTasksSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOrderProvisioningInboundTasks(payload));
    if (response.getOrderProvisioningInboundTasks) {
      if (cb) cb({ success: true, data: response.getOrderProvisioningInboundTasks });
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* getOrderAuditSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOrderAudit(payload));
    if (response.getOrderAudit) {
      if (cb) cb({ success: true, data: response.getOrderAudit });
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* convertJsonToOrderSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, convertJsonToOrder(payload));
    if (response.convertJsonToOrder) {
      if (cb) cb({ success: true, data: response.convertJsonToOrder });
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* ordersSaga() {
  yield takeLatest(types.SEARCH_ORDERS, searchOrdersSaga);
  yield takeLatest(types.GET_ORDERS_DETAIL, getOrderSaga);
  yield takeLatest(types.CREATE_ORDERS, createOrderSaga);
  yield takeLatest(types.MODIFY_ORDER, modifyOrderSaga);
  yield takeLatest(types.UPDATE_ORDER_STATUS, updateOrderStatusSaga);
  yield takeLatest(types.GET_SUBSCRIPTION_BY_ACCOUNT_ID, getSubscriptionByAccountIdSaga);
  yield takeLatest(types.GET_SUBSCRIPTION_ASSETS, getSubscriptionAssetsSaga);
  yield takeEvery(types.GET_SERVICE_UNITS_BY_SUBSCRIPTION_ID, getServiceUnitsBySubscriptionIdSaga);
  yield takeLatest(types.GET_PRICE_UNITS_BY_SERVICE_UNIT_ID, getPriceUnitsByServiceUnitIdSaga);
  yield takeLatest(types.GET_SERVICE_UNITS_AC, getServiceUnitsAcSaga);
  yield takeEvery(types.GET_BUNDLE_BY_ID, getBundleDetailSaga);
  yield takeEvery(types.GET_DEPENDENT_OFFERS, getDependentOffersSaga);
  yield takeLatest(types.SEARCH_OMS_CONFIG, searchOmsConfigSaga);
  yield takeLatest(types.CREATE_OMS_CONFIG, createOmsConfigSaga);
  yield takeLatest(types.MODIFY_OMS_CONFIG, modifyOmsConfigSaga);
  yield takeLatest(types.SEARCH_PROVISIONING_CONFIG, searchProvisioningTaskConfigSaga);
  yield takeLatest(types.CREATE_PROVISIONING_CONFIG, createProvisioningTaskConfigSaga);
  yield takeLatest(types.MODIFY_PROVISIONING_CONFIG, modifyProvisioningTaskConfigSaga);
  yield takeLatest(types.GET_FLEX_ATTRIBUTE_MAPPING, getFlexAttributesMappingSaga);
  yield takeLatest(types.CREATE_FLEX_ATTRIBUTE_MAPPING, createFlexAttributeMappingSaga);
  yield takeLatest(types.MODIFY_FLEX_ATTRIBUTE_MAPPING, modifyFlexAttributeMappingSaga);
  yield takeLatest(types.CREATE_BILLING_ACCOUNTS, createBillingAccountsSaga);
  yield takeLatest(types.PRCESS_SPLIT_ORDER, processSplitOrderSaga);
  yield takeLatest(types.SEARCH_ORDER_REASON_CODE, getOrderReasonCodesSaga);
  yield takeLatest(types.CREATE_ORDER_REASON_CODE, createOrderReasonCodesSaga);
  yield takeLatest(types.MODIFY_ORDER_REASON_CODE, modifyOrderReasonCodesSaga);
  yield takeLatest(types.GET_ORDER_SPLIT_OPPORTUNITY, getOrderSplitOpportunitySaga);
  yield takeLatest(types.SAVE_ORDER_SPLIT_OPPORTUNITY, saveSplitOpportunitySaga);
  yield takeLatest(types.UNDO_PROCESS_SPLIT_ORDER, undoProcessSplitOrderSaga);
  yield takeLatest(types.SEARCH_PROVISIONING_ATTRIBUTES_CONFIG, searchProvisioningAttributesConfigSaga);
  yield takeLatest(types.CREATE_PROVISIONING_ATTRIBUTES_CONFIG, createProvisioningAttributesConfigSaga);
  yield takeLatest(types.MODIFY_PROVISIONING_ATTRIBUTES_CONFIG, modifyProvisioningAttributesConfigSaga);
  yield takeLatest(types.BUILD_ORDER_PROVISIONING_ATTRIBUTES, buildOrderProvisioningAttributesSaga);
  yield takeLatest(types.GET_GATEWAY_API_MAP_BY_NAME_CONFIG, getGatewayApiMapByNameSaga);
  yield takeLatest(types.GET_ORDER_OMS_TASKS, getOrderOmsTasksSaga);
  yield takeLatest(types.GET_ORDER_PROVISIONING_OUTBOUND_TASKS, getOrderProvisioningOutboundTasksSaga);
  yield takeLatest(types.GET_ORDER_PROVISIONING_INBOUND_TASKS, getOrderProvisioningInboundTasksSaga);
  yield takeLatest(types.GET_ORDER_AUDIT, getOrderAuditSaga);
  yield takeLatest(types.CONVERT_JSON_TO_ORDER, convertJsonToOrderSaga);
}
