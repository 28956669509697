/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropsType from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import RouteNames from '../../App/RouteNames';
import {
  searchProvisioningAttributesConfig,
  modifyProvisioningAttributesConfig,
  createProvisioningAttributesConfig,
} from '../actions';
import { makeGetPermissionsOrderManagement } from '../../App/selectors';
import {
  GenericInput,
  FormWithTableItem,
  CollapsibleTable,
  TablePagination,
  DataTable,
} from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import { getPageTotalCount, supportRemoveIndexWithSize } from '../../../utils/utils';
import {
  checkPermissionGetProvisioningAttributes,
  checkPermissionCreateProvisioningAttributes,
  checkPermissionModifyProvisioningAttributes,
} from '../CheckPermission';
import ProvisioningAttributesMapSearchForm from './ProvisioningAttributesMapSearchForm';

const sortTypes = {
  name: {
    asc: 'name_ASC',
    desc: 'name_DESC',
  },
};

const ProvisioningAttributesMap = ({
  searchProvisioningAttributesConfig,
  modifyProvisioningAttributesConfig,
  createProvisioningAttributesConfig,
  permissionOrder,
}) => {
  const { t } = useTranslation('common');
  const [isSearching, setIsSearching] = useState(false);
  const [isModify, setIsModify] = useState(false);
  const [data, setData] = useState([]);
  const [wasValidated, setValidate] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState('');
  const [sorted, setSorted] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const [activeSubTab, setActiveSubTab] = useState({});
  const [activeChildSubTab, setActiveChildSubTab] = useState({});
  const formRef = useRef();
  let modeGetProvisioningAttributes = 0;
  let modeCreateProvisioningAttributes = 0;
  let modeModifyProvisioningAttributes = 0;

  if (permissionOrder && permissionOrder.orderModulePermissions) {
    const listPermission = permissionOrder.orderModulePermissions;
    modeCreateProvisioningAttributes = checkPermissionCreateProvisioningAttributes({ listPermission });
    modeModifyProvisioningAttributes = checkPermissionModifyProvisioningAttributes({ listPermission });
    modeGetProvisioningAttributes = checkPermissionGetProvisioningAttributes({ listPermission });
  }

  const doGeAttributesConfig = () => {
    setIsSearching(true);
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    searchProvisioningAttributesConfig(payload, ({ success, data }) => {
      setIsSearching(false);
      setActiveSubTab({});
      setActiveChildSubTab({});
      setTotalCount(getPageTotalCount({ totalCount, page, size, items: data || [] }));
      if (success) {
        setData(data || []);
      }
    });
  };

  const onToggleSubTab = (index, item, indexItem, key) => {
    if (activeSubTab.index === indexItem) setActiveSubTab({});
    if (activeSubTab.index !== indexItem) {
      setActiveSubTab({ key, index: indexItem });
    }
  };

  const onToggleChildSubTab = (index, item, indexItem, key) => {
    if (activeChildSubTab.index === indexItem) setActiveChildSubTab({});
    if (activeChildSubTab.index !== indexItem) {
      setActiveChildSubTab({ key, index: indexItem });
    }
  };

  useEffect(() => {
    doGeAttributesConfig();
  }, [searchProvisioningAttributesConfig, page, size, filter, sorted]);

  if (!modeGetProvisioningAttributes) return '';

  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  const onChangeTable = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSubTable = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData[activeSubTab.index].attributesList[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSubTableLove = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData[activeSubTab.index].attributesList[activeChildSubTab.index].attributesLovs[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleAddAttributes = () => {
    let newData = cloneDeep(data);
    let lastIndex = 0;
    if (!newData) newData = [];
    newData.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData = [
      {
        index: lastIndex + 1,
        name: null,
        description: null,
      },
      ...newData,
    ];
    setData(newData);
  };

  const onChangeDefaultValue = ({ item, value }) => {
    try {
      const newData = cloneDeep(data);
      newData[activeSubTab.index].attributesList[activeChildSubTab.index].defaultValue = value ? item.code : null;
      if (value) newData[activeSubTab.index].attributesList[activeChildSubTab.index].value = null;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleAddSubAttributes = () => {
    const newData = cloneDeep(data);
    let lastIndex = 0;
    if (!newData[activeSubTab.index].attributesList) newData[activeSubTab.index].attributesList = [];
    newData[activeSubTab.index].attributesList.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData[activeSubTab.index].attributesList = [
      ...newData[activeSubTab.index].attributesList,
      {
        index: lastIndex + 1,
        name: null,
        description: null,
      },
    ];
    setData(newData);
  };

  const onHandleAddSubAttributesLov = () => {
    const newData = cloneDeep(data);
    let lastIndex = 0;
    if (!newData[activeSubTab.index].attributesList[activeChildSubTab.index].attributesLovs)
      newData[activeSubTab.index].attributesList[activeChildSubTab.index].attributesLovs = [];
    newData[activeSubTab.index].attributesList[activeChildSubTab.index].attributesLovs.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData[activeSubTab.index].attributesList[activeChildSubTab.index].attributesLovs = [
      ...newData[activeSubTab.index].attributesList[activeChildSubTab.index].attributesLovs,
      {
        index: lastIndex + 1,
        name: null,
        description: null,
        isNew: true,
      },
    ];
    setData(newData);
  };

  const onRemoveSubItem = ({ index }) => {
    const newData = cloneDeep(data);
    try {
      if (newData[activeSubTab.index].attributesList[activeChildSubTab.index].attributesLovs[index].isNew) {
        newData[activeSubTab.index].attributesList[activeChildSubTab.index].attributesLovs.splice(index, 1);
      } else {
        newData[activeSubTab.index].attributesList[activeChildSubTab.index].attributesLovs[index] = {
          index: newData[activeSubTab.index].attributesList[activeChildSubTab.index].attributesLovs[index].index,
        };
      }
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  let headerTable = [
    {
      name: 'name',
      label: 'label.name',
      sortable: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.name}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="name"
        />
      ),
    },
    {
      name: 'description',
      label: 'label.description',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="description"
          type="textarea"
        />
      ),
    },
    {
      name: 'viewLov ',
      label: 'label.addAttributes',
      render: (colName, item, idx, indexParent, activeTab) => {
        return (
          <button
            type="button"
            className="btn-expand-table mr-3"
            onClick={evt => onToggleSubTab(idx, item, idx, 'addAttributes')}
            // disabled={!item.locations || !item.locations.length}
          >
            <i
              className={`fa ${
                activeSubTab && activeSubTab.key === 'addAttributes' && activeSubTab.index === idx
                  ? 'fa-minus'
                  : 'fa-plus'
              }`}
            />
          </button>
        );
      },
    },
  ];

  if (modeModifyProvisioningAttributes) {
    headerTable = [
      {
        name: 'saveConfig',
        label: 'label.apply',
        render: (colName, item, index) => (
          <button
            type="button"
            disabled={modeModifyProvisioningAttributes === 1}
            className="button x-small apply-btn"
            onClick={() => onHandleApply({ item, index })}
          >
            {t('common:label.apply')}
          </button>
        ),
      },
      ...headerTable,
    ];
  }

  const headerTableAttributesList = [
    {
      name: 'name',
      label: 'label.name',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.name}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeSubTable({ name, value, index: idx })}
          name="name"
        />
      ),
    },
    {
      name: 'value',
      label: 'label.value',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.value}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeSubTable({ name, value, index: idx })}
          name="value"
          disabled={item.attributesLovs && item.attributesLovs.length}
        />
      ),
    },
    // {
    //   name: 'defaultValue',
    //   label: 'label.defaultValue',
    //   render: (colName, item, idx) => (
    //     <GenericInput
    //       value={item.defaultValue}
    //       wrapperClass="col-md-12"
    //       onChange={({ name, value }) => onChangeSubTable({ name, value, index: idx })}
    //       name="defaultValue"
    //     />
    //   ),
    // },
    {
      name: 'isPricingValueStatic',
      label: 'label.staticValue',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.isPricingValueStatic}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeSubTable({ name, value, index: idx })}
          name="isPricingValueStatic"
          type="checkbox"
        />
      ),
    },
    {
      name: 'viewLov ',
      label: 'label.viewLov',
      render: (colName, item, idx) => {
        return (
          <button
            type="button"
            className="btn-expand-table mr-3"
            onClick={() => onToggleChildSubTab(idx, item, idx, 'viewLov')}
            disabled={item && item.value}
          >
            <i
              className={`fa ${
                activeChildSubTab && activeChildSubTab.key === 'viewLov' && activeChildSubTab.index === idx
                  ? 'fa-minus'
                  : 'fa-plus'
              }`}
            />
          </button>
        );
      },
    },
  ];

  const headerTableViewLov = [
    {
      name: 'code',
      label: 'label.value',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.code}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeSubTableLove({ name, value, index: idx })}
          name="code"
        />
      ),
    },
    {
      name: 'description',
      label: 'label.description',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeSubTableLove({ name, value, index: idx })}
          name="description"
          type="textarea"
        />
      ),
    },
    {
      name: 'defaultValue',
      label: 'label.defaultValue',
      render: (colName, item, idx) => (
        <GenericInput
          value={
            data &&
            data.length &&
            activeSubTab &&
            activeSubTab.index !== -1 &&
            activeChildSubTab &&
            activeChildSubTab.index !== -1 &&
            data[activeSubTab.index] &&
            data[activeSubTab.index].attributesList &&
            data[activeSubTab.index]?.attributesList[activeChildSubTab.index] &&
            data[activeSubTab.index]?.attributesList[activeChildSubTab.index].defaultValue === item.code
          }
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeDefaultValue({ name, value, index: idx, item })}
          name="defaultValue"
          type="checkbox"
        />
      ),
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, index) => {
        return (
          <div className="form-group col-md-12">
            <button type="button" className="btn-phone" onClick={() => onRemoveSubItem({ index })}>
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  const onHandleSubmitSearch = filter => {
    setFilter(filter);
    setPage(0);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onHandleApply = ({ index, item }) => {
    setValidate(true);
    if (!validate(true)) {
      return false;
    }
    const newPayload = cloneDeep(item);
    if (!item?.id) {
      const { index, ...createPayload } = item;
      if (createPayload && createPayload.attributesList) {
        createPayload.attributesList = createPayload.attributesList.map(val => {
          const { index, ...attributes } = val;

          if (attributes && attributes.attributesLovs) {
            attributes.attributesLovs = attributes.attributesLovs.map(att => {
              const { index, isNew, ...attributeslovs } = att;
              return attributeslovs;
            });
          }
          return attributes;
        });
      }
      return createProvisioningAttributesConfig(createPayload, ({ success }) => {
        if (success) {
          doGeAttributesConfig();
          setValidate(false);
        }
      });
    }
    if (newPayload && newPayload.attributesList) {
      newPayload.attributesList = newPayload.attributesList.map(val => {
        const { ...attributes } = val;

        if (attributes && attributes.attributesLovs) {
          attributes.attributesLovs = supportRemoveIndexWithSize({ data: attributes.attributesLovs });
        }
        return attributes;
      });
    }

    return modifyProvisioningAttributesConfig(newPayload, ({ success }) => {
      if (success) {
        doGeAttributesConfig();
        setValidate(false);
      }
    });
  };
  return (
    <div className="col-md-12 mb-30">
      <PageTitle
        linkTo={RouteNames.searchAndList.path}
        titleBtn={t('label.back')}
        items={[{ name: `${t('orderPage:sidebar.provisioningAttributes')}` }]}
      />
      <br />
      <div className="col-md-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="repeater-file">
              <div>
                <ProvisioningAttributesMapSearchForm onSubmit={onHandleSubmitSearch} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormWithTableItem title={`${t('orderPage:sidebar.provisioningAttributes')}`} subClass="border-bottom">
        <br />
        <form
          ref={formRef}
          noValidate
          className={`col-md-12 p-2 needs-validation ${wasValidated ? 'was-validated' : ''}`}
          // onSubmit={onSubmit}
        >
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateProvisioningAttributes === 2 && (
              <button
                type="button"
                className="button button-border black x-small"
                onClick={onHandleAddAttributes}
              >
                +
                {t('label.addConfig')}
              </button>
            )}
            {/* {modeModifyUomConfig !== 0 && (
              <button
                disabled={modeModifyUomConfig === 1}
                type="submit"
                className="button button-border x-small float-right"
              >
                {t('label.saveConfig')}
              </button>
            )} */}
            <button
              type="button"
              onClick={() => doGeAttributesConfig()}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div className="group-collapsible">
            <CollapsibleTable
              columns={headerTable}
              isSupportRemoveIndex
              indexViewer={activeSubTab && activeSubTab.index !== -1 ? activeSubTab.index : -1}
              data={data && data.length ? data : []}
              isLoading={isSearching}
              sorted={sorted}
              onSort={onSortColumn}
              isFixedHeaderTable
              isViewOnly
            >
              <CollapsibleTable
                columns={headerTableAttributesList}
                indexViewer={activeChildSubTab && activeChildSubTab.index !== -1 ? activeChildSubTab.index : -1}
                data={
                  data && data.length && activeSubTab && activeSubTab.index !== -1 && data[activeSubTab.index]
                    ? data[activeSubTab.index].attributesList || []
                    : []
                }
              >
                <DataTable
                  columns={headerTableViewLov}
                  data={
                    data &&
                    data.length &&
                    activeSubTab &&
                    activeSubTab.index !== -1 &&
                    activeChildSubTab &&
                    activeChildSubTab.index !== -1 &&
                    data[activeSubTab.index] &&
                    data[activeSubTab.index].attributesList &&
                    data[activeSubTab.index]?.attributesList[activeChildSubTab.index]?.attributesLovs
                      ? data[activeSubTab.index]?.attributesList[activeChildSubTab.index]?.attributesLovs || []
                      : []
                  }
                  isSupportRemoveIndex
                />
                <button
                  type="button"
                  onClick={onHandleAddSubAttributesLov}
                  className="button mb-4 button-border ml-3 black x-small"
                >
                  +
                  {t('label.addNewRow')}
                </button>
              </CollapsibleTable>
              <button
                type="button"
                onClick={onHandleAddSubAttributes}
                className="button mb-4 button-border ml-3 black x-small"
              >
                +
                {t('label.addNewRow')}
              </button>
            </CollapsibleTable>
          </div>
        </form>
      </FormWithTableItem>
      <br />
      <div className="mb-30">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>
    </div>
  );
};

ProvisioningAttributesMap.propTypes = {
  searchProvisioningAttributesConfig: PropsType.func.isRequired,
  modifyProvisioningAttributesConfig: PropsType.func.isRequired,
  createProvisioningAttributesConfig: PropsType.func.isRequired,
};

ProvisioningAttributesMap.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  permissionOrder: makeGetPermissionsOrderManagement() || {},
});

export default connect(mapStateToProps, {
  searchProvisioningAttributesConfig,
  modifyProvisioningAttributesConfig,
  createProvisioningAttributesConfig,
})(ProvisioningAttributesMap);
