export const SEARCH_ORDERS = 'app/orders/search_orders';
export const SEARCH_ORDERS_SUCCESS = 'app/orders/search_orders_success';
export const SEARCH_ORDERS_FAILURE = 'app/orders/search_orders_failure';

export const SET_PARAMS = 'app/orders/set_params';

export const GET_ORDERS_DETAIL = 'app/orders/get_orders_detail';
export const GET_ORDERS_DETAIL_SUCCESS = 'app/orders/get_orders_detail_success';
export const GET_ORDERS_DETAIL_FAILURE = 'app/orders/get_orders_detail_failure';

export const CREATE_ORDERS = 'app/orders/create_orders';
export const CREATE_ORDERS_SUCCESS = 'app/orders/create_orders_success';
export const CREATE_ORDERS_FAILURE = 'app/orders/create_orders_failure';

export const MODIFY_ORDER = 'app/orders/modify_item';
export const MODIFY_ORDER_SUCCESS = 'app/orders/modify_order_success';

export const UPDATE_ORDER_STATUS = 'app/orders/update_order_status';
export const UPDATE_ORDER_STATUS_SUCCESS = 'app/orders/update_item_order_success';

export const GET_SUBSCRIPTION_BY_ACCOUNT_ID = 'app/orders/get_subscription_by_account_id';
export const GET_SUBSCRIPTION_BY_ACCOUNT_ID_SUCCESS = 'app/orders/get_subscription_by_account_id_success';
export const GET_SUBSCRIPTION_BY_ACCOUNT_ID_FAILURE = 'app/orders/get_subscription_by_account_id_failure';

export const GET_SUBSCRIPTION_ASSETS = 'app/orders/get_subscription_assets';
export const GET_SUBSCRIPTION_ASSETS_SUCCESS = 'app/orders/get_subscription_assets_success';
export const GET_SUBSCRIPTION_ASSETS_FAILURE = 'app/orders/get_subscription_assets_failure';

export const GET_SERVICE_UNITS_BY_SUBSCRIPTION_ID = 'app/orders/get_service_units_by_subscription_id';
export const GET_SERVICE_UNITS_BY_SUBSCRIPTION_ID_SUCCESS = 'app/orders/get_service_units_by_subscription_id_success';
export const GET_SERVICE_UNITS_BY_SUBSCRIPTION_ID_FAILURE = 'app/orders/get_service_units_by_subscription_id_failure';

export const GET_PRICE_UNITS_BY_SERVICE_UNIT_ID = 'app/orders/get_service_units_by_service_id';
export const GET_PRICE_UNITS_BY_SERVICE_UNIT_ID_SUCCESS = 'app/orders/get_service_units_by_service_id_success';
export const GET_PRICE_UNITS_BY_SERVICE_UNIT_ID_FAILURE = 'app/orders/get_service_units_by_service_id_failure';

export const GET_SERVICE_UNITS_AC = 'app/order/get_service_units_ac';
export const GET_SERVICE_UNITS_AC_SUCCESS = 'app/order/get_service_units_ac_success';
export const GET_SERVICE_UNITS_AC_FAILURE = 'app/order/get_service_units_ac_failure';

export const GET_BUNDLE_BY_ID = 'GET_BUNDLE_BY_ID';
export const GET_BUNDLE_BY_ID_SUCCESS = 'GET_BUNDLE_BY_ID_SUCCESS';

export const GET_DEPENDENT_OFFERS = 'app/orders/getDependentOffers';

export const SEARCH_OMS_CONFIG = 'SEARCH_OMS_CONFIG';
export const SEARCH_OMS_CONFIG_SUCCESS = 'SEARCH_OMS_CONFIG_SUCCESS';
export const MODIFY_OMS_CONFIG = 'MODIFY_OMS_CONFIG';
export const CREATE_OMS_CONFIG = 'CREATE_OMS_CONFIG';

export const SEARCH_PROVISIONING_CONFIG = 'SEARCH_PROVISIONING_CONFIG';
export const SEARCH_PROVISIONING_CONFIG_SUCCESS = 'SEARCH_PROVISIONING_CONFIG_SUCCESS';
export const MODIFY_PROVISIONING_CONFIG = 'MODIFY_PROVISIONING_CONFIG';
export const CREATE_PROVISIONING_CONFIG = 'CREATE_PROVISIONING_CONFIG';

export const GET_FLEX_ATTRIBUTE_MAPPING = 'GET_FLEX_ATTRIBUTE_MAPPING';
export const GET_FLEX_ATTRIBUTE_MAPPING_SUCCESS = 'GET_FLEX_ATTRIBUTE_MAPPING_SUCCESS';
export const MODIFY_FLEX_ATTRIBUTE_MAPPING = 'MODIFY_FLEX_ATTRIBUTE_MAPPING';
export const CREATE_FLEX_ATTRIBUTE_MAPPING = 'CREATE_FLEX_ATTRIBUTE_MAPPING';

export const CREATE_BILLING_ACCOUNTS = 'CREATE_BILLING_ACCOUNTS';
export const PRCESS_SPLIT_ORDER = 'PRCESS_SPLIT_ORDER';

export const SEARCH_ORDER_REASON_CODE = 'SEARCH_ORDER_REASON_CODE';
export const SEARCH_ORDER_REASON_CODE_SUCCESS = 'SEARCH_ORDER_REASON_CODE_SUCCESS';
export const MODIFY_ORDER_REASON_CODE = 'MODIFY_ORDER_REASON_CODE';
export const CREATE_ORDER_REASON_CODE = 'CREATE_ORDER_REASON_CODE';

export const GET_ORDER_SPLIT_OPPORTUNITY = 'GET_ORDER_SPLIT_OPPORTUNITY';
export const SAVE_ORDER_SPLIT_OPPORTUNITY = 'SAVE_ORDER_SPLIT_OPPORTUNITY';
export const UNDO_PROCESS_SPLIT_ORDER = 'UNDO_PROCESS_SPLIT_ORDER';

export const SEARCH_PROVISIONING_ATTRIBUTES_CONFIG = 'SEARCH_PROVISIONING_ATTRIBUTES_CONFIG';
export const CREATE_PROVISIONING_ATTRIBUTES_CONFIG = 'CREATE_PROVISIONING_ATTRIBUTES_CONFIG';
export const MODIFY_PROVISIONING_ATTRIBUTES_CONFIG = 'MODIFY_PROVISIONING_ATTRIBUTES_CONFIG';

export const BUILD_ORDER_PROVISIONING_ATTRIBUTES = 'BUILD_ORDER_PROVISIONING_ATTRIBUTES';
export const GET_GATEWAY_API_MAP_BY_NAME_CONFIG = 'GET_GATEWAY_API_MAP_BY_NAME_CONFIG';

export const GET_ORDER_OMS_TASKS = 'GET_ORDER_OMS_TASKS';
export const GET_ORDER_PROVISIONING_OUTBOUND_TASKS = 'GET_ORDER_PROVISIONING_OUTBOUND_TASKS';
export const GET_ORDER_PROVISIONING_INBOUND_TASKS = 'GET_ORDER_PROVISIONING_INBOUND_TASKS';
export const GET_ORDER_AUDIT = 'GET_ORDER_AUDIT';
export const CONVERT_JSON_TO_ORDER = 'CONVERT_JSON_TO_ORDER';