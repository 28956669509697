import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import { isEmpty, cloneDeep, remove, union, filter, size, map, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  getAddressesForm,
  convertFieldInfo2New,
  messageRoleBilling,
  injectValueFromConfig,
  injectValueFromConfigWithLabel,
  validate,
} from '../../../utils/utils';
import FormCollapse from '../../../components/form/FormCollapse';
import Footer from '../../../components/Footer';
import constant, { collapsibleAddress, additionalAddressInformation } from '../constants';
import Sidebar from '../Sidebar';
import { GenericInput } from '../../../components/common';
import {
  CustomerForm,
  CustomerAttribute,
  CreatePaymentProfile,
  // ModalSelectCreditProfileIndex,
} from '../../../components/CustomerHub';
import {
  createAccount,
  getAccountDetail,
  getCustomAttributesByObjectType,
  getUsedCfdiByTaxRegion,
  getAccountDetailForSelectParent,
} from '../actions';
import {
  getCcpPropertiesConfig,
  getCurrencyConfigApp,
  getPaymentConfigByTypeApp,
  getConfigOperatorByServiceType,
} from '../../App/actions';
import ModalParentId from './ModalSelectItemId';
import RouteName from '../../App/RouteNames';
import PageTitle from '../../../components/PageTitle';
import {
  makeGetPermissionsCustomerManagement,
  makeGetCurrencyConfig,
  makeGetPermissionsRating,
  makeGetPermissionsBilling,
  makeGetPermissionsCollectionManagement,
} from '../../App/selectors';
import {
  checkPermissionCreateCustomer,
  checkPermissionSearchAccount,
  checkPermissionSetCreditLimit,
  checkPermissionGetDelayedBillingConfig,
} from '../CheckPermission';
import CustomerAttributePage from '../CustomerAttribute';
import CreditProfilePage from '../CreditProfile';
import ModalCollectionAgentByType from '../../../components/common/ModalCollectionAgentByType';
import QuickCreate from './QuickCreate';
import ModalSearchCollectionProfile from '../../../components/common/ModalSearchCollectionProfile';
import CheckFieldRole from '../../../components/CustomerHub/CheckFieldRole';

let customerPath = RouteName.customersNew.path;

const defaultContacts = [
  {
    phones: [
      {
        number: '',
        type: 'WORK',
      },
    ],
    roles: ['BILLING'],
  },
];

const defaultAddresses = [
  {
    city: '',
    state: '',
    country: '',
    roles: ['BILLING'],
  },
];

const CreateAccountForm = ({
  accountDetail,
  t,
  onHandleChange,
  ToggleModal,
  isParentAccount,
  onChangeSwitch,
  isNonPaying,
  activeTab,
  onHandleSubmit,
  modeCreateCustomer,
  isOpenModal,
  parentItemId,
  onSelectParentId,
  // isOpenModalSelectCreditProfile,
  // onSelectedIndexCreditProfile,
  isOpenModalCollectionAgent,
  onSelectedCollectionAgent,
  onToggleTab,
  renderItem,
  // onSelectCreditProfileIndex,
  onToggleModalCollectionAgent,
  isPopup,
  onToggleModal,
}) => {
  return (
    <div>
      <div className="col-md-12 row">
        <GenericInput
          label={t('common:label.accountId')}
          value={accountDetail.id}
          name="id"
          onChange={onHandleChange}
          wrapperClass="col-md-3"
        />
        <GenericInput
          label={`${t('common:label.parentId')} (${t('common:label.selectIfYouNeedToCreateChildAccount')})`}
          value={accountDetail.parentId}
          name="parentId"
          onChange={() => {}}
          fa="fa fa-external-link"
          onClick={ToggleModal}
          wrapperClass="col-md-3"
        />
        <GenericInput
          label={t('common:label.accountCategory')}
          value={accountDetail.accountCategory}
          name="accountCategory"
          onChange={onHandleChange}
          wrapperClass="col-md-3"
          tOptions="selections:accountCategory"
          type="select"
        />
        {isParentAccount && (
          <div className="float-right pl-20 parent-account">
            <p className="card-text text-dark">{accountDetail.id}</p>
            <div className="checkbox checbox-switch switch-danger">
              <label htmlFor="switch">
                <b>{t('common:label.createNonPaying')}</b>
                <input id="switch" type="checkbox" name="switch21" onChange={onChangeSwitch} checked={isNonPaying} />
                <span className="label-switch" />
              </label>
            </div>
          </div>
        )}
      </div>
      <br />
      <div className="col-sm-12 customer-new">
        {constant.newAccount.collapsibleMenu.map(item => (
          <FormCollapse
            key={item.title}
            label="First Name"
            isActive={item.state === activeTab.name && activeTab.isActive}
            title={t(item.title) || ''}
            state={item.state}
            onToggleTab={onToggleTab}
          >
            {renderItem(item)}
          </FormCollapse>
        ))}
      </div>

      <div className="col-sm-12 mb-30 btn-account">
        {modeCreateCustomer === 2 && (
          <button type="button" className="button x-small float-right" onClick={onHandleSubmit}>
            {t('common:label.createAccount')}
          </button>
        )}
        {onToggleModal && (
          <button type="button" className="button mr-2 button-border black x-small float-right" onClick={onToggleModal}>
            {t('common:label.cancel')}
          </button>
        )}
      </div>
      <ModalParentId
        isOpen={isOpenModal}
        selectedParentId={parentItemId}
        onSelect={onSelectParentId}
        onCancel={ToggleModal}
        toggleModal={ToggleModal}
      />
      {/* <ModalSelectCreditProfileIndex
        isOpen={isOpenModalSelectCreditProfile}
        selectedId={accountDetail.creditProfileIndex || ''}
        onSelect={onSelectedIndexCreditProfile}
        onCancel={onSelectCreditProfileIndex}
        toggleModal={ToggleModal}
      /> */}
      <ModalCollectionAgentByType
        isOpen={isOpenModalCollectionAgent}
        selectedParentId={accountDetail.collectionAgent || null}
        onSelect={onSelectedCollectionAgent}
        onCancel={onToggleModalCollectionAgent}
        toggleModal={onToggleModalCollectionAgent}
        defaultSearchParams={{ category: 'AGENT' }}
      />
      {!isPopup && <Footer className="footer-bottom" />}
    </div>
  );
};

class CustomerNew extends PureComponent {
  formRef = React.createRef();

  constructor() {
    super();
    this.state = {
      activeTab: {
        name: 'Create Account Info',
        isActive: true,
      },
      isOpenModal: false,
      isOpenModalSelectCreditProfile: false,
      isNonPaying: false,
      isParentAccount: false,
      isReferenceContact: false,
      isReferenceAddress: false,
      defaultParentDetail: {},
      parentAddresses: [],
      parentContacts: [],
      accountDetail: {
        contacts: [...defaultContacts],
        addresses: [...defaultAddresses],
        paymentProfiles: [{}],
        billingProfiles: [{ duration: '1' }],
        customAttributes: [],
        accountCategory: 'POSTPAID',
      },
      attributesType: [],
      defaulFilter: {},
      currencyOptions: [],
      landmarkLabel: '',
      extralineLabel: '',
      wasValidated: false,
      isShowPhones: false,
      isSupport31DayBilling: false,
      isOpenModalCollectionProfile: false,
      collectionProfileSelected: null,
      activeAddressTab: {},
    };
  }

  componentDidMount() {
    const {
      getCustomAttributesByObjectType,
      getCcpPropertiesConfig,
      location,
      getCurrencyConfigApp,
      currencyConfig,
      getPaymentConfigByTypeApp,
    } = this.props;
    const { currencyOptions } = this.state;
    if (location && location.state && location.state.filter) {
      this.setState({ defaulFilter: location.state.filter });
    }
    getPaymentConfigByTypeApp('PAYMENT_TERMS', ({ success, data }) => {
      if (success && data.paymentTerms) {
        const paymentTermOptions = data.paymentTerms.map(val => {
          return { label: val.paymentTerm, value: val.paymentTerm };
        });
        this.setState({ paymentTermOptions });
      }
    });
    getCustomAttributesByObjectType(({ data }) => {
      if (data) {
        this.setState({
          attributesType: filter(data.attributes, item => item.mandatory === 'Y').map(item => item.name) || [],
        });
      }
    });
    if ((!currencyConfig || !currencyConfig.id) && (!currencyOptions || !currencyOptions.length)) {
      getCurrencyConfigApp('', ({ success, data }) => {
        if (success && data && data.currencyListConfig) {
          const currencyOptions = data.currencyListConfig.map(val => ({
            label: `${val.name} (${val.currencyId})`,
            value: val.currencyId,
          }));
          this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
        }
      });
    } else {
      const currencyOptions = currencyConfig.currencyListConfig.map(val => ({
        label: val.name,
        value: val.currencyId,
      }));

      this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
    }

    getCcpPropertiesConfig('', ({ success, data }) => {
      if (success) {
        const { accountDetail } = this.state;
        let newAccountData = cloneDeep(accountDetail);
        const defaultBillingFrequency = data.ccpPropertyList.find(val => val.property === 'billingFrequency');
        const defaultCustomerSegment = data.ccpPropertyList.find(val => val.property === 'customerSegment');
        const support293031DayBilling = data.ccpPropertyList.find(val => val.property === 'support29_30_31DayBilling');
        const urlTokenCreditCard = window.location.href;
        if (
          urlTokenCreditCard.indexOf('id=') === -1 &&
          urlTokenCreditCard.indexOf('token=') === -1 &&
          urlTokenCreditCard.indexOf('expiryDate=') === -1
        ) {
          const infoFields = [
            { label: 'customerSegment', value: 'customerSegment' },
            { label: 'accountType', value: 'type' },
            { label: 'accountSubType', value: 'subType' },
            { label: 'salesChannel', value: 'salesChannel' },
            { label: 'marketSegment', value: 'marketSegment' },
            { label: 'sellingCompany', value: 'sellingCompany' },
            { label: 'defaultCurrency', value: 'currency' },
            { label: 'legalEntity', value: 'legalEntity' },
          ];
          if (defaultCustomerSegment && defaultCustomerSegment.value !== 'B2C') {
            infoFields.push({
              label: 'lineOfBusiness',
              value: 'lineOfBusiness',
            });
          }

          const newInfo = injectValueFromConfigWithLabel({
            dataConfig: data.ccpPropertyList,
            listFields: infoFields,
            defaultData: accountDetail,
          });
          newAccountData = newInfo;
          // const billingListFields = ['invoiceType', 'invoiceDelivery', 'billingDom', 'billingFrequency'];
          const billingListFields = ['invoiceType', 'invoiceDelivery', 'billingFrequency'];
          if (defaultBillingFrequency && defaultBillingFrequency.value !== 'DAYS') billingListFields.push('billingDom');
          const newBilling = injectValueFromConfig({
            dataConfig: data.ccpPropertyList,
            listFields: billingListFields,
            defaultData: accountDetail.billingProfiles[0],
          });
          newAccountData.billingProfiles[0] = newBilling;

          const newPayment = injectValueFromConfig({
            dataConfig: data.ccpPropertyList,
            listFields: ['paymentMethod', 'paymentTerm'],
            defaultData: accountDetail.paymentProfiles[0],
          });
          newAccountData.paymentProfiles[0] = newPayment;

          const newContact = injectValueFromConfig({
            dataConfig: data.ccpPropertyList,
            listFields: ['phoneType'],
            defaultData: {},
          });
          newAccountData.contacts[0] = {
            phones: [
              {
                number: '',
                type: newContact.phoneType,
              },
            ],
            roles: ['BILLING'],
          };

          const newAddress = injectValueFromConfigWithLabel({
            dataConfig: data.ccpPropertyList,
            listFields: [{ label: 'defaultCountry', value: 'country' }],
            defaultData: accountDetail.addresses[0],
          });

          newAccountData.addresses[0] = newAddress;
        }

        const landmarkLabelProperty = data.ccpPropertyList.find(val => val.property === 'landmarkLabel');
        const extralineLabelProperty = data.ccpPropertyList.find(val => val.property === 'extralineLabel');
        this.setState({
          accountDetail: newAccountData,
          landmarkLabel: landmarkLabelProperty && landmarkLabelProperty.value ? landmarkLabelProperty.value : '',
          extralineLabel: extralineLabelProperty && extralineLabelProperty.value ? extralineLabelProperty.value : '',
          quickCreateData: newAccountData,
          isSupport31DayBilling: support293031DayBilling && support293031DayBilling.value === 'true',
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: { params },
    } = nextProps;
    const { childRoute } = params || {};
    if (childRoute) {
      this.setState({ activeTab: { name: this.onToggleTabFromChildRoute(childRoute), isActive: true } });
    }
  }

  onToggleTabFromChildRoute = childRoute => {
    switch (childRoute) {
      case 'info':
        return 'Create Account Info';
      case 'contacts':
        return 'Create Contact';
      case 'addresses':
        return 'Create Address';
      case 'payment-profile':
        return 'Create Payment Profile';
      case 'profile-billing':
        return 'Create Billing Profile';
      case 'custom-attributes':
        return 'Create Customer Attributes';
      default:
        return 'Create Account Info';
    }
  };

  onSwitchRoute = tabName => {
    switch (tabName) {
      case 'Create Account Info':
        return 'info';
      case 'Create Contact':
        return 'contacts';
      case 'Create Address':
        return 'addresses';
      case 'Create Payment Profile':
        return 'payment-profile';
      case 'Create Billing Profile':
        return 'profile-billing';
      case 'Create Customer Attributes':
        return 'custom-attributes';
      default:
        return 'info';
    }
  };

  onHandleChange = ({ value = '', name = '', fieldObj = '' }) => {
    const { accountDetail } = this.state;
    const newData = cloneDeep(accountDetail);
    if (fieldObj) {
      newData[fieldObj][0][name] = value;
      if (name === 'billingFrequency' && value === 'DAYS') newData[fieldObj][0].billingDom = null;
    } else {
      if (name === 'customerSegment' && value === 'B2C') {
        newData.commercialName = null;
        newData.clientId = null;
        newData.lineOfBusiness = null;
      }
      if (name === 'type' && value !== 'EMPLOYEE') {
        newData.employeeId = null;
      }
      newData[name] = value;
    }

    this.setState({ accountDetail: newData });
  };

  onHandleChangeContact = ({ value = '', name = '', index = 0 }) => {
    const { accountDetail } = this.state;
    const newData = cloneDeep(accountDetail);
    newData.contacts[index][name] = value;
    this.setState({ accountDetail: newData });
  };

  onChangeMultiContact = (isNewContact = false) => {
    const { accountDetail } = this.state;
    const newData = cloneDeep(accountDetail);
    if (isNewContact) {
      const newContact = {
        phones: [
          {
            number: '',
            type: 'WORK',
          },
        ],
        roles: ['BILLING'],
      };
      newData.contacts.push(newContact);
    } else {
      newData.contacts.splice(newData.contacts.length - 1, 1);
    }

    return this.setState({ accountDetail: newData });
  };

  onChangeMultiAddress = (isNewAddress = false) => {
    const { accountDetail } = this.state;
    const newData = cloneDeep(accountDetail);
    if (isNewAddress) {
      const newAddress = {
        city: '',
        state: '',
        country: '',
        roles: [],
      };
      newData.addresses.push(newAddress);
    } else {
      newData.addresses.splice(newData.addresses.length - 1, 1);
    }

    return this.setState({ accountDetail: newData });
  };

  onHandleChangeAddress = ({ value = '', name = '', index = 0 }) => {
    const { accountDetail } = this.state;
    const newData = cloneDeep(accountDetail);
    newData.addresses[index][name] = value;
    this.setState({ accountDetail: newData });
  };

  onChangePhone = ({ value, fieldName, index, isNewPhone = false, isRemovePhone = false, indexForm }) => {
    const { accountDetail } = this.state;
    const newData = cloneDeep(accountDetail);
    const { phones } = newData.contacts[indexForm];
    if (isNewPhone) {
      const typePhoneArr = ['MOBILE', 'WORK', 'HOME', 'FAX'];
      map(phones, item => {
        const index = typePhoneArr.indexOf(item.type);
        typePhoneArr.splice(index, 1);
      });
      if (isEmpty(typePhoneArr)) {
        return toast('No unique type phone remaining', { type: toast.TYPE.ERROR });
      }
      phones[phones.length] = {
        number: '',
        type: typePhoneArr[0],
      };
    }
    if (isRemovePhone) {
      phones.splice(index, 1);
    }
    if (!isEmpty(phones) && !isNewPhone && !isRemovePhone) {
      phones[index][fieldName] = value;
    }
    return this.setState({ accountDetail: newData });
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    const { history } = this.props;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      history.push(`${customerPath}${this.onSwitchRoute(activeTabName)}`);
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  ToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  onSelectParentId = parentItemId => {
    const { getAccountDetailForSelectParent } = this.props;
    if (parentItemId) {
      this.setState({ isOpenModal: false, isParentAccount: false });
      getAccountDetailForSelectParent(parentItemId, resp => {
        if (resp.success) {
          const newAccount = cloneDeep(resp.data) || {};
          if (newAccount.billingProfiles && newAccount.billingProfiles.length) {
            newAccount.billingProfiles = newAccount.billingProfiles.map(val => {
              const {
                id,
                parentId,
                billingDom,
                billingSegment,
                billingFrequency,
                duration,
                invoiceType,
                invoiceDelivery,
                paymentProfileId,
                pauseBilling,
                pauseInvoicing,
                collectionProfile,
              } = val;
              return {
                id: id || null,
                parentId: parentId || null,
                billingDom: billingDom || null,
                billingSegment: billingSegment || null,
                billingFrequency: billingFrequency || null,
                duration: duration || null,
                invoiceType: invoiceType || null,
                invoiceDelivery: invoiceDelivery || null,
                paymentProfileId: paymentProfileId || null,
                pauseBilling: pauseBilling || null,
                pauseInvoicing: pauseInvoicing || null,
                collectionProfile: collectionProfile || null,
              };
            });
          }
          newAccount.parentId = parentItemId;
          newAccount.effectiveDate = null;
          delete newAccount.id;
          delete newAccount.status;
          delete newAccount.reason;
          const { contacts, addresses, taxExemptions, ...rest } = newAccount;
          this.setState({
            accountDetail: { ...rest, contacts: [...defaultContacts], addresses: [...defaultAddresses] },
            defaultParentDetail: newAccount,
            isParentAccount: true,
            parentAddresses: addresses,
            parentContacts: contacts,
            isNonPaying: false,
            isReferenceContact: false,
            isReferenceAddress: false,
          });
        }
      });
    }
  };

  onChangeSwitch = fieldObj => {
    const {
      isNonPaying,
      isReferenceContact,
      isReferenceAddress,
      defaultParentDetail,
      parentContacts,
      parentAddresses,
    } = this.state;
    let { accountDetail } = this.state;
    const addressesNew = accountDetail.addresses;
    const contactsNew = accountDetail.contacts;
    if (fieldObj === 'contacts') {
      accountDetail.contacts = isReferenceContact ? [...defaultContacts] : parentContacts;
      return this.setState({ isReferenceContact: !isReferenceContact, accountDetail });
    }
    if (fieldObj === 'addresses') {
      accountDetail.addresses = isReferenceAddress ? [...defaultAddresses] : parentAddresses;
      return this.setState({ isReferenceAddress: !isReferenceAddress });
    }

    accountDetail.paymentProfiles[0].paymentMethod = 'NON_PAYING';
    accountDetail = defaultParentDetail;
    accountDetail.addresses = addressesNew;
    accountDetail.effectiveDate = null;
    accountDetail.contacts = contactsNew;

    return this.setState({ accountDetail: defaultParentDetail, isNonPaying: !isNonPaying });
  };

  onChangeCreditCards = ({ value, fieldName, isCallbackURL = false, newCreditCardCallback = [] }) => {
    const { accountDetail } = this.state;
    let newData = cloneDeep(accountDetail);
    if (!newData.paymentProfiles[0].creditCards || isEmpty(newData.paymentProfiles[0].creditCards))
      newData.paymentProfiles[0].creditCards = [{}];
    if (isCallbackURL) {
      newData = localStorage.getItem('dataCallback') ? JSON.parse(localStorage.getItem('dataCallback')) : newData;
      newData.paymentProfiles[0].creditCards = newCreditCardCallback;
      newData.paymentProfiles[0].paymentMethod = 'CREDIT_CARD';
      localStorage.removeItem('dataCallback');
      return this.setState({ accountDetail: newData });
    }
    if (fieldName === 'last4CC') {
      newData.paymentProfiles[0].creditCards[0][fieldName] =
        value.length <= 4 ? value : newData.paymentProfiles[0].creditCards[0][fieldName];
    } else {
      newData.paymentProfiles[0].creditCards[0][fieldName] = value;
    }

    return this.setState({ accountDetail: newData });
  };

  isCheckValidateCreateAccount = ({ payload = {} }) => {
    const { attributesType, isNonPaying } = this.state;
    const { history } = this.props;
    const messageRoleContact = payload?.contacts
      ? messageRoleBilling({
          roles: union(
            Array.prototype.concat.apply(
              [],
              payload.contacts.map(item => item.roles)
            )
          ),
        })
      : '';
    const messageRoleAddress = payload?.addresses
      ? messageRoleBilling({
          roles: union(
            Array.prototype.concat.apply(
              [],
              payload.addresses.map(item => item.roles)
            )
          ),
        })
      : '';

    const isValidate = !constant.fieldParentRequired.filter(item => !!payload[item]).length;

    const isValidatePaymentProfile = !(payload?.paymentProfiles && payload?.paymentProfiles[0]?.paymentMethod);
    const isValidateBillingProfile = !(
      payload?.billingProfiles &&
      payload?.billingProfiles[0]?.billingFrequency &&
      payload?.billingProfiles[0]?.invoiceType &&
      payload?.billingProfiles[0]?.invoiceDelivery
    );
    // ||
    // (payload?.billingProfiles &&
    //   payload?.billingProfiles[0] &&
    //   payload?.billingProfiles[0]?.billingFrequency === 'DAYS' &&
    //   !payload?.billingProfiles[0]?.duration)
    let isValidateAttribute = false;
    let isValidateContact = false;
    let isValidateAddress = false;
    let isValidatePhone = false;
    const fieldContact = constant.fieldContactRequired;
    const fieldAddress = constant.fieldAddressRequired;
    if (payload?.contacts && payload?.contacts?.length > 1) fieldContact.push('id');
    if (payload?.addresses && payload?.addresses?.length > 1) fieldAddress.push('id');
    if (payload?.contacts && payload?.contacts.length) {
      for (let i = 0; i < payload.contacts.length; i++) {
        const isValidContactList = !payload?.contacts || !payload?.contacts[i];
        isValidateContact =
          isValidContactList || fieldContact.filter(field => payload.contacts[i][field]).length < fieldContact.length;
        if (payload.contacts[i].phones) {
          for (let j = 0; j < payload.contacts[i].phones.length; j++) {
            isValidatePhone =
              isValidContactList ||
              !payload.contacts[i].phones ||
              !payload.contacts[i].phones.length ||
              constant.fieldPhoneRequired.filter(field => payload.contacts[i].phones[j][field]).length <
                constant.fieldPhoneRequired.length;
          }
        }

        if (isValidateContact && isValidatePhone) break;
      }
    }

    if (payload.addresses && payload?.addresses.length) {
      for (let i = 0; i < payload.addresses.length; i++) {
        isValidateAddress =
          !payload.addresses ||
          !payload.addresses[i] ||
          fieldAddress.filter(field => payload.addresses[i][field]).length < fieldAddress.length;
        if (isValidateAddress) break;
      }
    }

    isValidateAttribute =
      !payload?.customAttributes ||
      !attributesType ||
      !payload.customAttributes.filter(item => attributesType.indexOf(item.name) > -1).length === attributesType.length;

    if (isValidate) {
      history.push(`${customerPath}${this.onSwitchRoute('Create Account Info')}`);
      return true;
    }

    if ((isValidateContact || messageRoleContact || isValidatePhone) && !isNonPaying) {
      history.push(`${customerPath}${this.onSwitchRoute('Create Contact')}`);
      return true;
    }

    if ((isValidateAddress || messageRoleAddress) && !isNonPaying) {
      history.push(`${customerPath}${this.onSwitchRoute('Create Address')}`);
      return true;
    }

    if (isValidatePaymentProfile) {
      history.push(`${customerPath}${this.onSwitchRoute('Create Payment Profile')}`);
      return true;
    }

    if (isValidateBillingProfile) {
      history.push(`${customerPath}${this.onSwitchRoute('Create Billing Profile')}`);
      return true;
    }

    if (isValidateAttribute) {
      history.push(`${customerPath}${this.onSwitchRoute('Create Customer Attributes')}`);
      return true;
    }
    return false;
  };

  onHandleSubmit = () => {
    const { accountDetail, isNonPaying, isShowPhones } = this.state;
    const { t, isPopup, onAddNew } = this.props;
    const newPayload = cloneDeep(accountDetail);
    const { createAccount } = this.props;
    this.setState({ wasValidated: true });

    if (isNonPaying) newPayload.contacts = null;
    if (isNonPaying) newPayload.addresses = null;
    if (!isShowPhones && newPayload.contacts) {
      newPayload.contacts = newPayload.contacts.map(val => {
        const { isShowPhones, phones, ...rest } = val;
        return { ...rest, phones: isShowPhones ? phones : null };
      });
    }
    if (this.isCheckValidateCreateAccount({ payload: newPayload })) return toast.error(t('common:message.mandatory'));
    if (
      newPayload.paymentProfiles[0].paymentMethod === 'CREDIT_CARD' &&
      !isEmpty(newPayload.paymentProfiles[0].creditCards[0])
    ) {
      newPayload.paymentProfiles[0].creditCards[0].merchant = 'qabrain3';
    } else {
      delete newPayload.paymentProfiles[0].creditCards;
    }
    if (newPayload.paymentProfiles[0].paymentMethod === 'NON_PAYING') {
      delete newPayload.billingProfiles;
      const newPaymentProfile = [{ paymentMethod: 'NON_PAYING' }];
      newPayload.paymentProfiles = newPaymentProfile;
    }
    if (isNonPaying) {
      const newPaymentProfile = [{ paymentMethod: 'NON_PAYING' }];
      newPayload.paymentProfiles = newPaymentProfile;
    }
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    this.setState({ wasValidated: false });
    return createAccount(
      newPayload,
      ({ success, id }) => {
        if (success && isPopup && onAddNew) onAddNew(id);
      },
      !!isPopup
    );
  };

  onChangeRole = (name, fieldObj, index) => {
    const { accountDetail } = this.state;
    const newData = cloneDeep(accountDetail);
    if (newData[fieldObj][index].roles.indexOf(name) > -1) {
      remove(newData[fieldObj][index].roles, item => item === name);
    } else {
      newData[fieldObj][index].roles.push(name);
    }
    if (newData[fieldObj][index]?.roles?.length === 0) return '';
    return this.setState({ accountDetail: newData });
  };

  onHandleCreateAttribute = attributes => {
    const { accountDetail } = this.state;
    const newAttribute = cloneDeep(accountDetail);
    const attributesValue = cloneDeep(attributes.filter(item => item.value));
    if (isEmpty(attributesValue)) {
      newAttribute.customAttributes = [];
    }

    const newValueAttr = [];
    attributesValue.forEach((item, index) => {
      newValueAttr[index] = { name: item.name, value: item.value };
    });
    newAttribute.customAttributes = newValueAttr;
    this.setState({ accountDetail: newAttribute });
  };

  // onSelectCreditProfileIndex = () => {
  //   const { isOpenModalSelectCreditProfile } = this.state;
  //   this.setState({ isOpenModalSelectCreditProfile: !isOpenModalSelectCreditProfile });
  // };

  // onSelectedIndexCreditProfile = index => {
  //   const { accountDetail } = this.state;
  //   const newData = cloneDeep(accountDetail);
  //   if (Number.parseInt(index, 10) > -1) {
  //     newData.creditProfileIndex = index;
  //   } else {
  //     newData.creditProfileIndex = null;
  //   }
  //   this.setState({ accountDetail: newData, isOpenModalSelectCreditProfile: false });
  // };

  onToggleModalCollectionAgent = () => {
    const { isOpenModalCollectionAgent } = this.state;
    this.setState({ isOpenModalCollectionAgent: !isOpenModalCollectionAgent });
  };

  onSelectedCollectionAgent = id => {
    const { accountDetail } = this.state;
    const newData = cloneDeep(accountDetail);
    newData.collectionAgent = id;
    this.setState({ accountDetail: newData, isOpenModalCollectionAgent: false });
  };

  onChangeIsShowPhone = ({ index }) => {
    const { accountDetail } = this.state;
    const newData = cloneDeep(accountDetail);
    try {
      newData.contacts[index].isShowPhones = !newData.contacts[index].isShowPhones;
      this.setState({ accountDetail: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onToggleModalCollectionProfile = ({ itemSelected }) => {
    const { isOpenModalCollectionProfile } = this.state;
    this.setState({
      isOpenModalCollectionProfile: !isOpenModalCollectionProfile,
      collectionProfileSelected: itemSelected || null,
    });
  };

  onSelectCollectionProfile = (id, row) => {
    if (row) {
      const { accountDetail } = this.state;
      const newData = cloneDeep(accountDetail);
      newData.billingProfiles[0].collectionProfile = row.collectionProfile;
      this.setState({
        isOpenModalCollectionProfile: false,
        collectionProfileSelected: null,
        accountDetail: newData,
      });
    }
  };

  onToggleAddressTab = (activeTabName, index) => {
    const { activeAddressTab } = this.state;
    if (activeAddressTab.name === activeTabName && activeAddressTab.index === index) {
      this.setState({
        activeAddressTab: { name: activeTabName, isActive: !activeAddressTab.isActive, index },
      });
    } else {
      this.setState({ activeAddressTab: { name: activeTabName, isActive: true, index } });
    }
  };

  renderItem = item => {
    const {
      accountDetail,
      isNonPaying,
      isReferenceAddress,
      isReferenceContact,
      isParentAccount,
      currencyOptions,
      paymentTermOptions,
      landmarkLabel,
      extralineLabel,
      isSupport31DayBilling,
      activeAddressTab,
    } = this.state;

    const messageRoleContact = messageRoleBilling({
      roles: union(
        Array.prototype.concat.apply(
          [],
          accountDetail.contacts.map(item => item.roles)
        )
      ),
    });
    const messageRoleAddress = messageRoleBilling({
      roles: union(
        Array.prototype.concat.apply(
          [],
          accountDetail.addresses.map(item => item.roles)
        )
      ),
    });
    const { t, getConfigOperatorByServiceType, getUsedCfdiByTaxRegion } = this.props;
    switch (item.state) {
      case 'Create Account Info':
        return (
          <CustomerForm
            colItem="col-md-4"
            dataField={convertFieldInfo2New({
              dataField: constant.accountInfo.accountNewInfoForm(currencyOptions, accountDetail),
              objItemRemove: ['Status', 'Reason'],
            })}
            accountDetail={accountDetail}
            onHandleChange={this.onHandleChange}
            onHandleSubmit={this.onHandleSubmit}
            isDisabled={isNonPaying}
            // onSelectCreditProfileIndex={this.onSelectCreditProfileIndex}
            t={t}
            onSelectCollectionAgent={this.onToggleModalCollectionAgent}
          />
        );
      case 'Create Contact':
        return isNonPaying
          ? ''
          : accountDetail.contacts.map((item, index) => (
            <CustomerForm
              key={index}
              colItem="col-md-4"
              dataField={
                  accountDetail.contacts.length > 1
                    ? constant.accountContract.accountNewMultiContactForm
                    : constant.accountContract.accountNewContactForm
                }
              checkRoleGroup={constant.checkRoleGroup}
              accountDetail={item}
              onChangeCheckBox={(name, fieldObj) => this.onChangeRole(name, fieldObj, index)}
              onHandleChange={({ name, value }) => this.onHandleChangeContact({ name, value, index })}
              fieldObj="contacts"
              onHandleSubmit={this.onHandleSubmit}
              onChangePhone={this.onChangePhone}
              isReferenceSwitch={isReferenceContact}
              isDisabled={isReferenceContact}
              isRenderSwitch={isParentAccount}
              onChangeSwitch={this.onChangeSwitch}
              indexForm={index}
              isNewAccount={index === 0}
              messageRole={messageRoleContact}
              t={t}
              isPhones
              isLeftAddPhoneBtn
              isShowPhones={item.isShowPhones}
              showPhoneName={index}
              onChangeIsShowPhone={() => this.onChangeIsShowPhone({ index })}
            >
              {index + 1 === accountDetail.contacts.length && (
              <div className="form-group col-md-12 btn-add-new action-buttons">
                <button
                  type="button"
                  className="button x-small float-right"
                  disabled={
                        // isReferenceContact ||
                        size(
                          union(
                            Array.prototype.concat.apply(
                              [],
                              accountDetail.contacts.map(item => item.roles)
                            )
                          )
                        ) === 4
                      }
                  onClick={() => this.onChangeMultiContact(true)}
                >
                  {t('common:label.addNewContact')}
                </button>
                <button
                  type="button"
                  className="button x-small float-right"
                  disabled={accountDetail.contacts.length === 1}
                  onClick={() => this.onChangeMultiContact(false)}
                >
                  {t('common:label.deleteContact')}
                </button>
              </div>
                )}
            </CustomerForm>
            ));
      case 'Create Address':
        return isNonPaying
          ? ''
          : accountDetail.addresses.map((item, index) => {
              const { country, state, city } = accountDetail.addresses[index];
              const addressesForm =
                accountDetail.addresses.length === 1
                  ? constant.dataFieldNewSingleAddress({ landmarkLabel, extralineLabel })
                  : constant.dataFieldNewAddress({ landmarkLabel, extralineLabel });

              let dataFields = getAddressesForm({
                city,
                state,
                country,
                addresses: addressesForm,
              });

              const additionalFields = dataFields.filter(val => ['extraLine', 'landmark'].includes(val.fieldName));

              dataFields = dataFields.filter(val => !['extraLine', 'landmark'].includes(val.fieldName));
              return (
                <CustomerForm
                  key={index}
                  colItem="col-md-4"
                  dataField={dataFields}
                  // checkRoleGroup={constant.checkRoleGroup}
                  // onChangeCheckBox={(name, fieldObj) => this.onChangeRole(name, fieldObj, index)}
                  accountDetail={item}
                  onHandleChange={({ name, value }) => this.onHandleChangeAddress({ name, value, index })}
                  fieldObj="addresses"
                  onHandleSubmit={this.onHandleSubmit}
                  isReferenceSwitch={isReferenceAddress}
                  onChangeSwitch={this.onChangeSwitch}
                  isDisabled={isReferenceAddress}
                  isRenderSwitch={isParentAccount}
                  isNewAccount={index === 0}
                  messageRole={messageRoleAddress}
                  t={t}
                >
                  <div className="col-md-12">
                    {collapsibleAddress.map(cl => {
                      return (
                        <FormCollapse
                          key={cl.title}
                          isActive={
                            activeAddressTab &&
                            cl.state === activeAddressTab.name &&
                            activeAddressTab.isActive &&
                            activeAddressTab.index === index
                          }
                          title={cl.title}
                          state={cl.state}
                          onToggleTab={name => this.onToggleAddressTab(name, index)}
                          isSubCollapse
                          isActiveSubForm={
                            activeAddressTab &&
                            cl.state === activeAddressTab.name &&
                            activeAddressTab.isActive &&
                            activeAddressTab.index === index
                          }
                        >
                          <div className="col-md-12 row">
                            {[
                              ...additionalAddressInformation,
                              ...additionalFields.map(i => {
                                const { fieldName, labelFilter, ...rest } = i;
                                return { ...rest, name: fieldName, label: labelFilter };
                              }),
                            ].map(val => (
                              <GenericInput
                                {...val}
                                value={item[val.name] || null}
                                onChange={({ name, value }) => this.onHandleChangeAddress({ name, value, index })}
                              />
                            ))}
                          </div>
                        </FormCollapse>
                      );
                    })}
                    <div className="form-group col-md-12 check-group mt-3">
                      {!isEmpty(constant.checkRoleGroup) && (
                        <span>
                          {t('common:label.roles')}
                          {index === 0 && <i className="fa fa-star is-required" />}
                        </span>
                      )}
                      {map(constant.checkRoleGroup, (ck, idx) => (
                        <CheckFieldRole
                          validate={messageRoleAddress && idx === 0 ? messageRoleAddress : ''}
                          key={ck.label}
                          label={t && ck.label ? t(ck.label) : ck.label || ''}
                          className={ck.className}
                          name={ck.value}
                          disabled={isReferenceAddress}
                          value={item.roles && item.roles.indexOf(ck.value) > -1}
                          onChange={({ name }) => this.onChangeRole(name, 'addresses', index)}
                        />
                      ))}
                    </div>
                  </div>
                  {index + 1 === accountDetail.addresses.length && (
                    <div className="form-group col-md-12 btn-add-new action-buttons">
                      <button
                        type="button"
                        className="button x-small float-right"
                        // disabled={isReferenceContact}
                        onClick={() => this.onChangeMultiAddress(true)}
                      >
                        {t('common:label.addNewAddress')}
                      </button>
                      <button
                        type="button"
                        className="button x-small float-right"
                        disabled={accountDetail.addresses.length === 1}
                        onClick={() => this.onChangeMultiAddress(false)}
                      >
                        {t('common:label.deleteAddress')}
                      </button>
                    </div>
                  )}
                </CustomerForm>
              );
            });
      case 'Create Payment Profile':
        return (
          <CreatePaymentProfile
            accountDetail={accountDetail.paymentProfiles[0]}
            colItem="col-md-4"
            isNewAccount
            onHandleChange={this.onHandleChange}
            fieldObj="paymentProfiles"
            onChangeCreditCards={this.onChangeCreditCards}
            isDisabled={isNonPaying}
            dataCallback={accountDetail}
            paymentTermOptions={paymentTermOptions}
          />
        );
      case 'Create Billing Profile':
        return (
          <CustomerForm
            colItem="col-md-4"
            dataField={convertFieldInfo2New({
              dataField: constant.accountBillingProfile.accountNewBillingProfileForm(
                accountDetail.billingProfiles[0],
                isSupport31DayBilling
              ),
              objItemRemove: ['Id'],
            })}
            btnGroup={constant.accountBillingProfile.btnGroup}
            accountDetail={accountDetail.billingProfiles[0]}
            fieldObj="billingProfiles"
            onHandleChange={this.onHandleChange}
            onHandleSubmit={this.onHandleSubmit}
            isDisabled={isNonPaying}
            t={t}
            onClickItem={() => {
              this.onToggleModalCollectionProfile({
                itemSelected:
                  accountDetail.billingProfiles[0] && accountDetail.billingProfiles[0].collectionProfile
                    ? accountDetail.billingProfiles[0].collectionProfile
                    : null,
              });
            }}
          />
        );
      case 'Create Customer Attributes':
        return (
          <CustomerAttribute
            account={accountDetail}
            isNewAccount
            onHandleCreateAttribute={this.onHandleCreateAttribute}
            getConfigOperatorByServiceType={getConfigOperatorByServiceType}
            getUsedCfdiByTaxRegion={getUsedCfdiByTaxRegion}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {
      activeTab,
      isOpenModal,
      accountDetail,
      isNonPaying,
      isParentAccount,
      parentItemId,
      // isOpenModalSelectCreditProfile,
      defaulFilter,
      isOpenModalCollectionAgent,
      wasValidated,
      quickCreateData,
      isOpenModalCollectionProfile,
      collectionProfileSelected,
    } = this.state;

    const {
      permissionsCustomer,
      t,
      match: { params },
      location,
      isPopup,
      onAddNew,
      onToggleModal,
      createAccount,
      permissionsRating,
      permissionsBilling,
      permissionsCollections,
    } = this.props;

    const { childRoute } = params || {};

    const sidePropDetails = {
      isExpand: false,
      items: [
        {
          isHaveExpand: true,
          mainTilte: 'customerPage:sidebarSearchPage.config.title',
          isExpandChild: true,
          childs: [
            {
              icon: 'fa fa-list',
              label: 'customerPage:sidebarSearchPage.config.subTitle.customAttributes',
              href: RouteName.customerAttributeCreate.path,
            },
            {
              icon: 'ti-credit-card',
              label: 'customerPage:sidebarSearchPage.config.subTitle.creditProfile',
              href: RouteName.creditProfileCreate.path,
            },
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: t('sidebarHandleAccountPage.quickCreate'),
          href: RouteName.quickCreateCustomer.path,
          childs: [],
          icon: 'fa fa-plus-circle',
        },
        {
          isHaveExpand: true,
          mainTilte: t('sidebarHandleAccountPage.detailCreate'),
          href: `/customers/create/${
            childRoute !== 'quick-create' &&
            childRoute !== 'credit-profile' &&
            childRoute !== 'ustom-attributes-config' &&
            childRoute !== 'collection-profile'
              ? childRoute
              : 'info'
          }`,
          childs: [],
          icon: 'fa fa-plus-square-o',
        },
        // {
        //   isHaveExpand: true,
        //   mainTilte: t('sidebarSearchPage.account.title'),
        //   childs: [
        //     {
        //       icon: 'ti-info-alt',
        //       label: t('sidebarHandleAccountPage.accountInfo'),
        //       href: '/customers/create/info',
        //     },
        //     {
        //       icon: 'ti-mobile',
        //       label: t('sidebarHandleAccountPage.contact'),
        //       href: '/customers/create/contacts',
        //     },
        //     {
        //       icon: 'ti-map-alt',
        //       label: t('sidebarHandleAccountPage.addresses'),
        //       href: '/customers/create/addresses',
        //     },
        //     {
        //       icon: 'ti-credit-card',
        //       label: t('sidebarHandleAccountPage.paymentProfile'),
        //       href: '/customers/create/payment-profile',
        //     },
        //     {
        //       icon: 'ti-printer',
        //       label: t('sidebarHandleAccountPage.billingProfile'),
        //       href: '/customers/create/profile-billing',
        //     },
        //     {
        //       icon: 'ti-bookmark-alt',
        //       label: t('sidebarHandleAccountPage.customAttributes'),
        //       href: '/customers/create/custom-attributes',
        //     },
        //   ],
        // },
      ],
    };
    let modeCreateCustomer = 0;
    let modeSearchAccount = 0;
    if (permissionsCustomer && permissionsCustomer.customerModulePermissions) {
      const listPermission = permissionsCustomer.customerModulePermissions;
      modeCreateCustomer = checkPermissionCreateCustomer({ listPermission });
      modeSearchAccount = checkPermissionSearchAccount({ listPermission });
    }

    let modeSetCreditProfile = 0;
    let modeGetDelayedBillingConfig = 0;

    if (permissionsRating && permissionsRating.ratingModulePermissions) {
      const listPermissionRating = permissionsRating.ratingModulePermissions;
      modeSetCreditProfile = checkPermissionSetCreditLimit({ listPermission: listPermissionRating });
    }

    if (permissionsBilling && permissionsBilling.billingModulePermissions) {
      const listPermissionsBilling = permissionsBilling.billingModulePermissions;
      modeGetDelayedBillingConfig = checkPermissionGetDelayedBillingConfig({ listPermission: listPermissionsBilling });
    }

    const newSideProps = cloneDeep(constant.sidePropIndex);
    if (modeSetCreditProfile === 0 && newSideProps.items[0] && newSideProps.items[0].childs) {
      newSideProps.items[0].childs = newSideProps.items[0].childs.filter(
        item => item.label !== 'customerPage:sidebarSearchPage.config.subTitle.creditProfile'
      );
    }

    if (modeGetDelayedBillingConfig === 0 && newSideProps.items[0] && newSideProps.items[0].childs) {
      newSideProps.items[0].childs = newSideProps.items[0].childs.filter(
        item => item.label !== 'customerPage:sidebarSearchPage.config.subTitle.delayedBilling'
      );
    }

    if (isPopup) {
      customerPath = `${RouteName.searchQuoteAndList.path}/`;

      return (
        <form
          onSubmit={this.onHandleSubmit}
          className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
          noValidate
          ref={this.formRef}
        >
          <CreateAccountForm
            accountDetail={accountDetail}
            t={t}
            onHandleChange={this.onHandleChange}
            ToggleModal={this.ToggleModal}
            isParentAccount={isParentAccount}
            onChangeSwitch={this.onChangeSwitch}
            isNonPaying={isNonPaying}
            activeTab={activeTab}
            onHandleSubmit={this.onHandleSubmit}
            modeCreateCustomer={modeCreateCustomer}
            isOpenModal={isOpenModal}
            parentItemId={parentItemId}
            onSelectParentId={this.onSelectParentId}
            // isOpenModalSelectCreditProfile={isOpenModalSelectCreditProfile}
            // onSelectedIndexCreditProfile={this.onSelectedIndexCreditProfile}
            isOpenModalCollectionAgent={isOpenModalCollectionAgent}
            onSelectedCollectionAgent={this.onSelectedCollectionAgent}
            onToggleTab={this.onToggleTab}
            renderItem={this.renderItem}
            // onSelectCreditProfileIndex={this.onSelectCreditProfileIndex}
            onToggleModalCollectionAgent={this.onToggleModalCollectionAgent}
            isPopup={isPopup}
            onAddNew={onAddNew}
            onToggleModal={onToggleModal}
          />
        </form>
      );
    }
    if (modeSearchAccount === 0 || modeCreateCustomer !== 2) return '';
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="content-wrapper">
            <Sidebar sideProps={sidePropDetails} isMaximizeOne />
            <PageTitle
              linkTo={{
                pathname:
                  location?.state && location.state?.backLink ? location.state.backLink : RouteName.customers.path,
                state: { filter: defaulFilter },
              }}
              titleBtn={t('common:label.back')}
              items={[
                { name: t('common:label.customerManagement'), url: RouteName.customers.path },
                { name: t(constant.newAccount.collapsibleMenu.find(item => item.state === activeTab.name).title) },
              ]}
            />
            <br />
            {childRoute === 'custom-attributes-config' && <CustomerAttributePage isComponent />}
            {childRoute === 'credit-profile' && <CreditProfilePage isComponent />}
            {childRoute !== 'custom-attributes-config' &&
              childRoute !== 'credit-profile' &&
              childRoute !== 'quick-create' &&
              childRoute !== 'collection-profile' && (
                <form
                  onSubmit={this.onHandleSubmit}
                  className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
                  noValidate
                  ref={this.formRef}
                >
                  <CreateAccountForm
                    accountDetail={accountDetail}
                    t={t}
                    onHandleChange={this.onHandleChange}
                    ToggleModal={this.ToggleModal}
                    isParentAccount={isParentAccount}
                    onChangeSwitch={this.onChangeSwitch}
                    isNonPaying={isNonPaying}
                    activeTab={activeTab}
                    onHandleSubmit={this.onHandleSubmit}
                    modeCreateCustomer={modeCreateCustomer}
                    isOpenModal={isOpenModal}
                    parentItemId={parentItemId}
                    onSelectParentId={this.onSelectParentId}
                    // isOpenModalSelectCreditProfile={isOpenModalSelectCreditProfile}
                    // onSelectedIndexCreditProfile={this.onSelectedIndexCreditProfile}
                    isOpenModalCollectionAgent={isOpenModalCollectionAgent}
                    onSelectedCollectionAgent={this.onSelectedCollectionAgent}
                    onToggleTab={this.onToggleTab}
                    renderItem={this.renderItem}
                    // onSelectCreditProfileIndex={this.onSelectCreditProfileIndex}
                    onToggleModalCollectionAgent={this.onToggleModalCollectionAgent}
                  />
                </form>
              )}
            {childRoute === 'quick-create' && (
              <QuickCreate
                data={quickCreateData}
                createAccount={createAccount}
                modeCreateCustomer={modeCreateCustomer}
              />
            )}
          </div>
        </div>
        <ModalSearchCollectionProfile
          isOpen={isOpenModalCollectionProfile}
          onSelect={this.onSelectCollectionProfile}
          onCancel={this.onToggleModalCollectionProfile}
          selectedParentId={collectionProfileSelected || null}
          selectFieldName="collectionProfile"
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  permissionsCustomer: makeGetPermissionsCustomerManagement() || {},
  currencyConfig: makeGetCurrencyConfig() || {},
  permissionsRating: makeGetPermissionsRating() || {},
  permissionsBilling: makeGetPermissionsBilling() || {},
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
});

CustomerNew.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  createAccount: PropTypes.func,
  getAccountDetail: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func,
  getCustomAttributesByObjectType: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any),
  permissionsCustomer: PropTypes.objectOf(PropTypes.any),
  getCcpPropertiesConfig: PropTypes.func.isRequired,
  getAccountDetailForSelectParent: PropTypes.func.isRequired,
};

CustomerNew.defaultProps = {
  match: {},
  history: {},
  createAccount: () => {},
  onToggleModal: () => {},
  permissionsCustomer: {},
};

const newCustomerCreate = connect(mapStateToProps, {
  createAccount,
  getAccountDetail,
  getCustomAttributesByObjectType,
  getCcpPropertiesConfig,
  getCurrencyConfigApp,
  getPaymentConfigByTypeApp,
  getConfigOperatorByServiceType,
  getUsedCfdiByTaxRegion,
  getAccountDetailForSelectParent,
})(CustomerNew);

export default withTranslation(['customerPage', 'common'])(newCustomerCreate);
