import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class AccountAssetsSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:priceUnitStatus',
      type: 'select',
    },
    {
      label: 'common:label.serviceType',
      name: 'serviceType',
      options: [],
      type: 'select',
    },
  ];

  btnSpan = '';
}

AccountAssetsSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AccountAssetsSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation(['common', 'selections'])(AccountAssetsSearchForm);
