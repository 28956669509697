import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ filter, page, size, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['serviceType'])}` : '';
  const searchAccountAssets = `searchAccountAssets(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchAccountAssets} {
        id
        accountId
        subscriptionId
        serviceUnitId
        bundleId
        packageId
        status
        serviceType
        discountPercent
        quantity
        startDate
        endDate
        cycleStartDate
        cycleEndDate
        appliedStartDate
        appliedEndDate
        packageName
        packageDescription
        bundleName
        bundleDescription
        offerName
        offerType
        offerOverride
        offerOffset
        offerId
      }
    }
  `;
};
