/**
 * Item Value Not String
 */

export default [
  'type',
  'status',
  'isPartialFulfillmentAllowed',
  'initialTerm',
  'initialTermUnit',
  'renewalTerm',
  'renewalTermUnit',
  'trialTerm',
  'trialTermUnit',
  'index',
  'serviceType',
  'action',
  'quantity',
  'priceOverride',
  'priceOffset',
  'discountPercent',
  'relativeStart',
  'relativeEnd',
  'relativeStartUnit',
  'relativeEndUnit',
  'subscriptionReason',
  'trialType',
  'inFlightOrder',
  'accountType',
  'discAmountOverride',
  'discPercentOverride',
  'subscriptionCategory',
  'trialToPaidNow',
  'billingOnlyFlag',
  'provisioningOnlyFlag',
  'billOrderCharges',
  'alignAndExtendSubsValidity',
];
