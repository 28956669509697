import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import { ModalWithItem, DataTable } from '../../../components/common';
import OrderHistory from './OrderHistory';

export default function AuditHistoryForm({ getOrderAudit, convertJsonToOrder, id }) {
  const { t } = useTranslation('common');

  const [data, setData] = useState([]);
  const [jsonData, setJsonData] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalItem, setIsOpenModalItem] = useState(false);

  const onToggleModal = () => {
    setIsOpenModal(false);
    setJsonData({});
  };

  const onToggleModalItem = () => {
    setIsOpenModalItem(false);
    setJsonData({});
  };

  const doConvertAuditJsonData = item => {
    convertJsonToOrder(
      {
        id: item.id,
        createdDate: item.createdDate || null,
      },
      ({ success, data }) => {
        if (success) {
          setJsonData(data || {});
          setIsOpenModalItem(true);
        }
      }
    );
  };

  const auditHistoryColumns = [
    {
      name: 'id',
      label: 'label.orderId',
    },
    {
      name: 'userId',
      label: 'label.userId',
    },
    {
      name: 'createdDate',
      label: 'label.createdDate',
    },
    {
      name: 'status',
      label: 'label.status',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:statusOrder')().find(val => val.value === item.status) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'type',
      label: 'label.type',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:types')().find(val => val.value === item.type) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'reason',
      label: 'label.reason',
    },
    {
      name: 'view',
      label: 'common:label.view',
      render: (colName, item) =>
        item.status === 'CANCELLED' ? (
          <Tooltip
            trigger={['hover']}
            placement="bottom"
            overlay={t('message.disableViewOrderHistoryInfo')}
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
          >
            <span type="button" className="btn btn-outline-success btn-sm disable-label">
              {t('label.viewDetails')}
            </span>
          </Tooltip>
        ) : (
          <button type="button" className="btn btn-outline-success btn-sm" onClick={() => doConvertAuditJsonData(item)}>
            {t('label.viewDetails')}
          </button>
        ),
    },
  ];

  useEffect(() => {
    if (isOpenModal) {
      getOrderAudit(id, ({ success, data }) => {
        if (success) {
          setData(data || []);
        }
      });
    }
  }, [getOrderAudit, id, isOpenModal]);

  const renderForm = useMemo(() => {
    return (
      <Tooltip
        trigger={['hover']}
        placement="bottom"
        overlay={t('label.auditHistory')}
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
      >
        <button type="button" onClick={() => setIsOpenModal(true)} className="button x-small ml-2 btn-config-item">
          A
        </button>
      </Tooltip>
    );
  }, [t]);

  return (
    <>
      {renderForm}
      <ModalWithItem
        isOpen={isOpenModal}
        onToggle={onToggleModal}
        wrapperClass="modal-custom modal-70"
        modalTitle={t('label.auditHistory')}
      >
        <button onClick={onToggleModal} type="button" className="button button-border black x-small mr-2 mb-2">
          {t('label.cancel')}
        </button>
        <DataTable columns={auditHistoryColumns} data={data && data.length ? data : []} />
        <ModalWithItem
          isOpen={isOpenModalItem}
          onToggle={onToggleModalItem}
          wrapperClass="modal-custom modal-70"
          modalTitle={t('label.auditHistoryDetails')}
        >
          <OrderHistory id={id} data={jsonData} />
          <button
            onClick={onToggleModalItem}
            type="button"
            className="button button-border black x-small mr-3 mb-3 float-right"
          >
            {t('label.cancel')}
          </button>
        </ModalWithItem>
      </ModalWithItem>
    </>
  );
}

AuditHistoryForm.propTypes = {
  getOrderAudit: PropTypes.func.isRequired,
  convertJsonToOrder: PropTypes.func.isRequired,
  id: PropTypes.string,
};

AuditHistoryForm.defaultProps = {
  id: '',
};
