import * as types from './types';

export function getCustomers(payload, cb) {
  return {
    type: types.GET_CUSTOMERS,
    payload,
    cb,
  };
}

export function getCustomersParentOnly(payload, cb) {
  const newPayload = payload;
  if (newPayload.filter) newPayload.filter.fetchParentOnly = true;
  return {
    type: types.GET_CUSTOMERS_PARENT,
    payload: newPayload,
    cb,
  };
}

export function getCustomersId(payload, cb) {
  return {
    type: types.GET_CUSTOMERS_ID,
    payload,
    cb,
  };
}

export function getCustomersSuccess(payload) {
  return {
    type: types.GET_CUSTOMERS_SUCCESS,
    payload,
  };
}

export function getCustomersFailure(payload) {
  return {
    type: types.GET_CUSTOMERS_FAILURE,
    payload,
  };
}

export function setParams(payload) {
  return {
    type: types.SET_PARAMS,
    payload,
  };
}

//----------

export function createAccount(payload, cb, isPopup) {
  return {
    type: types.CREATE_ACCOUNT,
    payload,
    cb,
    isPopup,
  };
}

export function createAccountSuccess() {
  return {
    type: types.CREATE_ACCOUNT_SUCCESS,
  };
}

export function createAccountFailure(payload) {
  return {
    type: types.CREATE_ACCOUNT_FAILURE,
    payload,
  };
}

// for get Account Detail
export function getAccountDetail(payload, cb) {
  return {
    type: types.GET_ACCOUNT_DETAIL,
    payload,
    cb,
  };
}

// for get Account Detail
export function getParentAccountDetail(payload, cb) {
  return {
    type: types.GET_PARENT_ACCOUNT_DETAIL,
    payload,
    cb,
  };
}

export function getAccountDetailSuccess(payload) {
  return {
    type: types.GET_ACCOUNT_DETAIL_SUCCESS,
    payload,
  };
}

export function getAccountDetailFailure(payload) {
  return {
    type: types.GET_ACCOUNT_DETAIL_FAILURE,
    payload,
  };
}

// for get Account Information
export function getAccountInformation(payload, cb) {
  return {
    type: types.GET_ACCOUNT_INFORMATION,
    payload,
    cb,
  };
}

// for modify account
export function modifyAccount(payload, cb) {
  return {
    type: types.MODIFY_ACCOUNT,
    payload,
    cb,
  };
}

export function modifyAccountSuccess(payload) {
  return {
    type: types.MODIFY_ACCOUNT_SUCCESS,
    payload,
  };
}

// for update account's status
export function updateAccountStatus(payload, cb) {
  return {
    type: types.UPDATE_ACCOUNT_STATUS,
    payload,
    cb,
  };
}

export function updateAccountStatusSuccess(payload) {
  return {
    type: types.UPDATE_ACCOUNT_STATUS_SUCCESS,
    payload,
  };
}

// for get price units
export function getPriceUnits(payload) {
  return {
    type: types.GET_PRICE_UNITS,
    payload,
  };
}

export function getPriceUnitsSuccess(payload) {
  return {
    type: types.GET_PRICE_UNITS_SUCCESS,
    payload,
  };
}

export function getPriceUnitsFailure(payload) {
  return {
    type: types.GET_PRICE_UNITS_FAILURE,
    payload,
  };
}

// for get service units by accountId
export function getServiceUnitAc(payload) {
  return {
    type: types.GET_SERVICE_UNITS_AC,
    payload,
  };
}

export function getServiceUnitAcSuccess(payload) {
  return {
    type: types.GET_SERVICE_UNITS_AC_SUCCESS,
    payload,
  };
}

export function getServiceUnitAcFailure(payload) {
  return {
    type: types.GET_SERVICE_UNITS_AC_FAILURE,
    payload,
  };
}

// for get subscription
export function getSubscription(payload, cb) {
  return {
    type: types.GET_SUBSCRIPTION,
    payload,
    cb,
  };
}

export function getSubscriptionSuccess(payload) {
  return {
    type: types.GET_SUBSCRIPTION_SUCCESS,
    payload,
  };
}

export function getSubscriptionFailure(payload) {
  return {
    type: types.GET_SUBSCRIPTION_FAILURE,
    payload,
  };
}
// for get balance units
export function searchBalanceUnitByAccountId(payload, cb) {
  return {
    type: types.SEARCH_BALANCE_UNIT_BY_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function getBalanceUnitSuccess(payload) {
  return {
    type: types.GET_BALANCE_UNIT_SUCCESS,
    payload,
  };
}

export function getBalanceUnitFailure(payload) {
  return {
    type: types.GET_BALANCE_UNIT_FAILURE,
    payload,
  };
}

// for get transaction unit
export function getTransactionUnit(payload, cb) {
  return {
    type: types.GET_TRANSACTION_UNIT,
    payload,
    cb,
  };
}

export function getTransactionUnitSuccess(payload) {
  return {
    type: types.GET_TRANSACTION_UNIT_SUCCESS,
    payload,
  };
}

export function getTransactionUnitFailure(payload) {
  return {
    type: types.GET_TRANSACTION_UNIT_FAILURE,
    payload,
  };
}

export function setTransactionParams(payload) {
  return {
    type: types.SET_TRANSACTION_PARAMS,
    payload,
  };
}

// for get ar activity
export function getArActivity(payload, cb) {
  return {
    type: types.GET_AR_ACTIVITY,
    payload,
    cb,
  };
}

export function getArActivitySuccess(payload) {
  return {
    type: types.GET_AR_ACTIVITY_SUCCESS,
    payload,
  };
}

export function getArActivityFailure(payload) {
  return {
    type: types.GET_AR_ACTIVITY_FAILURE,
    payload,
  };
}

export function setArActivityParams(payload) {
  return {
    type: types.SET_AR_ACTIVITY_PARAMS,
    payload,
  };
}

// for get customer activity
export function getCustomerActivity(payload, cb) {
  return {
    type: types.GET_CUSTOMER_ACTIVITY,
    payload,
    cb,
  };
}

export function getCustomerActivitySuccess(payload) {
  return {
    type: types.GET_CUSTOMER_ACTIVITY_SUCCESS,
    payload,
  };
}

export function getCustomerActivityFailure(payload) {
  return {
    type: types.GET_CUSTOMER_ACTIVITY_FAILURE,
    payload,
  };
}

export function setActivityParams(payload) {
  return {
    type: types.SET_ACTIVITY_PARAMS,
    payload,
  };
}

// for get pending bills
export function getPendingBill(payload, cb) {
  return {
    type: types.GET_PENDING_BILL,
    payload,
    cb,
  };
}

export function getPendingBillSuccess(payload) {
  return {
    type: types.GET_PENDING_BILL_SUCCESS,
    payload,
  };
}

export function getPendingBillFailure(payload) {
  return {
    type: types.GET_PENDING_BILL_FAILURE,
    payload,
  };
}

// for open / closed bills
export function getOCBill(payload, cb) {
  return {
    type: types.GET_OC_BILL,
    payload,
    cb,
  };
}

export function getOCBillSuccess(payload) {
  return {
    type: types.GET_OC_BILL_SUCCESS,
    payload,
  };
}

export function getOCBillFailure(payload) {
  return {
    type: types.GET_OC_BILL_FAILURE,
    payload,
  };
}

export function getCustomAttributesByObjectType(cb, payload) {
  return {
    type: types.GET_CUSTOM_ATTRIBUTES,
    cb,
    payload,
  };
}

export function getCustomAttributesByObjectTypeSuccess(payload, cb) {
  return {
    type: types.GET_CUSTOM_ATTRIBUTES_ORDER_SUCCESS,
    payload,
    cb,
  };
}

export function ModifyCustomAttributes(payload, cb) {
  return {
    type: types.MODIFY_CUSTOM_ATTRIBUTES,
    payload,
    cb,
  };
}

export function createCustomAttributes(payload, cb) {
  return {
    type: types.CREATE_CUSTOM_ATTRIBUTES,
    payload,
    cb,
  };
}

export function getConfigCreditProfile(payload, cb) {
  return {
    type: types.GET_CONFIG_CREDIT_PROFILE,
    payload,
    cb,
  };
}

export function getConfigCreditProfileSuccess(payload, cb) {
  return {
    type: types.GET_CONFIG_CREDIT_PROFILE_SUCCESS,
    payload,
    cb,
  };
}

export function modifyConfigCreditProfile(payload, cb) {
  return {
    type: types.MODIFY_CONFIG_CREDIT_PROFILE,
    payload,
    cb,
  };
}

export function createConfigCreditProfile(payload, cb) {
  return {
    type: types.CREATE_CONFIG_CREDIT_PROFILE,
    payload,
    cb,
  };
}

export function getTransactionUnitById(payload, cb) {
  return {
    type: types.GET_TRANSACTION_UNIT_BY_ID,
    payload,
    cb,
  };
}

export function getActivityById(payload, cb) {
  return {
    type: types.GET_ACTIVITY_BY_ID,
    payload,
    cb,
  };
}

export function getArOpsUnitById(payload, cb) {
  return {
    type: types.GET_AR_OPS_UNIT_BY_ID,
    payload,
    cb,
  };
}

export function manualInvoiceService(payload, cb) {
  return {
    type: types.MANUAL_INVOICE_SERVICE,
    payload,
    cb,
  };
}

export function runPendingBill(payload, cb) {
  return {
    type: types.RUN_PENDING_BILL,
    payload,
    cb,
  };
}

// FutureCycleCfg
export function getFutureCycleCfg(payload, cb) {
  return {
    type: types.GET_FUTURE_CYCLE_CFG,
    payload,
    cb,
  };
}

export function getFutureCycleCfgSuccess(payload, cb) {
  return {
    type: types.GET_FUTURE_CYCLE_CFG_SUCCESS,
    payload,
    cb,
  };
}

export function modifyFutureCycleCfg(payload, cb) {
  return {
    type: types.MODIFY_FUTURE_CYCLE_CFG,
    payload,
    cb,
  };
}

export function createFutureCycleCfg(payload, cb) {
  return {
    type: types.CREATE_FUTURE_CYCLE_CFG,
    payload,
    cb,
  };
}

export function sendInvoice(payload, cb) {
  return {
    type: types.SEND_INVOICE,
    payload,
    cb,
  };
}

export function getExternalPOByAccountId(payload, cb) {
  return {
    type: types.GET_EXTERNAL_BY_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function createExternalPO(payload, cb) {
  return {
    type: types.CREATE_EXTERNAL_BY_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function modifyExternalPO(payload, cb) {
  return {
    type: types.MODIFY_EXTERNAL_BY_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function regenerateInvoice(payload, cb) {
  return {
    type: types.REGENERATE_INVOICE,
    payload,
    cb,
  };
}

export function modifyInvoiceStatus(payload, cb) {
  return {
    type: types.MODIFY_INVOICE_STATUS,
    payload,
    cb,
  };
}

export function getAccountStatement(payload, cb) {
  return {
    type: types.GET_ACCOUNT_STATEMENT,
    payload,
    cb,
  };
}

export function undoManualInvoice(payload, cb) {
  return {
    type: types.UNDO_MANUAL_INVOICE,
    payload,
    cb,
  };
}

export function searchOrderInComplete(payload, cb) {
  return {
    type: types.SEARCH_ORDER_IN_COMPLETE,
    payload,
    cb,
  };
}

export function searchNotesUnits(payload, cb) {
  return {
    type: types.SEARCH_NOTE_UNITS,
    payload,
    cb,
  };
}

export function getAccountAssets(payload, cb) {
  return {
    type: types.GET_ACCOUNT_ASSETS,
    payload,
    cb,
  };
}

export function getSpecialPriceByAccountId(payload, cb) {
  return {
    type: types.GET_SPECIAL_PRICE,
    payload,
    cb,
  };
}

export function createSpecialPrice(payload, cb) {
  return {
    type: types.CREATE_SPECIAL_PRICE,
    payload,
    cb,
  };
}

export function modifySpecialPrice(payload, cb) {
  return {
    type: types.MODIFY_SPECIAL_PRICE,
    payload,
    cb,
  };
}

export function updateOrderCustomerStatus(payload, cb) {
  return {
    type: types.UPDATE_CUSTOMER_ORDER_STATUS,
    payload,
    cb,
  };
}

export function modifyNotesUnit(payload, cb) {
  return {
    type: types.MODIFY_NOTES_UNIT,
    payload,
    cb,
  };
}

export function getOrderSummaryByAccountId(payload, cb) {
  return {
    type: types.GET_ORDER_SUMMARY_BY_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function updateBillingAccount(payload, cb) {
  return {
    type: types.UPDATE_BILLING_ACCOUNT,
    payload,
    cb,
  };
}

export function modifyInvoiceDueDate(payload, cb) {
  return {
    type: types.MODIFY_INVOICE_DUE_DATE,
    payload,
    cb,
  };
}

export function getAccountExchangeRates(payload, cb) {
  return {
    type: types.GET_EXCHANGE_RATE_BY_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function createAccountExchangeRates(payload, cb) {
  return {
    type: types.CREATE_EXCHANGE_RATE_BY_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function modifyAccountExchangeRates(payload, cb) {
  return {
    type: types.MODIFY_EXCHANGE_RATE_BY_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function getPreviousAccountMerge(payload, cb) {
  return {
    type: types.GET_PREVIOUS_ACCOUNT_MERGE,
    payload,
    cb,
  };
}

export function invoiceAccount(payload, cb) {
  return {
    type: types.INVOICE_ACCOUNT,
    payload,
    cb,
  };
}

export function getPreviousAccountMovement(payload, cb) {
  return {
    type: types.GET_PREVIOUS_ACCOUNT_MOVEMENT,
    payload,
    cb,
  };
}

export function getPaymentInstallmentByAccountId(payload, cb) {
  return {
    type: types.GET_PAYMENT_INSTALLMENT_BY_ACCOUNTID,
    payload,
    cb,
  };
}

export function createPaymentInstallment(payload, cb) {
  return {
    type: types.CREATE_PAYMENT_INSTALLMENT_BY_ACCOUNTID,
    payload,
    cb,
  };
}

export function modifyPaymentInstallment(payload, cb) {
  return {
    type: types.MODIFY_PAYMENT_INSTALLMENT_BY_ACCOUNTID,
    payload,
    cb,
  };
}

export function getInvoiceInstallmentsByInvoiceId(payload, cb) {
  return {
    type: types.GET_INVOICE_INSTALLMENTS_BY_INVOICEID,
    payload,
    cb,
  };
}

export function searchUsageTransaction(payload, cb) {
  return {
    type: types.SEARCH_USAGE_TRANSACTION,
    payload,
    cb,
  };
}

export function searchUsageTransactionSuccess(payload, cb) {
  return {
    type: types.SEARCH_USAGE_TRANSACTION_SUCCESS,
    payload,
    cb,
  };
}

export function getTransactionUnitByIdUsage(payload, cb) {
  return {
    type: types.GET_TRANSACTION_UNIT_BY_ID_USAGE,
    payload,
    cb,
  };
}

export function resendInvoiceVendor(payload, cb) {
  return {
    type: types.RESEND_INVOICE_VENDOR,
    payload,
    cb,
  };
}

export function undoInvoiceCancel(payload, cb) {
  return {
    type: types.UNDO_INVOICE_CANCEL,
    payload,
    cb,
  };
}

export function reprocessInvoice(payload, cb) {
  return {
    type: types.RE_PROCESS_INVOICE,
    payload,
    cb,
  };
}

export function getOrderHistory(payload, cb) {
  return {
    type: types.GET_ORDER_HISTORY,
    payload,
    cb,
  };
}

export function searchLadingBill(payload, cb) {
  return {
    type: types.SEARCH_LADING_BILL,
    payload,
    cb,
  };
}

export function getPacInterfaceRecordById(payload, cb) {
  return {
    type: types.GET_PAC_INTERFACE_RECORD_BY_ID,
    payload,
    cb,
  };
}

export function saveAccountNonBillables(payload, cb) {
  return {
    type: types.SAVE_ACCOUNT_NON_BILLABLES,
    payload,
    cb,
  };
}

export function moveAccount(payload, cb) {
  return {
    type: types.MOVE_ACCOUNT,
    payload,
    cb,
  };
}

export function getInvoiceTransactionSummary(payload, cb) {
  return {
    type: types.GET_INVOICE_TRANSACTION_SUMMARY,
    payload,
    cb,
  };
}

export function getPendingManualInvoice(payload, cb) {
  return {
    type: types.GET_PENDING_MANUAL_INVOICE,
    payload,
    cb,
  };
}

export function deletePendingManualInvoice(payload, cb) {
  return {
    type: types.DELETE_PENDING_MANUAL_INVOICE,
    payload,
    cb,
  };
}

export function saveManualInvoice(payload, cb) {
  return {
    type: types.SAVE_MANUAL_INVOICE,
    payload,
    cb,
  };
}

export function getInvoiceTaxSummary(payload, cb) {
  return {
    type: types.GET_INVOICE_TAX_SUMMARY,
    payload,
    cb,
  };
}

export function syncProjectApp(payload, cb) {
  return {
    type: types.SYNC_PROJECT_APP,
    payload,
    cb,
  };
}

export function saveAccountMovementData(payload, cb) {
  return {
    type: types.SAVE_ACCOUNT_MOVEMENT_DATA,
    payload,
    cb,
  };
}

export function getNotesUnitbyId(payload, cb) {
  return {
    type: types.GET_NOTES_UNIT_BY_ID,
    payload,
    cb,
  };
}

export function resendPaymentVendor(payload, cb) {
  return {
    type: types.RESEND_PAYMENT_VENDOR,
    payload,
    cb,
  };
}

export function resendNotesVendor(payload, cb) {
  return {
    type: types.RESEND_NOTES_VENDOR,
    payload,
    cb,
  };
}

export function getClientAccountStatement(payload, cb) {
  return {
    type: types.GET_CLIENT_ACCOUNT_STATEMENT,
    payload,
    cb,
  };
}

export function getUsedCfdiByTaxRegion(payload, cb) {
  return {
    type: types.GET_USED_CFDI_BY_TAX_REGION,
    payload,
    cb,
  };
}

export function getSplitTaxPerLine(payload, cb) {
  return {
    type: types.GET_SPLIT_TAX_PER_LINE,
    payload,
    cb,
  };
}

export function searchAccountAssets(payload, cb) {
  return {
    type: types.SEARCH_ACCOUNTS_ASSETS,
    payload,
    cb,
  };
}

export function searchSubscriptionByAccountId(payload, cb) {
  return {
    type: types.SEARCH_SUBSCRIPTION_BY_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function subscriptionTopUp(payload, cb) {
  return {
    type: types.SUBSCRIPTION_TOP_UP,
    payload,
    cb,
  };
}

export function getProvisioningByAccountId(payload, cb) {
  return {
    type: types.GET_PROVISIONING_BY_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function getMultiInvPymtInstallmentsByAccountId(payload, cb) {
  return {
    type: types.GET_MULTI_INV_PYMT_INSTALLMENTS_BY_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function setupMultiInvoicePaymentInstallment(payload, cb) {
  return {
    type: types.SETUP_MULTI_INVOICE_PAYMENT_INSTALLMENT,
    payload,
    cb,
  };
}

export function getSharingGroup(payload, cb) {
  return {
    type: types.GET_SHARING_GROUP,
    payload,
    cb,
  };
}

export function createSharingGroup(payload, cb) {
  return {
    type: types.CREATE_SHARING_GROUP,
    payload,
    cb,
  };
}

export function modifySharingGroup(payload, cb) {
  return {
    type: types.MODIFY_SHARING_GROUP,
    payload,
    cb,
  };
}

export function searchAllArOpsRequests(payload, cb) {
  return {
    type: types.SEARCH_ALL_AR_OPS_REQUESTS,
    payload,
    cb,
  };
}

export function getArOpsRequestById(payload, cb) {
  return {
    type: types.GET_AR_OPS_REQUEST_BY_ID,
    payload,
    cb,
  };
}

export function generatePaymentInstallmentReport(payload, cb) {
  return {
    type: types.GENERATE_PAYMENT_INSTALLMENT_REPORT,
    payload,
    cb,
  };
}

export function getAccountDetailForSelectParent(payload, cb) {
  return {
    type: types.GET_ACCOUNT_DETAIL_FOR_SELECT_PARENT,
    payload,
    cb,
  };
}

export function getUnallocatedPayment(payload, cb) {
  return {
    type: types.GET_UNALLOCATED_PAYMENT,
    payload,
    cb,
  };
}

export function getCustomersNoParentFilter(payload, cb) {
  return {
    type: types.GET_CUSTOMERS_NO_PARENT_FILTER,
    payload,
    cb,
  };
}

export function searchSubscriptionTopUp(payload, cb) {
  return {
    type: types.SEARCH_SUBSCRIPTION_TOP_UP,
    payload,
    cb,
  };
}
