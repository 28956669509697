import selectConstant from '../../utils/selectConstant';
import utils from '../../utils/utils';
import listCurrency from '../../utils/currency.json';
import { countryList } from '../../constantsApp/countryList';

export default {
  tenantDataCreate: {
    tenantMerchantAccounts: [
      {
        authType: 'HTTP_BASIC',
        name: '',
        type: 'TAX_GATEWAY',
        validity: 365,
        status: 'ACTIVE',
        startDate: '',
        taxGatewayAttributes: [
          {
            type: 'BASE_URL',
            url: '',
          },
          {
            type: 'CALCULATE_TAX',
            url: '',
          },
          {
            type: 'CREATE_CONFIG',
            url: '',
          },
          {
            type: 'MODIFY_CONFIG',
            url: '',
          },
          {
            type: 'READ_CONFIG',
            url: '',
          },
          {
            type: 'ADDRESS_LOOKUP',
            url: '',
          },
        ],
        httpBasicAttributes: [
          {
            clientId: '',
            clientProfileId: '',
            password: '',
            username: '',
          },
        ],
      },
      {
        authType: 'JSON_WEB_TOKEN',
        name: '',
        type: 'PAYMENT_GATEWAY',
        validity: 365,
        status: 'ACTIVE',
        startDate: '',
        paymentGatewayAttributes: [
          {
            type: 'BATCH',
            url: '',
          },
          {
            type: 'AUTHORIZE',
            url: '',
          },
          {
            type: 'CAPTURE',
            url: '',
          },
          {
            type: 'CREDIT',
            url: '',
          },
          {
            type: 'VOID',
            url: '',
          },
          {
            type: 'BASE_URL',
            url: '',
          },
        ],
        jwtAttributes: [
          {
            authorizationKey: '',
            merchantId: '',
            privateKey: '',
            publicKey: '',
          },
        ],
      },
      {
        authType: 'OAUTH2',
        name: '',
        type: 'FINANCE_GATEWAY',
        validity: 365,
        status: 'ACTIVE',
        startDate: '',
        financeGatewayAttributes: [
          {
            type: 'BASE_URL',
            url: '',
          },
          {
            type: 'READ_COA',
            url: '',
          },
          {
            type: 'MODIFY_COA',
            url: '',
          },
          {
            type: 'MODIFY_JOURNAL',
            url: '',
          },
          {
            type: 'RUN_REPORT',
            url: '',
          },
          {
            type: 'CREATE_COA',
            url: '',
          },
          {
            type: 'CREATE_JOURNAL',
            url: '',
          },
        ],
        oauthAttributes: [
          {
            clientId: '',
            clientSecret: '',
            companyId: '',
            refreshToken: '',
          },
        ],
      },
    ],
    tenantPropertyDefaults: {
      shortBillingCycle: true,
      paymentNotification: true,
      paymentAutoAllocation: true,
      paymentFailureNotification: true,
      invoiceDueNotification: true,
      useUsageStartTime: true,
    },
  },
  tenant: [
    {
      label: 'label.name',
      name: 'name',
    },
    {
      label: 'label.vaultUri',
      name: 'vaultUri',
    },
    {
      label: 'label.vaultPath',
      name: 'vaultPath',
    },
    {
      label: 'label.licenseKey',
      name: 'licenseKey',
    },
  ],

  fieldGetwayForm: [
    {
      label: 'label.merchantName',
      name: 'name',
      tOptions: 'selections:merchantName',
      required: true,
    },
    {
      label: 'label.status',
      name: 'status',
      tOptions: 'selections:statusTenant',
      required: true,
    },
    {
      label: 'label.validityInMonths',
      name: 'validity',
      isNumber: true,
      required: true,
    },
    {
      label: 'label.startDate',
      name: 'startDate',
      isFieldDate: true,
      required: true,
    },
    {
      label: 'label.merchantAuthType',
      name: 'authType',
      // disabled: true,
      tOptions: 'selections:authType',
      required: true,
    },
    {
      name: 'country',
      label: 'common:label.country',
      wrapperClass: 'col-md-4',
      options: countryList,
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.webApiType',
      name: 'webApiType',
      tOptions: 'selections:webApiType',
      // required: true,
    },
  ],

  fieldHttpBasicForm: [
    {
      label: 'label.clientId',
      name: 'clientId',
      required: true,
    },
    {
      label: 'label.clientProfileId',
      name: 'clientProfileId',
      required: true,
    },
    {
      label: 'label.username',
      name: 'username',
      required: true,
    },
    {
      label: 'label.password',
      name: 'password',
      isOptionType: true,
      required: true,
    },
  ],

  fieldJsonWebTokenForm: [
    {
      label: 'label.authorizationKey',
      name: 'authorizationKey',
      required: true,
    },
    {
      label: 'label.merchantId',
      name: 'merchantId',
      required: true,
    },
    {
      label: 'label.publicKey',
      name: 'publicKey',
      isOptionType: true,
      required: true,
    },
    {
      label: 'label.privateKey',
      name: 'privateKey',
      isOptionType: true,
      required: true,
    },
  ],

  fieldOauthForm: [
    {
      label: 'label.clientId',
      name: 'clientId',
      required: true,
    },
    {
      label: 'label.companyId',
      name: 'companyId',
      required: true,
    },
    {
      label: 'label.refreshToken',
      name: 'refreshToken',
      required: true,
    },
    {
      label: 'label.clientSecret',
      name: 'clientSecret',
      isOptionType: true,
      required: true,
    },
    {
      label: 'label.accessToken',
      name: 'accessToken',
      required: true,
    },
    // {
    //   label: 'label.refreshTokenExpiryDate',
    //   name: 'refreshTokenExpiryDate',
    //   type:'date',
    //   required: true,
    // },
    // {
    //   label: 'label.accessToken',
    //   name: 'accessToken',
    //   required: true,
    // },
  ],
  fieldApiKeyForm: [
    {
      label: 'label.clientId',
      name: 'clientId',
      required: true,
    },
    {
      label: 'label.clientProfileId',
      name: 'clientProfileId',
      required: true,
    },
    {
      label: 'label.key',
      name: 'key',
      required: true,
    },
    {
      label: 'label.value',
      name: 'value',
      isOptionType: true,
      required: true,
    },
    {
      label: 'label.addTo',
      name: 'addTo',
      required: true,
      tOptions: 'selections:apiKeyAddTo',
      type: 'select',
    },
  ],

  companyProfile: {
    withAddressesUSForm: [
      // {
      //   labelFilter: 'label.name',
      //   fieldName: 'name',
      // },
      // {
      //   labelFilter: 'label.vaultUri',
      //   fieldName: 'vaultUri',
      // },
      // {
      //   labelFilter: 'label.vaultPath',
      //   fieldName: 'vaultPath',
      // },
      // {
      //   labelFilter: 'label.licenseKey',
      //   fieldName: 'licenseKey',
      // },
      // {
      //   labelFilter: 'label.taxId',
      //   fieldName: 'companyTaxId',
      // },
      {
        labelFilter: 'label.taxId',
        fieldName: 'companyTaxId',
      },
      {
        labelFilter: 'label.taxRegimeId',
        fieldName: 'companyTaxRegime',
      },
      {
        labelFilter: 'label.externalUid',
        fieldName: 'externalUid',
      },
      {
        labelFilter: 'label.country',
        fieldSelect: 'country',
        optionSelect: countryList,
      },
      {
        labelFilter: 'label.state',
        fieldSelect: 'state',
        optionSelect: selectConstant.stateUSData,
      },
      {
        labelFilter: 'label.city',
        fieldSelect: 'city',
        fieldName: 'city',
        optionSelect: [],
      },
      {
        labelFilter: 'label.postalCode',
        fieldSelect: 'postalCode',
        fieldName: 'postalCode',
        optionSelect: [],
      },
      {
        labelFilter: 'label.number',
        fieldName: 'number',
        isNumber: true,
      },
      {
        labelFilter: 'label.address',
        fieldName: 'street',
      },
      {
        labelFilter: 'label.extraLine',
        fieldName: 'extraLine',
      },
      {
        labelFilter: 'label.landMark',
        fieldName: 'landMark',
      },
      {
        labelFilter: 'label.code',
        fieldName: 'code',
      },
      // {
      //   labelFilter: 'label.supportEmail',
      //   fieldName: 'enquiryEmail',
      // },
      // {
      //   labelFilter: 'label.supportContactNumber',
      //   fieldName: 'enquiryPhone',
      // },
      // // {
      // //   labelFilter: 'label.companyTaxId',
      // //   fieldName: 'companyTaxId',
      // // },
      // {
      //   labelFilter: 'label.tagLine',
      //   fieldName: 'companyTag',
      // },
      // {
      //   labelFilter: 'label.logoFile',
      //   fieldName: 'logoPath',
      //   fa: 'fa fa-external-link',
      // },
      // {
      //   labelFilter: 'label.taxVersion',
      //   fieldName: 'taxVersion',
      // },
    ],
    withAddressesNormalForm: [
      // {
      //   labelFilter: 'label.name',
      //   fieldName: 'name',
      // },
      // {
      //   labelFilter: 'label.vaultUri',
      //   fieldName: 'vaultUri',
      // },
      // {
      //   labelFilter: 'label.vaultPath',
      //   fieldName: 'vaultPath',
      // },
      // {
      //   labelFilter: 'label.licenseKey',
      //   fieldName: 'licenseKey',
      // },
      {
        labelFilter: 'label.taxId',
        fieldName: 'companyTaxId',
      },
      {
        labelFilter: 'label.taxRegimeId',
        fieldName: 'companyTaxRegime',
      },
      {
        labelFilter: 'label.externalUid',
        fieldName: 'externalUid',
      },
      {
        labelFilter: 'label.country',
        fieldSelect: 'country',
        optionSelect: countryList,
      },
      {
        fieldName: 'state',
        labelFilter: 'label.state',
      },
      {
        labelFilter: 'label.city',
        fieldName: 'city',
      },
      {
        fieldName: 'postalCode',
        labelFilter: 'label.postalCode',
      },
      {
        labelFilter: 'label.number',
        fieldName: 'number',
        isNumber: true,
      },
      {
        labelFilter: 'label.address',
        fieldName: 'street',
      },
      {
        labelFilter: 'label.extraLine',
        fieldName: 'extraLine',
      },
      {
        labelFilter: 'label.landMark',
        fieldName: 'landMark',
      },
      {
        labelFilter: 'label.code',
        fieldName: 'code',
      },
      // {
      //   labelFilter: 'label.supportEmail',
      //   fieldName: 'enquiryEmail',
      // },
      // {
      //   labelFilter: 'label.supportContactNumber',
      //   fieldName: 'enquiryPhone',
      // },
      // {
      //   labelFilter: 'label.tagLine',
      //   fieldName: 'companyTag',
      // },
      // {
      //   labelFilter: 'label.logoFile',
      //   fieldName: 'logoPath',
      //   fa: 'fa fa-external-link',
      // },
      // {
      //   labelFilter: 'label.taxVersion',
      //   fieldName: 'taxVersion',
      // },
    ],
  },

  platformDefaults: [
    {
      label: 'label.customerSegment',
      name: 'customerSegment',
    },
    {
      label: 'label.salesChannel',
      name: 'salesChannel',
      tOptions: 'selections:salesChannel',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      tOptions: 'selections:accountType',
    },
    {
      label: 'label.marketSegment',
      name: 'marketSegment',
      tOptions: 'selections:marketSegment',
    },
    {
      label: 'label.accountSubType',
      name: 'accountSubType',
      tOptions: 'selections:accountSubType',
    },
    {
      label: 'label.defaultCurrency',
      name: 'defaultCurrency',
    },
    {
      label: 'label.contactRole',
      name: 'contactRole',
      tOptions: 'selections:accountRole',
    },
    {
      label: 'label.addressRole',
      name: 'addressRole',
      tOptions: 'selections:accountRole',
    },
    {
      label: 'label.invoiceType',
      name: 'invoiceType',
      tOptions: 'selections:invoiceType',
    },
    {
      label: 'label.invoiceDelivery',
      name: 'invoiceDelivery',
      tOptions: 'selections:invoiceDelivery',
    },
    {
      label: 'label.paymentMethod',
      name: 'paymentMethod',
      tOptions: 'selections:paymentMethod',
    },
    {
      label: 'label.paymentTerm',
      name: 'paymentTerm',
      tOptions: 'selections:paymentTerm',
    },
    {
      label: 'label.initialTerm',
      name: 'initialTerm',
      isNumber: true,
    },
    {
      label: 'label.initialTermUnit',
      name: 'initialTermUnit',
      tOptions: 'selections:initialTermUnit',
    },
    {
      label: 'label.renewalTerm',
      name: 'renewalTerm',
      isNumber: true,
    },
    {
      label: 'label.renewalTermUnit',
      name: 'renewalTermUnit',
      tOptions: 'selections:initialTermUnit',
    },
    {
      label: 'label.sellingCompany',
      name: 'sellingCompany',
    },
    {
      label: 'label.productCompany',
      name: 'productCompany',
    },
    {
      label: 'label.productFamily',
      name: 'productFamily',
    },
    {
      label: 'label.productLine',
      name: 'productLine',
    },
    {
      label: 'label.productType',
      name: 'productType',
    },
    {
      label: 'label.productSubType',
      name: 'productSubType',
    },
    {
      label: 'label.lineOfBusiness',
      name: 'lineOfBusiness',
    },
    {
      label: 'label.legalEntity',
      name: 'legalEntity',
    },
    {
      label: 'label.organization',
      name: 'organizationUnit',
    },
    {
      label: 'label.setOfBooks',
      name: 'setOfBooks',
    },
    {
      label: 'label.phoneType',
      name: 'phoneType',
      tOptions: 'selections:phoneType',
    },
    {
      label: 'label.billingDom',
      name: 'billingDom',
      isNumber: true,
    },
    {
      label: 'label.billingFrequency',
      name: 'billingFrequency',
      tOptions: 'selections:billingFrequency',
    },
    {
      label: 'label.shortBillingCycle',
      name: 'shortBillingCycle',
      tOptions: 'selections:selectBool',
    },
    {
      label: 'label.paymentNotification',
      name: 'paymentNotification',
      tOptions: 'selections:selectBool',
    },
    {
      label: 'label.useUsageStartTime',
      name: 'useUsageStartTime',
      tOptions: 'selections:selectBool',
    },
    {
      label: 'label.paymentAutoAllocation',
      name: 'paymentAutoAllocation',
      tOptions: 'selections:selectBool',
    },
    {
      label: 'label.taxItemId',
      name: 'taxItemId',
    },
    {
      label: 'label.orderStatus',
      name: 'orderStatus',
      tOptions: 'selections:statusOrder',
    },
    {
      label: 'label.selfCareUserType',
      name: 'selfCareUserType',
      tOptions: 'selections:selfCareUserType',
    },
    {
      label: 'label.passwordExpiryInMonths',
      name: 'passwordExpiryInMonths',
      isNumber: true,
    },
    {
      label: 'label.defaultGLAccount',
      name: 'defaultGLAccount',
    },
    {
      label: 'label.batchSizeBilling',
      name: 'batchSizeBilling',
      isNumber: true,
    },
    {
      label: 'label.batchSizePayment',
      name: 'batchSizePayment',
      isNumber: true,
    },
    {
      label: 'label.batchSizeInvoice',
      name: 'batchSizeInvoice',
      isNumber: true,
    },
    {
      label: 'label.batchSizeCommon',
      name: 'batchSizeCommon',
      isNumber: true,
    },
    {
      label: 'label.paymentFailureNotification',
      name: 'paymentFailureNotification',
      tOptions: 'selections:selectBool',
    },
    {
      label: 'label.invoiceDueNotification',
      name: 'invoiceDueNotification',
      tOptions: 'selections:selectBool',
    },
    {
      label: 'label.invoiceReminderThreshold',
      name: 'invoiceReminderThreshold',
      isNumber: true,
    },
  ],
  collapsibleSelfCareMenu: [
    {
      title: 'label.keyLinksToShare',
      state: 'Key Links to Share',
      dataFields: [
        {
          label: 'label.shortTitle',
          name: 'shortTitle',
        },
        {
          label: 'label.loadVideo',
          name: 'loadVideo',
        },
        {
          label: 'label.loadImages',
          name: 'loadImages',
        },
      ],
    },
    {
      title: 'label.advertisement',
      state: 'Advertisement',
      dataFields: [
        {
          label: 'label.shortTitle',
          name: 'shortTitle',
        },
        {
          label: 'label.loadVideo',
          name: 'loadVideo',
        },
        {
          label: 'label.loadImages',
          name: 'loadImages',
        },
      ],
    },
    {
      title: 'label.mediaFile',
      state: 'Media File',
      dataFields: [
        {
          label: 'label.fileType',
          name: 'type',
          type: 'select',
          tOptions: 'selections:fileType',
        },
        {
          label: 'label.selectFile',
          name: 'title',
          fa: 'fa fa-external-link',
        },
        // {
        //   label: 'label.loadImages',
        //   name: 'loadImages',
        // },
      ],
    },
  ],
  collapsiblePlatformDefault: [
    {
      title: 'navbar:customerHub.main',
      state: 'CUSTOMER HUB',
      dataFields: [],
      ccpPropertiesFields: [
        {
          isTitle: true,
          title: 'navbar:commonHub.UIDefaults',
        },
        {
          label: 'label.customerSegment',
          name: 'customerSegment',
          tOptions: 'selections:customerSegment',
        },
        {
          label: 'label.accountType',
          name: 'accountType',
          tOptions: 'selections:accountType',
        },
        {
          label: 'label.accountSubType',
          name: 'accountSubType',
          tOptions: 'selections:accountSubType',
        },
        {
          label: 'label.salesChannel',
          name: 'salesChannel',
          tOptions: 'selections:salesChannel',
        },
        {
          label: 'label.marketSegment',
          name: 'marketSegment',
          tOptions: 'selections:marketSegment',
        },
        {
          label: 'label.sellingCompany',
          name: 'sellingCompany',
        },
        {
          label: 'label.defaultCurrency',
          name: 'defaultCurrency',
          options: utils.convertCurrency2Option(listCurrency.currencies),
        },
        {
          label: 'label.paymentMethod',
          name: 'paymentMethod',
          tOptions: 'selections:paymentMethod',
        },
        {
          label: 'label.initialTermUnit',
          name: 'initialTermUnit',
          tOptions: 'selections:initialTermUnit',
        },
        {
          label: 'label.initialTerm',
          name: 'initialTerm',
          isNumber: true,
        },
        {
          label: 'label.renewalTermUnit',
          name: 'renewalTermUnit',
          tOptions: 'selections:initialTermUnit',
        },
        {
          label: 'label.renewalTerm',
          name: 'renewalTerm',
          isNumber: true,
        },
        {
          label: 'label.lineOfBusiness',
          name: 'lineOfBusiness',
        },
        {
          label: 'label.legalEntity',
          name: 'legalEntity',
        },
        {
          label: 'label.phoneType',
          name: 'phoneType',
          tOptions: 'selections:phoneType',
        },
        {
          label: 'label.paymentTerm',
          name: 'paymentTerm',
          tOptions: 'selections:paymentTerm',
        },
        {
          label: 'label.landmarkLabel',
          name: 'landmarkLabel',
        },
        {
          label: 'label.extralineLabel',
          name: 'extralineLabel',
        },
        {
          isTitle: true,
          title: 'navbar:customerHub.subMain.customerManagement',
        },
        {
          label: 'label.createOrganization',
          name: 'createOrganization',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.propagateAddressChangeToBillingAccounts',
          name: 'propagateAddressChangeToBillingAccounts',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.sysAdminAccount',
          name: 'sysAdminAccount',
        },
        {
          label: 'label.multiCurrency',
          name: 'multiCurrency',
          tOptions: 'selections:selectBool',
        },
        {
          isTitle: true,
          title: 'navbar:customerHub.subMain.orderManagement',
        },
        {
          label: 'label.futureOrderDateSystemTime',
          name: 'futureOrderDateSystemTime',
        },
        {
          label: 'label.provisioningIdRequired',
          name: 'provisioningIdRequired',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.splitOpportunityEnabled',
          name: 'splitOpportunityEnabled',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.projectEnabled',
          name: 'projectEnabled',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.allowInFlightOrders',
          name: 'allowInFlightOrders',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.alignModifyPricingEffectiveDate',
          name: 'alignModifyPricingEffectiveDate',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.unlimitedValidityEndDate',
          name: 'unlimitedValidityEndDate',
        },
        {
          label: 'label.autoTaskSync',
          name: 'autoTaskSync',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.quotePricingLevel',
          name: 'quotePricingLevel',
          tOptions: 'selections:quotePricingLevel',
        },
        {
          label: 'label.accountOrderWrapperAPI',
          name: 'accountOrderWrapperAPI',
          tOptions: 'selections:selectBool',
        },
        {
          isTitle: true,
          title: 'navbar:commonHub.subscriptionManagement',
        },
        {
          label: 'label.useParentUsagePriceOffer',
          name: 'useParentUsagePriceOffer',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.prorateOnPartialSuspend',
          name: 'prorateOnPartialSuspend',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.prorateOnPartialCancel',
          name: 'prorateOnPartialCancel',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.defaultSubscription',
          name: 'defaultSubscription',
        },
        {
          label: 'label.trackSubscriptionCount',
          name: 'trackSubscriptionCount',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.subscriptionStatus',
          name: 'subscriptionStatus',
        },
        {
          label: 'label.alignSubscriptionEndDate',
          name: 'alignSubscriptionEndDate',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.prorateBilledOnSuspend',
          name: 'prorateBilledOnSuspend',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.multiSubscriptionEnabled',
          name: 'multiSubscriptionEnabled',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.subscriptionTopUpOfferId',
          name: 'subscriptionTopUpOfferId',
        },
        {
          label: 'label.firstInvoicePaymentActivation',
          name: 'firstInvoicePaymentActivation',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.prorateShortOrLongCycle',
          name: 'prorateShortOrLongCycle',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.crossCountryPurchase',
          name: 'crossCountryPurchase',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.autoOverrideGrantAccumulator',
          name: 'autoOverrideGrantAccumulator',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.billableService',
          name: 'billableServiceEnabled',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.unitPriceRoundingPrecision',
          name: 'unitPriceRoundingPrecision',
          isNumber: true,
          isInteger: true,
        },
        {
          label: 'label.exchangeRateForProvisioning',
          name: 'exchangeRateForProvisioning',
        },
        {
          isTitle: true,
          title: 'navbar:commonHub.provisioningManagement',
        },
        {
          label: 'label.partialFulfillment',
          name: 'partialFulfillment',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.partialProvisioningEnabled',
          name: 'partialProvisioningEnabled',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.provisioningEnabled',
          name: 'provisioningEnabled',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.defaulTrunkAccount',
          name: 'defaulTrunkAccount',
        },
        {
          label: 'label.sendProvisioningData',
          name: 'sendServiceProvDataToProvisioning',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.enterpriseProvisioningQueue',
          name: 'enterpriseManualProvisioningQueue',
        },
        {
          label: 'label.oldProvisioningSystemQueue',
          name: 'oldProvisioningSystemQueue',
        },
        {
          label: 'label.waitTimeForNextSequenceInSeconds',
          name: 'waitTimeForNextSequence',
          isNumber: true,
          isInteger: true,
        },
        {
          label: 'label.provisioningLagInSeconds',
          name: 'provisioningLag',
          isNumber: true,
          isInteger: true,
        },
      ],
    },
    {
      title: 'navbar:billingHub.main',
      state: 'BILLING HUB',
      dataFields: [],
      ccpPropertiesFields: [
        {
          isTitle: true,
          title: 'navbar:billingHub.subMain.billing',
        },
        {
          label: 'label.triggerPendingBillNewSubsActivation',
          name: 'triggerPendingBillNewSubsActivation',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.triggerPendingBillForClosedSubs',
          name: 'triggerPendingBillForClosedSubs',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.useShortBillingCycle',
          name: 'useShortBillingCycle',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.alignPendingBillToBillFrequency',
          name: 'alignPendingBillToBillFrequency',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.support29_30_31DayBilling',
          name: 'support29_30_31DayBilling',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.moveDayForwardFor29_30_31DayBilling',
          name: 'moveDayForwardFor29_30_31DayBilling',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.triggerInvoicingWithManualBilling',
          name: 'triggerInvoicingWithManualBilling',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.triggerInvoicingWithPendingBill',
          name: 'triggerInvoicingWithPendingBill',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.monthEndCloseThreshold',
          name: 'monthEndCloseThreshold',
          isNumber: true,
        },
        {
          label: 'label.advanceBillingInNoOfDays',
          name: 'advanceBillingInNoOfDays',
          isNumber: true,
        },
        {
          label: 'label.itemAsTransactionDescription',
          name: 'useItemForTxnDescription',
          tOptions: 'selections:selectBool',
        },
        {
          isTitle: true,
          title: 'navbar:billingHub.subMain.invoicing',
        },
        {
          label: 'label.triggerInvoicingOnNewSubsActivation',
          name: 'triggerInvoicingOnNewSubsActivation',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.includeSubsOnRegularInvoice',
          name: 'includeSubsOnRegularInvoice',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.includeSubsOnManualInvoice',
          name: 'includeSubsOnManualInvoice',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.invoiceEndDateInclusive',
          name: 'invoiceEndDateInclusive',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.recurringPeriodInInvoice',
          name: 'recurringPeriodInInvoice',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.ccListForInvoiceDelivery',
          name: 'ccListForInvoiceDelivery',
        },
        {
          label: 'label.invoiceApprovalEmailId',
          name: 'invoiceApprovalEmailId',
        },
        {
          label: 'label.invoiceApproval',
          name: 'invoiceApproval',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.generateInvoicePdf',
          name: 'generateInvoicePdf',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.addDatesToInvoiceDescription',
          name: 'addDatesToInvoiceDescription',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.autoSendInvoice',
          name: 'autoSendInvoice',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.pacCustomizedPdfLayout',
          name: 'pacCustomizedPdfLayout',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.invoiceCancellationReason',
          name: 'invoiceCancellationReason',
        },
        {
          label: 'label.reprocessInvoiceReason',
          name: 'reprocessInvoiceReason',
        },
        {
          label: 'label.returnPDFBase64',
          name: 'returnPDFBase64',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.fetchFinalizedInvNotePymt',
          name: 'fetchFinalizedInvNotePymt',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.substitutionSatCode',
          name: 'substitutionSatCode',
        },
        {
          label: 'label.sendInvoicePdfAndXml',
          name: 'sendInvoicePdfAndXml',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.templateType',
          name: 'templateType',
          tOptions: 'selections:templateFileType',
        },
        {
          isTitle: true,
          title: 'navbar:billingHub.subMain.taxation',
        },
        {
          label: 'label.taxApplicable',
          name: 'taxApplicable',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.taxItemId',
          name: 'taxItemId',
        },
        {
          label: 'label.taxValidateAddress',
          name: 'taxValidateAddress',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.taxExemptionMidStartCycle',
          name: 'taxExemptionMidStartCycle',
        },
        {
          label: 'label.taxExemptionEndCycle',
          name: 'taxExemptionEndCycle',
        },
        {
          label: 'label.additionalTax',
          name: 'additionalTax',
        },
        {
          label: 'label.splitTaxCalculationByItem',
          name: 'splitTaxCalculationByItem',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.ivaTaxItemId',
          name: 'ivaTaxItemId',
        },
        {
          label: 'label.iepsTaxItemId',
          name: 'iepsTaxItemId',
        },
        {
          label: 'label.primaryTaxType',
          name: 'primaryTaxType',
        },
        {
          label: 'label.addOnTaxType',
          name: 'addOnTaxType',
        },
        {
          label: 'label.splitTaxCalculationByServiceType',
          name: 'splitTaxCalculationByServiceType',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.taxByLineItem',
          name: 'taxByLineItem',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.taxExemptionOnTaxableAmount',
          name: 'taxExemptionOnTaxableAmount',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.searchPackageBundleToIncludeTax',
          name: 'searchPkgBnldToIncTax',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.accumulatorBasedTaxThresholds',
          name: 'accumulatorBasedTaxThresholds',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.avoidZeroAmountTaxTransaction',
          name: 'avoidZeroAmountTaxTransaction',
          tOptions: 'selections:selectBool',
        },
        {
          isTitle: true,
          title: 'navbar:billingHub.subMain.usage',
        },
        {
          label: 'label.allowUsageProcessing',
          name: 'allowUsageProcessing',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.useUsageStartTime',
          name: 'useUsageStartTime',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.reratePendingTransactionsOnly',
          name: 'reratePendingTransactionsOnly',
          tOptions: 'selections:selectBool',
        },
        {
          isTitle: true,
          title: 'navbar:commonHub.default',
        },
        {
          label: 'label.invoiceType',
          name: 'invoiceType',
          tOptions: 'selections:invoiceType',
        },
        {
          label: 'label.invoiceDelivery',
          name: 'invoiceDelivery',
          tOptions: 'selections:invoiceDelivery',
        },
        {
          label: 'label.billingDom',
          name: 'billingDom',
          isNumber: true,
        },
        {
          label: 'label.billingFrequency',
          name: 'billingFrequency',
          tOptions: 'selections:billingFrequency',
        },
      ],
    },

    {
      title: 'navbar:arHub.main',
      state: 'AR HUB',
      dataFields: [],
      ccpPropertiesFields: [
        {
          isTitle: true,
          title: 'navbar:arHub.subMain.payments',
        },
        {
          label: 'label.useAutoAllocation',
          name: 'useAutoAllocation',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.autoReverseWriteoffOnPayment',
          name: 'autoReverseWriteoffOnPayment',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.collectDebtFirst',
          name: 'collectDebtFirst',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.paymentSuspenseAccount',
          name: 'paymentSuspenseAccount',
        },
        {
          label: 'label.autoClientPaymentAllocation',
          name: 'autoClientPaymentAllocation',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.exchangeThreshold',
          name: 'exchangeThreshold',
          isNumber: true,
        },
        {
          label: 'label.pendingDepositAccount',
          name: 'pendingDepositAccount',
        },
        {
          label: 'label.autoAllocateFullDebtOnly',
          name: 'autoAllocateFullDebtOnly',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.paymentReceiptsAccount',
          name: 'paymentReceiptsAccount',
        },
        {
          label: 'label.crossCountryPayment',
          name: 'crossCountryPayment',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.pacEnabled',
          name: 'pacEnabled',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.batchFolioStamping',
          name: 'batchFolioStamping',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.separateStampingAPI',
          name: 'separateStampingAPI',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.alignInstallmentWithBdom',
          name: 'alignInstallmentWithBdom',
          tOptions: 'selections:selectBool',
        },
        {
          isTitle: true,
          title: 'navbar:arHub.subMain.AROperations',
        },
        {
          label: 'label.useProviderForARTax',
          name: 'useProviderForARTax',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.withTaxARRule',
          name: 'withTaxARRule',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.minimumCreditNoteThreshold',
          name: 'minimumCreditNoteThreshold',
          isNumber: true,
        },
        {
          label: 'label.minimumDebitNoteThreshold',
          name: 'minimumDebitNoteThreshold',
          isNumber: true,
        },
        {
          label: 'label.immediateNotesGeneration',
          name: 'immediateNotesGeneration',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.defaultTaxRuleForAROps',
          name: 'defaultTaxRuleForAROps',
          tOptions: 'selections:taxRule',
        },
        {
          label: 'label.fixedTaxRuleForAROps',
          name: 'fixedTaxRuleForAROps',
          tOptions: 'selections:taxRule',
        },
        {
          label: 'label.aropsSupportTicket',
          name: 'aropsSupportTicket',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.mandateSatCodeForAdjustments',
          name: 'mandateSatCodeForAdjustments',
          tOptions: 'selections:selectBool',
        },
        // common start
        {
          label: 'label.isCollectionScheduleCached',
          name: 'isCollectionScheduleCached',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.isCustomAttributeCached',
          name: 'isCustomAttributeCached',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.displayExchangeRateInHomeCurrency',
          name: 'displayExchangeRateInHomeCurrency',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.filterInstallmentInvoicesForAR',
          name: 'filterInstallmentInvoicesForAR',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.notesOnDispute',
          name: 'notesOnDispute',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.interCompanyCreditNoteItem',
          name: 'interCompanyCreditNoteItem',
        },
        {
          label: 'label.interCompanyInvoiceCreditReason',
          name: 'interCompanyInvoiceCreditReason',
        },
        // common End
      ],
    },
    {
      title: 'navbar:revenueHub.main',
      state: 'REVENUE HUB',
      dataFields: [],
      ccpPropertiesFields: [
        {
          isTitle: true,
          title: 'navbar:commonHub.default',
        },
        {
          label: 'label.defaultGLAccount',
          name: 'defaultGLAccount',
        },
        {
          label: 'label.coaExportFileName',
          name: 'coaExportFileName',
        },
        {
          label: 'label.financeSyncBatchSize',
          name: 'financeSyncBatchSize',
          isNumber: true,
        },
        {
          label: 'label.financeSyncBatchMode',
          name: 'financeSyncBatchMode',
        },
        {
          label: 'label.defaultCalendar',
          name: 'defaultCalendar',
        },
        {
          label: 'label.defaultCostCenter',
          name: 'defaultCostCenter',
        },
        {
          label: 'label.exchangeRateDeltaItemId',
          name: 'exchangeRateDeltaItemId',
        },
        {
          label: 'label.realizedGainItemId',
          name: 'realizedGainItemId',
        },
        {
          label: 'label.realizedLossItemId',
          name: 'realizedLossItemId',
        },
        {
          label: 'label.roundingPrecisionForExchangeRate',
          name: 'ROUNDING_PRECISION_FOR_EXCHANGE_RATE',
          isNumber: true,
          isInteger: true,
        },
        {
          label: 'label.revenueApportionPaymentToTax',
          name: 'revenueApportionPaymentToTax',
          tOptions: 'selections:selectBool',
        },
        {
          isTitle: true,
          title: 'navbar:commonHub.enablers',
        },
        {
          label: 'label.revenueTracking',
          name: 'revenueTracking',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.realTimeFinanceSync',
          name: 'realTimeFinanceSync',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.paymentFinanceSync',
          name: 'paymentFinanceSync',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.revenueRecognitionInSystemCurrency',
          name: 'revenueRecognitionInSystemCurrency',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.itemizeARRevenueExtract',
          name: 'itemizeARRevenueExtract',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.batchFinancialExtract',
          name: 'batchFinancialExtract',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.useGLCombination',
          name: 'useGLCombination',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.erpExtractGracePeriod',
          name: 'erpExtractGracePeriod',
          isNumber: true,
        },
        {
          label: 'label.matchBillingEntries',
          name: 'matchBillingEntries',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.skipNetZeroGLExtract',
          name: 'skipNetZeroGLExtract',
          tOptions: 'selections:selectBool',
        },
        {
          isTitle: true,
          title: 'navbar:commonHub.miscellaneous',
        },
        {
          label: 'label.reclassifyTrueUpRevenue',
          name: 'reclassifyTrueUpRevenue',
          tOptions: 'selections:selectBool',
        },
      ],
    },
    {
      title: 'navbar:operationsHub.main',
      state: 'OPERATIONS HUB',
      dataFields: [],
      ccpPropertiesFields: [
        {
          isTitle: true,
          title: 'navbar:operationsHub.subMain.deployment',
        },
        {
          label: 'label.defaultLanguage',
          name: 'defaultLanguage',
        },
        {
          label: 'label.pricingSync',
          name: 'pricingSync',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.queuePollDisabled',
          name: 'queuePollDisabled',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.timeAndBillingDeployment',
          name: 'timeAndBillingDeployment',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.quickbooksRefreshToken',
          name: 'quickbooksRefreshToken',
        },
        {
          label: 'label.multiCurrency',
          name: 'multiCurrency',
          tOptions: 'selections:selectBool',
        },
        {
          isTitle: true,
          title: 'navbar:operationsHub.subMain.userManagement',
        },
        {
          label: 'label.passwordExpiryInMonths',
          name: 'passwordExpiryInMonths',
          isNumber: true,
        },
        {
          label: 'label.authorizationEnabled',
          name: 'authorizationEnabled',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.userInMultipleGroups',
          name: 'userInMultipleGroups',
          tOptions: 'selections:selectBool',
        },
        {
          isTitle: true,
          title: 'navbar:operationsHub.subMain.correspondence',
        },
        {
          label: 'label.paymentNotification',
          name: 'paymentNotification',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.renewalReminderThreshold',
          name: 'renewalReminderThreshold',
          isNumber: true,
        },
        {
          label: 'label.trialExpiryReminderThreshold',
          name: 'trialExpiryReminderThreshold',
          isNumber: true,
        },
        {
          label: 'label.invoiceReadyNotification',
          name: 'invoiceReadyNotification',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.cancelSubscriptionNotification',
          name: 'cancelSubscriptionNotification',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.renewalDueNotification',
          name: 'renewalDueNotification',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.newSubscriptionNotification',
          name: 'newSubscriptionNotification',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.creditNoteReadyNotification',
          name: 'creditNoteReadyNotification',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.sendNotificationOnCollectionEntry',
          name: 'notificationOnCollectionEntry',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.paymentFailureNotification',
          name: 'paymentFailureNotification',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.embrixSmsGateway',
          name: 'embrixSmsGateway',
          tOptions: 'selections:selectBool',
        },
        {
          isTitle: true,
          title: 'navbar:commonHub.miscellaneous',
        },
        {
          label: 'label.batchSizeCommon',
          name: 'batchSizeCommon',
          isNumber: true,
        },
        {
          label: 'label.batchSizeBilling',
          name: 'batchSizeBilling',
          isNumber: true,
        },
        {
          label: 'label.batchSizeInvoice',
          name: 'batchSizeInvoice',
          isNumber: true,
        },
        {
          label: 'label.batchSizePayment',
          name: 'batchSizePayment',
          isNumber: true,
        },
        {
          label: 'label.dashboardMonths',
          name: 'dashboardMonths',
          isNumber: true,
        },
        {
          label: 'label.usageProcessBatchSize',
          name: 'usageProcessBatchSize',
          isNumber: true,
        },
        {
          label: 'label.ssoEnabled',
          name: 'ssoEnabled',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.provisioningLag',
          name: 'provisioningLag',
          isNumber: true,
        },
        {
          label: 'label.filterZeroAmount',
          name: 'filterZeroAmount',
          tOptions: 'selections:selectBool',
        },
        {
          label: 'label.skipAuthorizationGatewayAPIs',
          name: 'skipGatewayAuthorizationApis',
          type: 'textarea',
          tooltipDetails: 'common:message.skipGatewayAuthorizationApisInfo',
        },
      ],
    },
    {
      title: 'navbar:selfCareHub.main',
      state: 'SELF CARE HUB',
      dataFields: [],
      ccpPropertiesFields: [
        {
          label: 'label.selfcareRole',
          name: 'selfcareRole',
        },
        {
          label: 'label.contactRole',
          name: 'contactRole',
          tOptions: 'selections:accountRole',
        },
        {
          label: 'label.addressRole',
          name: 'addressRole',
          tOptions: 'selections:accountRole',
        },
        {
          label: 'label.selfCareUserType',
          name: 'selfCareUserType',
          tOptions: 'selections:selfCareUserType',
        },
        {
          label: 'label.selfCareUserCategory',
          name: 'selfCareUserCategory',
          tOptions: 'selections:category',
        },
        {
          label: 'label.historicalFolioLink',
          name: 'historicalFolioLink',
        },
      ],
    },
  ],
};

export const groupNamePermissions = {
  getTenantInfo: 'GET_TENANT_INFO',
  createTenantInfo: 'CREATE_TENANT_INFO',
  setLincenseData: 'SET_LICENSE_DATA',
  modifyLicenseData: 'MODIFY_LICENSE_DATA',
  setCompanyProfile: 'SET_COMPANY_PROFILE',
  modifyCompanyProfile: 'MODIFY_COMPANY_PROFILE',
  setPropertyDefaults: 'SET_PROPERTY_DEFAULTS',
  modifyPropertyDefaults: 'MODIFY_PROPERTY_DEFAULTS',
  setPyamentGatewayMerchant: 'SET_PAYMENT_GATEWAY_MERCHANT',
  setTaxGatewayMerchant: 'SET_TAX_GATEWAY_MERCHANT',
  setFinanceGatewayMerchant: 'SET_FINANCE_GATEWAY_MERCHANT',
  setCrmGatewayProvider: 'SET_CRM_GATEWAY_PROVIDER',
  setDataGatewayProvider: 'SET_DATA_GATEWAY_PROVIDER',
  setUsageGatewayProvider: 'SET_USAGE_GATEWAY_PROVIDER',
  setProvisioningGatewayProvider: 'SET_PROVISIONING_GATEWAY_PROVIDER',
  modifyPaymentGatewayMerchant: 'MODIFY_PAYMENT_GATEWAY_MERCHANT',
  modifyTaxGatewayMerchant: 'MODIFY_TAX_GATEWAY_MERCHANT',
  modifyFinanceGatewayMerchant: 'MODIFY_FINANCE_GATEWAY_MERCHANT',
  modifyCrmGatewayProvider: 'MODIFY_CRM_GATEWAY_PROVIDER',
  modifyDataGatewayProvider: 'MODIFY_DATA_GATEWAY_PROVIDER',
  modifyUsageGatewayProvider: 'MODIFY_USAGE_GATEWAY_PROVIDER',
  modifyProvisioningGatewayProvider: 'MODIFY_PROVISIONING_GATEWAY_PROVIDER',
  createGatewayCanonical: 'CREATE_GATEWAY_CANONICAL',
  modifyGatewayCanonical: 'MODIFY_GATEWAY_CANONICAL',
  getGatewayCanonical: 'GET_GATEWAY_CANONICAL',
  createPaymentMethodUrl: 'CREATE_PAYMENT_METHOD_URL',
  modifyPaymentMethodUrl: 'MODIFY_PAYMENT_METHOD_URL',
  getPaymentMethodUrl: 'GET_PAYMENT_METHOD_URL',
  getGatewayCanonicalSpec: 'GET_GATEWAY_CANONICAL_SPEC',
  createCustomDBConfig: 'CREATE_CUSTOM_DB_SEQUENCE_CONFIG',
  modifyCustomDBConfig: 'MODIFY_CUSTOM_DB_SEQUENCE_CONFIG',
  getCustomDBConfig: 'GET_CUSTOM_DB_SEQUENCE_CONFIG',
  GET_FOLIO_RESPONSE: 'GET_FOLIO_RESPONSE',
  CREATE_FOLIO_RESPONSE: 'CREATE_FOLIO_RESPONSE',
  MODIFY_FOLIO_RESPONSE: 'MODIFY_FOLIO_RESPONSE',
};

export const tenantSupportFields = [
  {
    labelFilter: 'label.supportEmail',
    fieldName: 'enquiryEmail',
  },
  {
    labelFilter: 'label.supportContactNumber',
    fieldName: 'enquiryPhone',
  },
  // {
  //   labelFilter: 'label.companyTaxId',
  //   fieldName: 'companyTaxId',
  // },
  {
    labelFilter: 'label.tagLine',
    fieldName: 'companyTag',
  },
  {
    labelFilter: 'label.logoFile',
    fieldName: 'logoPath',
    fa: 'fa fa-external-link',
  },
  {
    labelFilter: 'label.taxVersion',
    fieldName: 'taxVersion',
  },
];
