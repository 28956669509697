import gql from 'graphql-tag';

export default id => gql`
  query {
    getOrderAudit(id: "${id}") {
      id
      accountId
      status
      effectiveDate
      createdDate
      reason
      type
      orderDetailsJson
      userId
    }
  }
`;
