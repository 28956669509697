import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty, cloneDeep, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ChildrenPlacementType } from 'basicprimitives';
import { DataTable, FormWithTableItem, TablePagination, Switch, GenericInput, FamilyChartGroup } from '../../common';
import TitleFrom from '../../common/TitleFrom';
import { hanldeDataHierarchy, getPageTotalCount } from '../../../utils/utils';
import RouteNames from '../../../containers/App/RouteNames';
import ChildrentAccountSearchForm from '../ChildrentAccountSearchForm';
import CreatePaymentProfile from './CreatePaymentProfile';
import ModalParentId from '../../../containers/CustomerPage/CustomerNew/ModalSelectItemId';
import AccountSort from '../../../constantsApp/customer/AccountSort';

const colorList = [
  {
    label: 'label.currentAccount',
    color: '#28a745',
  },
  {
    label: 'label.parentAccount',
    color: 'orange',
  },
  {
    label: 'label.childAccount',
    color: 'grey',
  },
];

const color = {
  current: 'active',
  parent: 'inactive',
  child: 'closed',
};

const convertData = ({ customerInfo, isChildAccount, dataList, t, childData }) => {
  const isChild = !isChildAccount;
  const statusOptions = t ? t('selections:status')() : [];
  const parentSlt = statusOptions.find(i => i.value === customerInfo.status);

  const parentItem = {
    id: customerInfo.id,
    parent: isChild && dataList && dataList.length ? dataList[0].id : null,
    title: t('label.account'),
    templateName: 'normalSize',
    className: color.current,
    description: (
      <div className="col-md-12 row">
        <div className="col-md-12 row">
          <div className="title-description">{`${t('label.id')}:`}</div>
          <div className="description-content">{customerInfo.id}</div>
        </div>
        <div className="col-md-12 row">
          <div className="title-description">{`${t('label.paymentType')}:`}</div>
          <div className="description-content">
            {customerInfo?.paymentProfiles && customerInfo?.paymentProfiles[0]?.paymentMethod
              ? customerInfo.paymentProfiles[0].paymentMethod
              : ''}
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="title-description">{`${t('label.type')}:`}</div>
          <div className="description-content bold">{parentSlt ? parentSlt.label : ''}</div>
        </div>
      </div>
    ),
  };
  const data = [parentItem];

  if (dataList && dataList.length) {
    dataList.forEach(val => {
      const slt = statusOptions.find(i => i.value === val.status);
      const item = {
        id: val.id,
        parent: isChild ? null : customerInfo.id,
        title: t('label.account'),
        templateName: 'normalSize',
        description: (
          <div>
            <div className="col-md-12 row">
              <div className="title-description">{`${t('label.id')}:`}</div>
              <div className="description-content">{val.id}</div>
            </div>
            {val?.paymentProfiles && val?.paymentProfiles[0]?.paymentMethod && (
              <div className="col-md-12 row">
                <div className="title-description">{`${t('label.paymentType')}:`}</div>
                <div className="description-content">
                  {val?.paymentProfiles && val?.paymentProfiles[0]?.paymentMethod
                    ? val.paymentProfiles[0].paymentMethod
                    : ''}
                </div>
              </div>
            )}
            <div className="col-md-12 row">
              <div className="title-description">{`${t('label.type')}:`}</div>
              <div className="description-content bold">{slt ? slt.label : ''}</div>
            </div>
          </div>
        ),
        className: isChild ? color.parent : color.child,
      };
      data.push(item);
    });
  }
  if (childData && childData.length) {
    childData.forEach(val => {
      const slt = statusOptions.find(i => i.value === val.status);
      const item = {
        id: val.id,
        parent: val.parent,
        title: t('label.account'),
        className: color.child,
        templateName: 'normalSize',
        description: (
          <div className="col-md-12 row">
            <div className="col-md-12 row">
              <div className="title-description">{`${t('label.id')}:`}</div>
              <div className="description-content">{val.id}</div>
            </div>
            {val?.paymentProfiles && val?.paymentProfiles[0]?.paymentMethod && (
              <div className="col-md-12 row">
                <div className="title-description">{`${t('label.paymentType')}:`}</div>
                <div className="description-content">
                  {val?.paymentProfiles && val?.paymentProfiles[0]?.paymentMethod
                    ? val.paymentProfiles[0].paymentMethod
                    : ''}
                </div>
              </div>
            )}
            <div className="col-md-12 row">
              <div className="title-description">{`${t('label.type')}:`}</div>
              <div className="description-content bold">{slt ? slt.label : ''}</div>
            </div>
          </div>
        ),
        childrenPlacementType: ChildrenPlacementType.Vertical,
      };
      data.push(item);
    });
  }

  return data;
};

const tableColumns = [
  {
    name: 'id',
    label: 'common:label.ACCTNo',
    render: (colName, item) => (
      <Link className="text-success" to={RouteNames.prettifyPath(RouteNames.customerInfo.path, item.id)}>
        {item.id}
      </Link>
    ),
  },
  {
    name: 'firstName',
    label: 'common:label.firstName',
    sortable: true,
  },
  {
    name: 'lastName',
    label: 'common:label.lastName',
    sortable: true,
  },
  {
    name: 'organization',
    label: 'label.organization',
    sortable: true,
    style: { minWidth: '110px' },
    render: (name, item) => {
      return item && item.contacts && item.contacts[0] ? item.contacts[0].organization : null;
    },
  },
  {
    name: 'email',
    label: 'common:label.email',
  },
  {
    name: 'nonPaying',
    label: 'common:label.nonPaying',
    render: (colName, item) => {
      return <span>{item.nonPaying ? 'TRUE' : 'FALSE'}</span>;
    },
  },
  {
    name: 'city',
    label: 'common:label.city',
    sortable: true,
  },
  {
    name: 'state',
    label: 'common:label.state',
    sortable: true,
  },
  {
    name: 'currency',
    label: 'common:label.currency',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'common:label.reason',
  },
  {
    name: 'Detail',
    label: 'common:label.detail',
    render: (colName, item) => {
      return (
        <Link to={`/customers/${item.id}/info`} className="btn btn-outline-success btn-sm">
          View
        </Link>
      );
    },
  },
];

const Hierarchy = ({
  id,
  data,
  title,
  page,
  size,
  onPageChange,
  onSizeChange,
  totalCount,
  onHandleSearchChildAccount,
  isChildAccount,
  isSearchingChildAccount,
  doGetParentAndChildAccount,
  getPaymentConfigByTypeApp,
  moveAccount,
  paymentProfiles,
  getCcpDateTimeConfig,
  currencyOptions,
  customerInfo,
  searchChildAccount,
  defaultFilter,
  onSortChildAccount,
  sorted,
  ccpTime,
}) => {
  const formRef = useRef();
  const { t } = useTranslation('common');

  const [submitData, setSubmitData] = useState({ paymentProfile: paymentProfiles || {} });
  const [paymentTermOptions, setPaymentTermOptions] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [wasValidated, setValidate] = useState(false);
  const [currentCcpDateTime, setCurrentCcpDateTime] = useState(null);
  const [isMoveOutHierachy, setIsMoveOutHierachy] = useState(false);
  const [isTreeView, setIsTreeView] = useState(false);
  const [childData, setChildData] = useState(null);
  const [pageChildAccount, setPageChildAccount] = useState(0);
  const [sizeChilAccount, setSizeChilAccount] = useState(20);
  const [filterChildAccount, setFilterChildAccount] = useState({});
  const [totalChildAccount, setTotalChildAccount] = useState(null);
  const [sortedChildAccount, setSortedChildAccount] = useState({});
  const [moveAsNonPaying, setmoveAsNonPaying] = useState(false);

  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  const onToggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const onChangeCreditCards = ({ value, fieldName, isCallbackURL = false, newCreditCardCallback = [] }) => {
    let newData = cloneDeep(submitData);
    if (!newData.paymentProfile.creditCards || isEmpty(newData.paymentProfile.creditCards))
      newData.paymentProfile.creditCards = [{}];
    if (isCallbackURL) {
      newData = localStorage.getItem('dataCallback') ? JSON.parse(localStorage.getItem('dataCallback')) : newData;
      newData.paymentProfile.creditCards = newCreditCardCallback;
      newData.paymentProfile.paymentMethod = 'CREDIT_CARD';
      localStorage.removeItem('dataCallback');
      return setSubmitData(newData);
    }
    if (fieldName === 'last4CC') {
      newData.paymentProfile.creditCards[0][fieldName] =
        value.length <= 4 ? value : newData.paymentProfile.creditCards[0][fieldName];
    } else {
      newData.paymentProfile.creditCards[0][fieldName] = value;
    }

    return setSubmitData(newData);
  };

  const onHandleChangePayment = ({ value = '', name = '' }) => {
    const newData = cloneDeep(submitData);
    if (name === 'paymentMethod' && value === 'NON_PAYING') {
      newData.paymentProfile.paymentTerm = null;
    }
    newData.paymentProfile[name] = value;
    setSubmitData(newData);
  };

  let isNonPaying = false;
  if (submitData.paymentProfile && submitData.paymentProfile.length) {
    isNonPaying = submitData.paymentProfile.paymentMethod === 'NON_PAYING';
  }

  const onSelectParentId = parentItemId => {
    const newData = cloneDeep(submitData);
    newData.toAccount = parentItemId;
    setSubmitData(newData);
    setIsOpenModal(!isOpenModal);
  };

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true)) {
      return false;
    }
    const payload = cloneDeep(submitData);
    payload.fromAccount = id;
    let idRefetch = null;
    payload.effectiveDate = currentCcpDateTime || moment(new Date()).format('YYYY-MM-DD');
    if (payload.paymentProfile.paymentMethod === 'CREDIT_CARD' && !isEmpty(payload.paymentProfile.creditCards[0])) {
      payload.paymentProfile.creditCards[0].merchant = 'qabrain3';
    } else {
      delete payload.paymentProfile.creditCards;
    }
    if (payload.paymentProfile.paymentMethod === 'NON_PAYING' || moveAsNonPaying) {
      delete payload.billingProfiles;
      const newPaymentProfile = { paymentMethod: 'NON_PAYING' };
      payload.paymentProfile = newPaymentProfile;
    }
    if (!payload.toAccount || isMoveOutHierachy) {
      payload.toAccount = id;
    } else {
      idRefetch = submitData.toAccount || null;
    }
    // if (isMoveOutHierachy) {
    //   payload.toAccount = 'NONE';
    // }
    return moveAccount(payload, ({ success }) => {
      if (success) {
        setmoveAsNonPaying(false);
        doGetParentAndChildAccount({ id: idRefetch, isMoveOutHierachy, isOnSubmit: true });
      }
    });
  };

  const doGetChildAccount = useCallback(() => {
    if (customerInfo.parentId) {
      searchChildAccount(
        {
          page: pageChildAccount + 1,
          size: sizeChilAccount,
          filter: { parentId: customerInfo.id, ...filterChildAccount },
          sort: !isEmpty(sortedChildAccount)
            ? AccountSort[sortedChildAccount.sortCol][sortedChildAccount.sortDir]
            : null,
        },
        ({ success, data }) => {
          if (success) {
            setTotalChildAccount(
              getPageTotalCount({
                totalCount: totalChildAccount,
                page: pageChildAccount,
                size: sizeChilAccount,
                items: data,
              })
            );
            setChildData(
              data
                ? data.map(val => {
                    return { ...val, parent: id };
                  })
                : []
            );
          }
        }
      );
    }
  }, [
    customerInfo.parentId,
    customerInfo.id,
    searchChildAccount,
    pageChildAccount,
    sizeChilAccount,
    filterChildAccount,
    sortedChildAccount,
    totalChildAccount,
    id,
  ]);

  const onPageChangeChildAccount = page => {
    if (page !== pageChildAccount) setPageChildAccount(page);
  };

  const onSizeChangeChildAccount = size => {
    if (size !== sizeChilAccount) setSizeChilAccount(size);
  };

  const onSortColumnChildAccount = (sortCol, sortDir) => {
    setSortedChildAccount({ sortCol, sortDir });
  };

  const onHandleSearchChildAccountWithParent = filter => {
    setPageChildAccount(0);
    setFilterChildAccount(filter);
  };

  useEffect(() => {
    doGetChildAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doGetParentAndChildAccount();
    getPaymentConfigByTypeApp('PAYMENT_TERMS', ({ success, data }) => {
      if (success && data.paymentTerms) {
        const paymentTermOptions = data.paymentTerms.map(val => {
          return { label: val.paymentTerm, value: val.paymentTerm };
        });
        setPaymentTermOptions(paymentTermOptions ? sortBy(paymentTermOptions, ['label']) : []);
      }
    });
    if (!ccpTime) {
      getCcpDateTimeConfig('', ({ success, data }) => {
        if (success) {
          setCurrentCcpDateTime(data ? data.ccpTime : null);
        }
      });
    } else setCurrentCcpDateTime(ccpTime);
  }, [ccpTime, doGetParentAndChildAccount, getCcpDateTimeConfig, getPaymentConfigByTypeApp]);
  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }

  let paginationConfig = {};

  if (isChildAccount) {
    paginationConfig = {
      page,
      size,
      totalCount,
      onPageChange,
      onSizeChange,
    };
  }

  if (!isChildAccount) {
    paginationConfig = {
      page: pageChildAccount,
      size: sizeChilAccount,
      totalCount: totalChildAccount,
      onPageChange: onPageChangeChildAccount,
      onSizeChange: onSizeChangeChildAccount,
    };
  }

  return (
    <div className="col-md-12 mb-30">
      <FormWithTableItem
        title={t('label.customerHierarchy')}
        accountNum={id}
        commercialName={customerInfo?.commercialName || ''}
        isCommercialName={customerInfo?.customerSegment !== 'B2C'}
        currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
      >
        <div className="col-md-12 mb-3 row">
          {hanldeDataHierarchy({ data }) && hanldeDataHierarchy({ data }).length > 0 && (
            <GenericInput
              onChange={() => setIsTreeView(!isTreeView)}
              title={t('label.treeView')}
              value={isTreeView}
              name="isTreeView"
              type="switch"
              wrapperClass="m-auto"
            />
          )}
        </div>

        {!isTreeView && (
          <>
            {customerInfo.parentId && (
              <>
                <TitleFrom title={title} isTabel />
                {isChildAccount && (
                  <div className="col-md-12 ml-2 mr-2 mb-30">
                    <ChildrentAccountSearchForm onSubmit={onHandleSearchChildAccount} defaultFilter={defaultFilter} />
                  </div>
                )}
                <DataTable
                  columns={
                    isChildAccount
                      ? tableColumns
                      : tableColumns.map(val => {
                          const { sortable, ...rest } = val;
                          return rest;
                        })
                  }
                  isLoading={isSearchingChildAccount}
                  data={hanldeDataHierarchy({ data })}
                  noDataMessage={isEmpty(data) ? t('label.messageNoAccountHierarchy') : ''}
                  onSort={onSortChildAccount}
                  sorted={sorted || {}}
                />
              </>
            )}
            <form
              ref={formRef}
              noValidate
              className={`col-md-12 mr-3 pr-4 needs-validation ${wasValidated ? 'was-validated' : ''}`}
              onSubmit={onSubmit}
            >
              <div className="col-md-12 row mb-3 ml-3 pr-0 mr-3 pb-3 border-item">
                <div className="col-md-12 row mb-3 mt-3">
                  <Switch
                    onChange={() => setIsMoveOutHierachy(!isMoveOutHierachy)}
                    name="isChildAccount"
                    wrapperClass={`pl-2 pt-4 pr-3 ${!isMoveOutHierachy ? 'switch-active' : 'switch-non-active'}`}
                    title={t('common:label.moveIntoHierarchy')}
                    checked={!isMoveOutHierachy}
                  />
                  {!isChildAccount && (
                    <Switch
                      onChange={() => setIsMoveOutHierachy(!isMoveOutHierachy)}
                      name="isMoveOutHierachy"
                      wrapperClass={`pl-2 pt-4 pr-3 ${isMoveOutHierachy ? 'switch-active' : 'switch-non-active'}`}
                      title={t('common:label.moveOutOfHierarchy')}
                      checked={isMoveOutHierachy}
                    />
                  )}
                  {/* <Switch
                    onChange={() => setIsMoveOutHierachy(!isMoveOutHierachy)}
                    name="isMoveOutHierachy"
                    wrapperClass={`pl-2 pt-4 pr-3 ${isMoveOutHierachy ? 'switch-active' : 'switch-non-active'}`}
                    title={t('common:label.moveOutOfHierarchy')}
                    checked={isMoveOutHierachy}
                  /> */}
                  <GenericInput
                    label={t('common:label.parentId')}
                    value={submitData.toAccount}
                    name="toAccount"
                    onChange={() => {}}
                    fa="fa fa-external-link"
                    onClick={onToggleModal}
                    wrapperClass="col-md-4"
                    disabled={isMoveOutHierachy}
                  />
                </div>
                <CreatePaymentProfile
                  accountDetail={
                    moveAsNonPaying
                      ? { ...submitData.paymentProfile, paymentMethod: 'NON_PAYING' }
                      : submitData.paymentProfile
                  }
                  colItem="col-md-4"
                  isNewAccount
                  onHandleChange={onHandleChangePayment}
                  fieldObj="paymentProfile"
                  onChangeCreditCards={onChangeCreditCards}
                  isDisabled={isNonPaying}
                  isDisabledPaymentTerm={submitData?.paymentProfile?.paymentMethod === 'NON_PAYING'}
                  dataCallback={submitData}
                  paymentTermOptions={paymentTermOptions}
                  isNoNeedDisableNpnPaying={!isChildAccount}
                  isDisabledAll={isChildAccount}
                >
                  {!customerInfo.parentId && (!data || !data.length) && (
                    <GenericInput
                      label={t('common:label.moveAsNonPaying')}
                      value={moveAsNonPaying}
                      name="moveAsNonPaying"
                      onChange={() => setmoveAsNonPaying(!moveAsNonPaying)}
                      type="checkbox"
                      wrapperClass="mt-4 pt-2 col-md-4"
                    />
                  )}
                </CreatePaymentProfile>
                <div className="col-md-12">
                  <button type="submit" className="button button-border x-small float-right">
                    {t('label.submit')}
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
        {isTreeView && (
          <FamilyChartGroup
            wrapperClass="border-top"
            items={convertData({
              customerInfo,
              isChildAccount,
              dataList: hanldeDataHierarchy({ data }),
              t,
              childData,
            })}
            colorList={colorList}
            normalUsSize={{ width: 190, height: 85 }}
            normalOtherLangSize={{ width: 300, height: 85 }}
          />
        )}
      </FormWithTableItem>
      {!customerInfo.parentId && (
        <FormWithTableItem>
          <TitleFrom title={title} isTabel />
          {isChildAccount && (
            <div className="col-md-12 ml-2 mr-2 mb-30">
              <ChildrentAccountSearchForm onSubmit={onHandleSearchChildAccount} defaultFilter={defaultFilter} />
            </div>
          )}
          <DataTable
            columns={
              isChildAccount
                ? tableColumns
                : tableColumns.map(val => {
                    const { sortable, ...rest } = val;
                    return rest;
                  })
            }
            isLoading={isSearchingChildAccount}
            data={hanldeDataHierarchy({ data })}
            noDataMessage={isEmpty(data) ? t('label.messageNoAccountHierarchy') : ''}
            onSort={onSortChildAccount}
            sorted={sorted || {}}
          />
        </FormWithTableItem>
      )}
      {!isChildAccount && !isTreeView && (
        <FormWithTableItem>
          <div className="col-md-12 ml-2 mr-2 mb-30">
            <ChildrentAccountSearchForm
              onSubmit={onHandleSearchChildAccountWithParent}
              defaulFilter={filterChildAccount}
            />
          </div>
          <TitleFrom title={t('common:label.childAccount')} isTabel />
          <DataTable
            columns={tableColumns}
            sorted={sortedChildAccount}
            isLoading={isSearchingChildAccount}
            data={childData || []}
            onSort={onSortColumnChildAccount}
          />
        </FormWithTableItem>
      )}
      {!isTreeView && (
        <div className="mt-15 mb-30">
          <TablePagination
            pageNumber={paginationConfig.page}
            pageSize={paginationConfig.size}
            totalCount={paginationConfig.totalCount}
            onPageChange={paginationConfig.onPageChange}
            onSizeChange={paginationConfig.onSizeChange}
          />
        </div>
      )}
      <ModalParentId
        isOpen={isOpenModal}
        selectedParentId={submitData.toAccount}
        onSelect={onSelectParentId}
        onCancel={onToggleModal}
        toggleModal={onToggleModal}
        isNoParentFilter
      />
    </div>
  );
};

Hierarchy.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  id: PropTypes.string,
  title: PropTypes.string,
  page: PropTypes.number,
  size: PropTypes.number,
  onPageChange: PropTypes.func,
  onSizeChange: PropTypes.func,
  totalCount: PropTypes.number,
  onHandleSearchChildAccount: PropTypes.func,
  isChildAccount: PropTypes.bool,
  isSearchingChildAccount: PropTypes.bool,
};

Hierarchy.defaultProps = {
  id: '',
  title: '',
  data: [],
  isSearchingChildAccount: false,
  isChildAccount: false,
  onHandleSearchChildAccount: () => {},
  onSizeChange: () => {},
  onPageChange: () => {},
  page: 1,
  size: 20,
  totalCount: null,
};

export default Hierarchy;
