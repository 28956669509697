import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import {
  uploadMultiPartFiles,
  getARReasonCodeSelectionConfig,
  getNotesUnitbyIdConfig,
  modifyNotesUnitConfig,
  resendNotesVendorConfig,
  getObjectFileById,
  saveOutputTemplateByType,
  getOutputTemplateByType,
  bulkProcess,
  getCcpPropertiesConfig,
} from '../../../App/actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { CreditNotesSearchForm } from '../../../../components/ReportHub';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  ButtonExport,
  CheckField,
  ModalWithItem,
  FormCollapse,
  GenericInput,
  DragDropConfigExportTemplate,
  ModalViewNote,
  EmailGroup,
} from '../../../../components/common';
import ModalSearchObjectApprovals from '../../../../components/common/ModalSearchObjectApprovals';
import { sortCreditNotes } from '../../constants';
import { getCreditNote, getListAllCreditNote } from '../../actions';
import { makeGetListCreditNotes, makeGetListAllCreditNotes } from '../../selectors';
import {
  getPageTotalCount,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  formatMoneyValue,
  configOutputTemplateByType,
  parseToMutationRequestBulk,
} from '../../../../utils/utils';
import { getUserId } from '../../../../localStorages';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfFile';
import { PAGE_SIZE_DOWNLOAD, statusDisableFielsdViewFolio } from '../../../../utils/constants';

const checkExistMatchAllStatus = ({ listItemSelect, statusList }) => {
  return !!listItemSelect.find(val => statusList.indexOf(val.status) === -1);
};

const checkMatchAllFolioStatus = ({ listItemSelect, folioStatusList }) => {
  return !!listItemSelect.find(val => folioStatusList.indexOf(val.folioStatus) === -1);
};

const messageNotesAction = t => <div dangerouslySetInnerHTML={{ __html: t('message.messgaeNoteCreditNoteAction') }} />;

const listCreditDebitNoteFields = [
  {
    label: 'label.notesId',
    name: 'id',
    readOnly: true,
  },
  {
    label: 'label.reason',
    name: 'reason',
    readOnly: true,
  },
  {
    label: 'label.invoiceId',
    name: 'invoiceId',
    readOnly: true,
  },
];

const collapsibleMenuCreditDebitNotes = [
  {
    title: 'common:label.notesSummaryLines',
    state: 'notesSummaryLines',
  },
  {
    title: 'common:label.taxLines',
    state: 'taxLines',
  },
];

const tableTaxLinesCreditDebitNote = [
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'city',
    label: 'label.city',
  },
  {
    name: 'state',
    label: 'label.state',
  },
  {
    name: 'jurisdiction',
    label: 'label.jurisdiction',
  },
  {
    name: 'taxCode',
    label: 'label.taxCode',
  },
  {
    name: 'taxCategory',
    label: 'label.taxCategory',
  },
  {
    name: 'amount',
    label: 'label.amount',
    isAmount: true,
  },
  {
    name: 'taxRate',
    label: 'label.taxableRate',
    isAmount: true,
  },
  {
    name: 'taxableAmount',
    label: 'label.taxableAmount',
    isAmount: true,
  },
  {
    name: 'exemptAmount',
    label: 'label.exemptAmount',
    isAmount: true,
  },
];

let tableColumnsDefault = null;
let tableColumnsOutput = null;

class CreditNotes extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {
      startDate: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .endOf('month')
        .format('YYYY-MM-DD'),
    },
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listCreditNotes: [],
    listReasonOptions: [],
    creditDebitNoteData: null,
    isOpenModalDetails: false,
    itemSelected: null,
    activeTab: {},
    itemSelect: null,
    actionSelected: null,
    comments: '',
    notes: '',
    idOpenModalNote: null,
    isOpenModalNotesAction: false,
    listCreditId: [],
    listItemSelect: [],
    isPacEnabled: false,
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listCreditNotes: props.listCreditNotes,
      totalCount: getPageTotalCount({ ...state, items: props.listCreditNotes }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetCreditNoteReport },
      getARReasonCodeSelectionConfig,
      isArOperations,
      location,
      getCcpPropertiesConfig,
    } = this.props;
    if (modeGetCreditNoteReport) {
      if (location && location.state && location.state) {
        const { filter, page, size, sort, sorted, totalCount } = location.state || {};
        this.setState({ filter, page, size, sort, sorted, totalCount }, () => {
          this.doGetReportCreditNotes();
        });
      } else {
        this.doGetReportCreditNotes();
      }
      this.doGetOutputTemplateByType();
      getARReasonCodeSelectionConfig('', ({ success, data }) => {
        if (success && data) {
          const listReasonOptions = data
            .filter(dt => !!dt.customReasonCode)
            .map(val => {
              return { label: val.customReasonCode, value: val.customReasonCode };
            });
          this.setState({ listReasonOptions: listReasonOptions ? sortBy(listReasonOptions, ['label']) : [] });
        }
      });
      // getCcpPropertiesConfig('', ({ success, data }) => {
      //   let isPacEnabled = false;
      //   if (success) {
      //     const pacEnabledProperty = data.ccpPropertyList.find(val => val.property === 'pacEnabled');
      //     if (pacEnabledProperty && pacEnabledProperty.value) {
      //       isPacEnabled = pacEnabledProperty.value === 'true';
      //     }
      //   }
      //   this.setState({
      //     isPacEnabled,
      //   });
      // });
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetReportCreditNotes();
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doGetReportCreditNotes());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetReportCreditNotes());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetReportCreditNotes();
    });
  };

  doGetReportCreditNotes = () => {
    const { filter, page, size, sorted } = this.state;
    const { getCreditNote } = this.props;
    const newFilter = cloneDeep(filter);
    if (newFilter.groupByNote) newFilter.groupByNote = newFilter.groupByNote === 'TRUE' ? 'true' : 'false';
    if (newFilter.invoiceCancelllation)
      newFilter.invoiceCancelllation = newFilter.invoiceCancelllation === 'TRUE' ? 'true' : 'false';
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortCreditNotes[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    getCreditNote(payload, () => {
      this.setState({
        isSearching: false,
        creditDebitNoteData: null,
        isOpenModalDetails: false,
        itemSelected: null,
        activeTab: {},
        itemSelect: null,
        actionSelected: null,
        comments: '',
        notes: '',
        creditIdSelected: null,
        // listCreditId: [],
        // listItemSelect: [],
      });
    });
  };

  doGetAllCreditNotes = cb => {
    const { filter, sorted } = this.state;
    const { getListAllCreditNote } = this.props;
    const newFilter = cloneDeep(filter);
    if (newFilter.groupByNote) newFilter.groupByNote = newFilter.groupByNote === 'TRUE' ? 'true' : 'false';
    if (newFilter.invoiceCancelllation)
      newFilter.invoiceCancelllation = newFilter.invoiceCancelllation === 'TRUE' ? 'true' : 'false';
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortCreditNotes[sorted.sortCol][sorted.sortDir] : null,
    };
    getListAllCreditNote(payload, () => {
      if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAllCreditNotes(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllCreditNotes, t } = this.props;
        convertJson2Pdf({
          data: listAllCreditNotes,
          t,
          title: t('reportPage:sidebar.creditNotes').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.creditNotes')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
          fontSize: 3,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = ({ subject, emailBody }) => {
    this.doGetAllCreditNotes(() => {
      const { listAllCreditNotes, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_CREDIT_NOTE_REPORT',
          subject: subject || `Embrix Report ${t('reportPage:sidebar.creditNotes')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('reportPage:sidebar.creditNotes')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllCreditNotes,
            t,
            columns: tableColumnsOutput,
            listOptionFields: { arType: 'arType', status: 'noteStatus', accountType: 'accountType' },
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllCreditNotes,
          t,
          title: t('reportPage:sidebar.creditNotes').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.creditNotes')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumnsOutput,
          fontSize: 3,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('reportPage:sidebar.creditNotes')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  onToggleModalDetails = item => {
    const { isOpenModalDetails } = this.state;
    const { getNotesUnitbyIdConfig } = this.props;
    if (!isOpenModalDetails && item) {
      if (item.id) {
        getNotesUnitbyIdConfig(item.id, ({ success, data }) => {
          this.setState({ creditDebitNoteData: success ? data || null : null, itemSelected: item });
        });
      }
    }
    this.setState({ isOpenModalDetails: !isOpenModalDetails });
  };

  renderItem = item => {
    const { creditDebitNoteData, itemSelected } = this.state;
    const tableCreditDebitLines = [
      {
        name: 'index',
        label: 'label.index',
      },
      {
        name: 'name',
        label: 'label.name',
      },
      {
        name: 'appliedStart',
        label: 'label.startDate',
      },
      {
        name: 'appliedEnd',
        label: 'label.endDate',
      },
      {
        name: 'itemId',
        label: 'label.itemId',
      },
      {
        name: 'currency',
        label: 'label.currency',
        render: () => {
          return <span>{itemSelected ? itemSelected.currency : ''}</span>;
        },
      },
      {
        name: 'amount',
        label: 'label.amount',
      },
      {
        name: 'taxRule',
        label: 'label.taxRule',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:taxRule')().find(val => val.value === item.taxRule) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'notesType',
        label: 'label.notesType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:notesCrDrType')().find(val => val.value === item.notesType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
    ];

    switch (item.state) {
      case 'notesSummaryLines':
        return (
          <div className="col-md-12">
            <DataTable
              columns={tableCreditDebitLines}
              data={creditDebitNoteData?.notesSummaryList ? creditDebitNoteData.notesSummaryList : []}
            />
          </div>
        );
      case 'taxLines':
        return (
          <div className="col-md-12">
            <DataTable
              columns={tableTaxLinesCreditDebitNote}
              data={creditDebitNoteData?.invoiceTaxList ? creditDebitNoteData.invoiceTaxList : []}
            />
          </div>
        );
      default:
        return null;
    }
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({ activeTab: { name: activeTabName, isActive: !activeTab.isActive } });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onSubmitUpdateStatus = () => {
    const { modifyNotesUnitConfig } = this.props;
    const { listCreditId, actionSelected, comments, notes } = this.state;
    if (listCreditId && listCreditId.length === 1) {
      const payloads = listCreditId.map(val => {
        if (actionSelected === 'APPROVED') {
          return { status: actionSelected, id: val, userId: getUserId(), comments, notes };
        }
        return { status: actionSelected, id: val, userId: getUserId(), comments };
      });
      return modifyNotesUnitConfig(payloads[0], ({ success }) => {
        if (success) {
          this.doGetReportCreditNotes();
          this.setState({
            actionSelected: null,
            creditIdSelected: null,
            comments: null,
            notes: null,
            listItemSelect: [],
            listInvoiceUnitId: [],
          });
        }
      });
    }
    const payloads = listCreditId.map(val => {
      const payload = { status: actionSelected, id: val, userId: getUserId(), comments };
      if (actionSelected === 'APPROVED') {
        return parseToMutationRequestBulk({ ...payload, notes });
      }
      return parseToMutationRequestBulk(payload);
    });
    return this.doBulkProcess({
      apiName: actionSelected === 'APPROVED' ? 'APPROVE_NOTES' : 'DISAPPROVE_NOTES',
      serviceName: 'modifyNotesUnit',
      payloads,
    });
  };

  onHandleResendToVendor = () => {
    const { resendNotesVendorConfig } = this.props;
    const { listCreditId } = this.state;
    if (listCreditId && listCreditId.length === 1) {
      const payloads = listCreditId.map(val => {
        return val;
      });
      return resendNotesVendorConfig(payloads[0], ({ success }) => {
        if (success) {
          this.doGetReportCreditNotes();
          this.setState({
            actionSelected: null,
            creditIdSelected: null,
            comments: null,
            notes: null,
            listItemSelect: [],
            listInvoiceUnitId: [],
          });
        }
      });
    }
    const payloads = listCreditId.map(val => {
      return parseToMutationRequestBulk({ id: val });
    });
    return this.doBulkProcess({
      apiName: 'RESEND_NOTES_TO_VENDOR',
      serviceName: 'resendNotesVendor',
      payloads,
    });
  };

  doGetObjectFileById = invoiceId => {
    const { history, getObjectFileById, isArOperations } = this.props;
    const { filter, page, size, sort, sorted, totalCount } = this.state;
    getObjectFileById(invoiceId, data => {
      if (data && data.length) {
        history.push({
          pathname: RouteNames.prettifyPath(RouteNames.invoicesInfo.path, invoiceId),
          state: {
            fromPath: isArOperations ? RouteNames.arCreditNotesReport.path : RouteNames.creditNotesReport.path,
            fileData: data,
            filter,
            page,
            size,
            sort,
            sorted,
            totalCount,
          },
        });
      }
    });
  };

  doGetObjectFileByIdCreditNote = invoiceId => {
    const { history, getObjectFileById, isArOperations } = this.props;
    const { filter, page, size, sort, sorted, totalCount } = this.state;
    getObjectFileById(invoiceId, data => {
      if (data && data.length) {
        history.push({
          pathname: RouteNames.prettifyPath(RouteNames.creditNotesInfo.path, invoiceId),
          state: {
            fromPath: isArOperations ? RouteNames.arCreditNotesReport.path : RouteNames.creditNotesReport.path,
            fileData: data,
            filter,
            page,
            size,
            sort,
            sorted,
            totalCount,
          },
        });
      }
    });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('CREDIT_NOTES_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({ columns: tableColumnsDefault, data, t }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  onHandleSaveConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idOutputTemplate, typeOutputTemplate } = this.state;
    const payload = {
      id: idOutputTemplate,
      type: typeOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputTemplateByType();
        this.setState({ exportColumnsSave: columns, isOpenModalExportConfig: false });
      }
    });
  };

  onToggleModalExportConfig = () => {
    const { isOpenModalExportConfig } = this.state;
    this.setState({ isOpenModalExportConfig: !isOpenModalExportConfig });
  };

  doBulkProcess = ({ serviceName, apiName, payloads }) => {
    const { bulkProcess } = this.props;
    bulkProcess({ serviceName, apiName, payloads }, ({ success }) => {
      if (success) {
        this.doGetReportCreditNotes();
        this.setState({
          actionSelected: null,
          creditIdSelected: null,
          comments: null,
          notes: null,
          listCreditId: [],
          listItemSelect: [],
        });
      }
    });
  };

  onToggleModalNotesAction = () => {
    const { isOpenModalNotesAction } = this.state;
    this.setState({ isOpenModalNotesAction: !isOpenModalNotesAction });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listCreditNotes,
      listReasonOptions,
      emails,
      filter,
      creditIdSelected,
      isOpenModalDetails,
      itemSelected,
      activeTab,
      itemSelect,
      actionSelected,
      comments,
      notes,
      idOpenModalNote,
      exportColumnsSave,
      isOpenModalExportConfig,
      listCreditId,
      listItemSelect,
      isOpenModalNotesAction,
      isPacEnabled,
    } = this.state;
    const {
      listAllCreditNotes,
      t,
      isArOperations,
      permissions: { modeGetCreditNoteReport, modeDownloadReport, modeGetMailReport },
      location,
    } = this.props;
    if (!modeGetCreditNoteReport) return '';
    const tabelColumnExportCreditNotes = [
      {
        name: 'notesDate',
        label: 'label.date',
        sortable: true,
      },
      {
        name: 'id',
        label: 'label.creditNoteId',
        sortable: true,
        render: (colName, item) => {
          return (
            <button
              className="text-success no-border"
              type="button"
              onClick={() => this.doGetObjectFileByIdCreditNote(item.id)}
              disabled={statusDisableFielsdViewFolio.indexOf(item.folioStatus) === -1}
            >
              {item.id}
            </button>
          );
        },
      },
      {
        name: 'status',
        label: 'label.creditNoteStatus',
        fieldOption: 'noteStatus',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:noteStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'folioStatus',
        label: 'label.folioStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:folioStatus')().find(val => val.value === item.folioStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'invoiceId',
        label: 'label.invoiceId',
        render: (colName, item) => {
          return (
            <button
              className="text-success no-border"
              type="button"
              onClick={() => this.doGetObjectFileById(item.invoiceId)}
            >
              {item.invoiceId}
            </button>
          );
        },
      },
      {
        name: 'invoiceStatus',
        label: 'label.invoiceStatus',
        fieldOption: 'invoiceUnitStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:invoiceUnitStatus')().find(val => val.value === item.invoiceStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'invoiceCancellation',
        label: 'common:label.invoiceCancellation',
        render: (colName, item) => {
          return <span>{item?.invoiceCancellation ? 'TRUE' : item?.invoiceCancellation === false ? 'FALSE' : ''}</span>;
        },
      },
      {
        name: 'organization',
        label: 'label.organization',
        sortable: true,
      },
      {
        name: 'commercialName',
        label: 'label.commercialName',
      },
      {
        name: 'accountId',
        label: 'label.accountId',
      },
      {
        name: 'firstName',
        label: 'label.firstName',
      },
      {
        name: 'notes',
        label: 'label.notes',
      },
      {
        name: 'lastName',
        label: 'label.lastName',
      },
      {
        name: 'arType',
        label: 'label.type',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:arType')().find(val => val.value === item.arType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'currency',
        label: 'label.currency',
      },
      {
        name: 'reason',
        label: 'label.reason',
      },
      {
        name: 'ticketNumber',
        label: 'label.supportTicket',
      },
      {
        name: 'description',
        label: 'label.description',
      },
      {
        name: 'summaryTotal',
        label: 'label.summaryTotal',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.summaryTotal)}</span>;
        },
      },
      {
        name: 'addOnTaxRate',
        label: 'label.addOnTaxRate',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.addOnTaxRate)}</span>;
        },
      },
      {
        name: 'addOnTaxAmount',
        label: 'label.addOnTaxAmount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.addOnTaxAmount)}</span>;
        },
      },
      {
        name: 'mainTaxRate',
        label: 'label.mainTaxRate',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.mainTaxRate)}</span>;
        },
      },
      {
        name: 'mainTaxAmount',
        label: 'label.mainTaxAmount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.mainTaxAmount)}</span>;
        },
      },
      {
        name: 'total',
        label: 'label.total',
        sortable: true,
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.total)}</span>;
        },
      },
      {
        name: 'groupApproval',
        label: 'label.groupApproval',
      },
      {
        name: 'accountType',
        label: 'label.accountType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
    ];

    tableColumnsDefault = tabelColumnExportCreditNotes;

    const exportColumns = exportColumnsSave || {};

    tableColumnsOutput = exportColumns?.targetFields?.items || [];
    let newTabelColumnExportCreditNotes = tableColumnsOutput;

    let creditNotesSelectOptions = t('selections:creditNoteActions')();
    if (listItemSelect && checkExistMatchAllStatus({ listItemSelect, statusList: ['PENDING_APPROVAL'] })) {
      creditNotesSelectOptions = creditNotesSelectOptions.filter(
        val => ['APPROVED', 'DISAPPROVED'].indexOf(val.value) === -1
      );
    }
    if (
      listItemSelect &&
      checkMatchAllFolioStatus({
        listItemSelect,
        folioStatusList: ['CANCELLATION_STAMPING_REQUESTED', 'STAMPING_REQUESTED'],
      })
    ) {
      creditNotesSelectOptions = creditNotesSelectOptions.filter(val => val.value !== 'RESEND_TO_VENDOR');
    }

    if (isArOperations) {
      newTabelColumnExportCreditNotes = [
        {
          name: 'select',
          label: 'common:label.select',
          render: (colName, item) => {
            return (
              <CheckField
                name="select"
                value={!!item.id}
                // disabled={item.status !== 'PENDING_APPROVAL'}
                onChange={evt => {
                  let newListCreditId = cloneDeep(listCreditId);
                  let newListItemSelect = cloneDeep(listItemSelect);
                  if (
                    listCreditId &&
                    listCreditId.length &&
                    listCreditId.indexOf(item.id) !== -1 &&
                    !evt.target.checked
                  ) {
                    newListCreditId = newListCreditId.filter(val => val !== item.id);
                    newListItemSelect = newListItemSelect.filter(val => val.id !== item.id);
                  } else {
                    newListCreditId = [...newListCreditId, item.id];
                    newListItemSelect = [...newListItemSelect, item];
                  }
                  this.setState({
                    listCreditId: newListCreditId,
                    listItemSelect: newListItemSelect,
                    type: null,
                    actionSelected: null,
                  });

                  // if (creditIdSelected === item.id) {
                  //   this.setState({ creditIdSelected: null, itemSelect: null });
                  // } else {
                  //   this.setState({
                  //     creditIdSelected: item.id,
                  //     actionSelected: null,
                  //     itemSelect: cloneDeep(item),
                  //     notes: item.notes,
                  //   });
                  // }
                }}
                checked={!!(item.id && listCreditId && listCreditId.length && listCreditId.indexOf(item.id) !== -1)}
                readOnly
              />
            );
          },
        },
        {
          name: 'details',
          label: 'common:label.details',
          render: (colName, item) => (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={evt => {
                this.onToggleModalDetails(item);
              }}
            >
              {t('label.view')}
            </button>
          ),
        },
        {
          name: 'select',
          label: 'label.action',
          render: (colName, item) => (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={() => {
                this.setState({ idOpenModalNote: item.id });
              }}
            >
              {t('label.notes')}
              <i className="fa fa-external-link ml-2" aria-hidden="true" />
            </button>
          ),
        },
        ...tableColumnsOutput,
      ];
    }
    const isMultipleRows = listItemSelect && listItemSelect.length > 1;

    return (
      <div className={isArOperations ? 'content-wrapper' : ''}>
        <div className="col-md-12">
          <PageTitle
            linkTo={isArOperations ? RouteNames.arOperationsAdjustments.path : RouteNames.report.path}
            titleBtn="back"
            items={[
              isArOperations
                ? { name: t('common:label.bulkOperations') }
                : { name: t('label.report'), url: RouteNames.report.path },
              { name: t('reportPage:sidebar.creditNotes') },
            ]}
          />
          <br />
          <div className="card card-statistics mb-30">
            <TitleFrom title={t('reportPage:sidebar.creditNotes')} />
            <div className="col-md-12 mb-30">
              <div className="card-body">
                <div className="pl-2">
                  <CreditNotesSearchForm
                    keyOptionInject="customReason"
                    optionsInject={listReasonOptions}
                    onSubmit={this.onHandleSubmitSearch}
                    defaultFilter={
                      location && location.state && location.state.filter
                        ? location.state.filter
                        : {
                            startDate: filter.startDate || null,
                            endDate: filter.endDate || null,
                          }
                    }
                  />
                </div>
                <div className={isArOperations ? 'mt-5' : ''}>
                  {!isArOperations && (
                    <div className="mt-3 col-md-12 row mb-4 pt-3 pb-3">
                      <button
                        type="submit"
                        onClick={this.onToggleModalExportConfig}
                        className="button x-small mr-2 btn-config"
                      >
                        {/* {t('label.outputConfig')} */}
                        <i className="ti-list" />
                      </button>
                      {!!modeDownloadReport && (
                        <div className="ml-auto mt-2 mb-auto mr-3">
                          <SwitchExport
                            onChange={this.onChangeSwitch}
                            wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                            title={t('label.excel')}
                            checked={isActivePdf}
                            rightTitle={t('label.pdf')}
                          />
                        </div>
                      )}
                      {!!modeDownloadReport && (
                        <>
                          <ButtonExport onExport={this.onExport} />
                          <ExcelExport
                            element={<button type="button" className="display-none" ref={this.buttonRef} />}
                            nameSheet={t('reportPage:sidebar.creditNotes').toLocaleUpperCase()}
                            multiDataSet={convertJson2Sheet({
                              data: listAllCreditNotes,
                              t,
                              title: t('reportPage:sidebar.creditNotes').toLocaleUpperCase(),
                              columnsTable: tableColumnsOutput,
                            })}
                            fileName={`${t('reportPage:sidebar.creditNotes')
                              .toLocaleLowerCase()
                              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                          />
                        </>
                      )}
                      {modeGetMailReport !== 0 && (
                        <EmailGroup
                          emails={emails}
                          onChangeEmail={this.onChangeEmail}
                          sendEmail={this.sendEmail}
                          data={listCreditNotes}
                        />
                      )}
                    </div>
                  )}
                  {isArOperations && (
                    <div className="col-md-12 p-3 pl-0 pb-4 d-flex separation-border-top">
                      <button
                        type="button"
                        className="button button-border gray x-small mt-4 default-heigh-btn mr-2 btn-action-note"
                        onClick={this.onToggleModalNotesAction}
                      >
                        {t('label.quickNotes')}
                        <i className="fa fa-external-link ml-2" aria-hidden="true" />
                      </button>
                      <GenericInput
                        value={actionSelected}
                        name="type"
                        onChange={({ name, value }) => {
                          this.setState({
                            actionSelected: value,
                            notes: value === 'APPROVED' ? listCreditNotes[0]?.notes || null : null,
                          });
                        }}
                        wrapperClass="col-md-3"
                        label="label.action"
                        options={creditNotesSelectOptions || []}
                        type="select"
                        placeholder="<Select Action>"
                        disabled={!listCreditId || !listCreditId.length}
                        isPlaceholder
                        isClearable
                      />
                      {listCreditId &&
                        listCreditId.length > 0 &&
                        actionSelected &&
                        actionSelected !== 'RESEND_TO_VENDOR' && (
                          <>
                            {actionSelected === 'APPROVED' && (
                              <GenericInput
                                value={notes}
                                name="notes"
                                onChange={({ name, value }) => this.setState({ notes: value })}
                                wrapperClass="col-md-2 mt-auto mb-auto mr-0"
                                label="label.notes"
                                type="textarea"
                              />
                            )}
                            <GenericInput
                              value={comments}
                              name="comment"
                              onChange={({ name, value }) => this.setState({ comments: value })}
                              wrapperClass="col-md-3 mt-auto mb-auto mr-0"
                              label="label.comments"
                              type="textarea"
                            />

                            <div className="col-md-3">
                              <button
                                className="button x-small mt-4"
                                // disabled={modeRegenerateInvoice === 1 || !itemSelect || !itemSelect.invoiceUnitId}
                                type="button"
                                onClick={this.onSubmitUpdateStatus}
                              >
                                {isMultipleRows
                                  ? t('label.processAll')
                                  : `${actionSelected === 'APPROVED' ? t('label.approve') : t('label.reject')}`}
                              </button>
                            </div>
                          </>
                        )}
                      {listCreditId &&
                        listCreditId.length > 0 &&
                        actionSelected &&
                        actionSelected === 'RESEND_TO_VENDOR' && (
                          <div className="col-md-3">
                            <button
                              className="button x-small mt-4"
                              // disabled={modeRegenerateInvoice === 1 || !itemSelect || !itemSelect.invoiceUnitId}
                              type="button"
                              onClick={this.onHandleResendToVendor}
                            >
                              {isMultipleRows ? t('label.processAll') : t('label.submit')}
                            </button>
                          </div>
                        )}
                    </div>
                  )}
                  <DataTable
                    columns={newTabelColumnExportCreditNotes}
                    data={listCreditNotes && listCreditNotes.length ? listCreditNotes : []}
                    onSort={this.onSortColumn}
                    sorted={sorted}
                    isLoading={isSearching}
                    isFixedHeaderTable
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-30">
            <TablePagination
              pageNumber={page}
              pageSize={size}
              totalCount={totalCount}
              onPageChange={this.onPageChange}
              onSizeChange={this.onSizeChange}
            />
          </div>
        </div>
        {isArOperations && (
          <ModalWithItem
            modalTitle={t('label.notesSummary')}
            wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
            isOpen={isOpenModalDetails}
            onToggle={this.onToggleModalDetails}
          >
            <div className="col-md-12 row pb-1">
              {listCreditDebitNoteFields.map(val => (
                <GenericInput
                  value={itemSelected && itemSelected[val.name] ? itemSelected[val.name] : ''}
                  wrapperClass="col-md-4"
                  onChange={() => {}}
                  readOnly
                  {...val}
                />
              ))}
            </div>
            <br />
            {collapsibleMenuCreditDebitNotes.map(item => (
              <FormCollapse
                key={item.title}
                label="First Name"
                isActive={item.state === activeTab.name && activeTab.isActive}
                title={t(item.title) || ''}
                state={item.state}
                onToggleTab={this.onToggleTab}
              >
                {this.renderItem(item)}
              </FormCollapse>
            ))}
            <br />
            <button
              type="button"
              onClick={this.onToggleModalDetails}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </ModalWithItem>
        )}
        {isArOperations && (
          <ModalSearchObjectApprovals
            isOpen={!!idOpenModalNote}
            defaultSearchParams={{ objectId: idOpenModalNote }}
            id={idOpenModalNote}
            onSelect={this.onSelectItemId}
            onCancel={() => {
              this.setState({ idOpenModalNote: null });
            }}
            toggleModal={() => {
              this.setState({ idOpenModalNote: null });
            }}
          />
        )}

        {!isArOperations && (
          <ModalWithItem
            onToggle={this.onToggleModalExportConfig}
            isOpen={isOpenModalExportConfig}
            wrapperClass="modal-config"
          >
            <div className="col-md-12 d-flex">
              <div className="column-config" onClick={this.onToggleModalExportConfig} />
              <div className="body-config">
                <DragDropConfigExportTemplate
                  onHandleSave={this.onHandleSaveConfig}
                  exportColumns={exportColumns}
                  onToggle={this.onToggleModalExportConfig}
                />
              </div>
            </div>
          </ModalWithItem>
        )}
        {isArOperations && (
          <ModalViewNote
            isOpen={isOpenModalNotesAction}
            message={messageNotesAction(t)}
            onToggle={this.onToggleModalNotesAction}
          />
        )}
      </div>
    );
  }
}

CreditNotes.propTypes = {
  getListAllCreditNote: PropTypes.func,
  getCreditNote: PropTypes.func,
  listAllCreditNotes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

CreditNotes.defaultProps = {
  getListAllCreditNote: () => {},
  getCreditNote: () => {},
  listAllCreditNotes: [],
};

const mapStateToProps = createStructuredSelector({
  listCreditNotes: makeGetListCreditNotes() || [],
  listAllCreditNotes: makeGetListAllCreditNotes() || [],
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getCreditNote,
    getListAllCreditNote,
    getARReasonCodeSelectionConfig,
    uploadMultiPartFiles,
    getNotesUnitbyIdConfig,
    modifyNotesUnitConfig,
    resendNotesVendorConfig,
    getObjectFileById,
    saveOutputTemplateByType,
    getOutputTemplateByType,
    bulkProcess,
    getCcpPropertiesConfig,
  })(CreditNotes)
);
