import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'status',
        'arType',
        'accountType',
        'minimumThreshold',
        'reason',
        'groupByNote',
        'invoiceCancelllation',
        'invoiceStatus',
        'folioStatus',
      ])}`
    : '';
  const getCreditNoteReport = `getCreditNoteReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getCreditNoteReport} {
        id
        invoiceId
        accountId
        company
        firstName
        lastName
        notesDate
        currency
        reason
        description
        organization
        ticketNumber
        groupApproval
        summaryTotal
        addOnTaxRate
        addOnTaxAmount
        mainTaxRate
        mainTaxAmount
        total
        accountType
        arType
        status
        invoiceCancellation
        commercialName
        invoiceStatus
        folioStatus
        notes
      }
    }
  `;
};
