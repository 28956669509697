import * as types from './types';

export function searchOrders(payload, cb) {
  return {
    type: types.SEARCH_ORDERS,
    payload,
    cb,
  };
}

export function searchOrdersSuccess(payload) {
  return {
    type: types.SEARCH_ORDERS_SUCCESS,
    payload,
  };
}

export function searchOrdersFailure(payload) {
  return {
    type: types.SEARCH_ORDERS_FAILURE,
    payload,
  };
}

export function setParams(payload) {
  return {
    type: types.SET_PARAMS,
    payload,
  };
}

export function getOrderDetail(payload, cb) {
  return {
    type: types.GET_ORDERS_DETAIL,
    payload,
    cb,
  };
}

export function getOrderDetailSuccess(payload) {
  return {
    type: types.GET_ORDERS_DETAIL_SUCCESS,
    payload,
  };
}

export function getOrderDetailFailure(payload) {
  return {
    type: types.GET_ORDERS_DETAIL_FAILURE,
    payload,
  };
}

export function createOrder(payload, cb) {
  return {
    type: types.CREATE_ORDERS,
    payload,
    cb,
  };
}

export function createOrderSuccess(payload) {
  return {
    type: types.CREATE_ORDERS_SUCCESS,
    payload,
  };
}

export function createOrderFailure(payload) {
  return {
    type: types.CREATE_ORDERS_FAILURE,
    payload,
  };
}

export function modifyOrder(payload, cb) {
  return {
    type: types.MODIFY_ORDER,
    payload,
    cb,
  };
}

export function modifyOrderSuccess(payload) {
  return {
    type: types.MODIFY_ORDER_SUCCESS,
    payload,
  };
}

export function updateOrderStatus(payload, cb) {
  return {
    type: types.UPDATE_ORDER_STATUS,
    payload,
    cb,
  };
}

export function updateOrderStatusSuccess(payload) {
  return {
    type: types.UPDATE_ORDER_STATUS_SUCCESS,
    payload,
  };
}

export function getSubscriptionByAccountId(payload, cb, isNoGetAsset) {
  return {
    type: types.GET_SUBSCRIPTION_BY_ACCOUNT_ID,
    payload,
    cb,
    isNoGetAsset,
  };
}

export function getSubscriptionByAccountIdSuccess(payload) {
  return {
    type: types.GET_SUBSCRIPTION_BY_ACCOUNT_ID_SUCCESS,
    payload,
  };
}

export function getSubscriptionByAccountIdFailure(payload, cb) {
  return {
    type: types.GET_SUBSCRIPTION_BY_ACCOUNT_ID_FAILURE,
    payload,
    cb,
  };
}

export function getServiceUnitsBySubscriptionId(payload) {
  return {
    type: types.GET_SERVICE_UNITS_BY_SUBSCRIPTION_ID,
    payload,
  };
}

export function getServiceUnitsBySubscriptionIdSuccess(payload) {
  return {
    type: types.GET_SERVICE_UNITS_BY_SUBSCRIPTION_ID_SUCCESS,
    payload,
  };
}

export function getSubscriptionAssets(payload) {
  return {
    type: types.GET_SUBSCRIPTION_ASSETS,
    payload,
  };
}

export function getSubscriptionAssetsSuccess(payload) {
  return {
    type: types.GET_SUBSCRIPTION_ASSETS_SUCCESS,
    payload,
  };
}

export function getSubscriptionAssetsFailure(payload) {
  return {
    type: types.GET_SUBSCRIPTION_ASSETS_FAILURE,
    payload,
  };
}

export function getServiceUnitsBySubscriptionIdFailure(payload) {
  return {
    type: types.GET_SERVICE_UNITS_BY_SUBSCRIPTION_ID_FAILURE,
    payload,
  };
}

export function getPriceUnitsByServiceUnitId(payload) {
  return {
    type: types.GET_PRICE_UNITS_BY_SERVICE_UNIT_ID,
    payload,
  };
}

export function getPriceUnitsByServiceUnitIdSuccess(payload) {
  return {
    type: types.GET_PRICE_UNITS_BY_SERVICE_UNIT_ID_SUCCESS,
    payload,
  };
}

export function getPriceUnitsByServiceUnitIdFailure(payload) {
  return {
    type: types.GET_PRICE_UNITS_BY_SERVICE_UNIT_ID_FAILURE,
    payload,
  };
}

// for get service units by accountId
export function getServiceUnitAc(payload, cb) {
  return {
    type: types.GET_SERVICE_UNITS_AC,
    payload,
    cb,
  };
}

// for get service units by accountId
export function getBundleDetail(payload, cb) {
  return {
    type: types.GET_BUNDLE_BY_ID,
    payload,
    cb,
  };
}

// for get service units by accountId
export function getBundleDetailSuccess(payload) {
  return {
    type: types.GET_BUNDLE_BY_ID_SUCCESS,
    payload,
  };
}

export function getDependentOffers(payload, cb) {
  return {
    type: types.GET_DEPENDENT_OFFERS,
    payload,
    cb,
  };
}

// OMS config
export function searchOms(payload, cb) {
  return {
    type: types.SEARCH_OMS_CONFIG,
    payload,
    cb,
  };
}

export function searchOmsSuccess(payload, cb) {
  return {
    type: types.SEARCH_OMS_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function modifyOms(payload, cb) {
  return {
    type: types.MODIFY_OMS_CONFIG,
    payload,
    cb,
  };
}

export function createOms(payload, cb) {
  return {
    type: types.CREATE_OMS_CONFIG,
    payload,
    cb,
  };
}

// OMS config
export function searchProvisioningTaskSequence(payload, cb) {
  return {
    type: types.SEARCH_PROVISIONING_CONFIG,
    payload,
    cb,
  };
}

export function searchProvisioningTaskSequenceSuccess(payload, cb) {
  return {
    type: types.SEARCH_PROVISIONING_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function modifyProvisioningTaskSequence(payload, cb) {
  return {
    type: types.MODIFY_PROVISIONING_CONFIG,
    payload,
    cb,
  };
}

export function createProvisioningTaskSequence(payload, cb) {
  return {
    type: types.CREATE_PROVISIONING_CONFIG,
    payload,
    cb,
  };
}

// Provisioning Mapping
export function getFlexAttributesMapping(payload, cb) {
  return {
    type: types.GET_FLEX_ATTRIBUTE_MAPPING,
    payload,
    cb,
  };
}

export function getFlexAttributesMappingSuccess(payload, cb) {
  return {
    type: types.GET_FLEX_ATTRIBUTE_MAPPING_SUCCESS,
    payload,
    cb,
  };
}

export function modifyFlexAttributeMapping(payload, cb) {
  return {
    type: types.MODIFY_FLEX_ATTRIBUTE_MAPPING,
    payload,
    cb,
  };
}

export function createFlexAttributeMapping(payload, cb) {
  return {
    type: types.CREATE_FLEX_ATTRIBUTE_MAPPING,
    payload,
    cb,
  };
}

export function createBillingAccounts(payload, cb) {
  return {
    type: types.CREATE_BILLING_ACCOUNTS,
    payload,
    cb,
  };
}

export function processSplitOrder(payload, cb) {
  return {
    type: types.PRCESS_SPLIT_ORDER,
    payload,
    cb,
  };
}

// Order Reason Code
export function getOrderReasonCodes(payload, cb) {
  return {
    type: types.SEARCH_ORDER_REASON_CODE,
    payload,
    cb,
  };
}

export function getOrderReasonCodesSuccess(payload, cb) {
  return {
    type: types.SEARCH_ORDER_REASON_CODE_SUCCESS,
    payload,
    cb,
  };
}

export function modifyOrderReasonCodes(payload, cb) {
  return {
    type: types.MODIFY_ORDER_REASON_CODE,
    payload,
    cb,
  };
}

export function createOrderReasonCodes(payload, cb) {
  return {
    type: types.CREATE_ORDER_REASON_CODE,
    payload,
    cb,
  };
}

// Get Order Split Opportunity
export function getOrderSplitOpportunity(payload, cb) {
  return {
    type: types.GET_ORDER_SPLIT_OPPORTUNITY,
    payload,
    cb,
  };
}

export function saveSplitOpportunity(payload, cb) {
  return {
    type: types.SAVE_ORDER_SPLIT_OPPORTUNITY,
    payload,
    cb,
  };
}

export function undoProcessSplitOrder(payload, cb) {
  return {
    type: types.UNDO_PROCESS_SPLIT_ORDER,
    payload,
    cb,
  };
}

export function searchProvisioningAttributesConfig(payload, cb) {
  return {
    type: types.SEARCH_PROVISIONING_ATTRIBUTES_CONFIG,
    payload,
    cb,
  };
}

export function createProvisioningAttributesConfig(payload, cb) {
  return {
    type: types.CREATE_PROVISIONING_ATTRIBUTES_CONFIG,
    payload,
    cb,
  };
}

export function modifyProvisioningAttributesConfig(payload, cb) {
  return {
    type: types.MODIFY_PROVISIONING_ATTRIBUTES_CONFIG,
    payload,
    cb,
  };
}

export function buildOrderProvisioningAttributes(payload, cb) {
  return {
    type: types.BUILD_ORDER_PROVISIONING_ATTRIBUTES,
    payload,
    cb,
  };
}

export function getGatewayApiMapByNameConfig(payload, cb) {
  return {
    type: types.GET_GATEWAY_API_MAP_BY_NAME_CONFIG,
    payload,
    cb,
  };
}

export function getOrderOmsTasks(payload, cb) {
  return {
    type: types.GET_ORDER_OMS_TASKS,
    payload,
    cb,
  };
}

export function getOrderProvisioningOutboundTasks(payload, cb) {
  return {
    type: types.GET_ORDER_PROVISIONING_OUTBOUND_TASKS,
    payload,
    cb,
  };
}

export function getOrderProvisioningInboundTasks(payload, cb) {
  return {
    type: types.GET_ORDER_PROVISIONING_INBOUND_TASKS,
    payload,
    cb,
  };
}

export function getOrderAudit(payload, cb) {
  return {
    type: types.GET_ORDER_AUDIT,
    payload,
    cb,
  };
}

export function convertJsonToOrder(payload, cb) {
  return {
    type: types.CONVERT_JSON_TO_ORDER,
    payload,
    cb,
  };
}
