import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { cardTokenFields, dataSelect } from '../../../constantsApp';
import { getDataCardFromURL } from '../../../utils/utils';
import { URL_PAYMENT } from '../../../utils/constants';
import { GenericInput, PasswordField, MonthGroup } from '../../common';

const creditCards = [
  {
    cardToken: '',
    cardExpiry: '',
    last4CC: '',
    merchant: 'qabrain3',
  },
];

class CreatePaymentProfile extends PureComponent {
  componentDidMount() {
    const urlTokenCreditCard = window.location.href;
    const { onChangeCreditCards } = this.props;
    if (urlTokenCreditCard) {
      creditCards[0].cardToken = getDataCardFromURL(urlTokenCreditCard, cardTokenFields.token);
      creditCards[0].cardExpiry = getDataCardFromURL(urlTokenCreditCard, cardTokenFields.expiryDate);
      creditCards[0].last4CC = getDataCardFromURL(urlTokenCreditCard, cardTokenFields.last4cc);
    }
    if (creditCards[0].cardToken && creditCards[0].cardExpiry && creditCards[0].last4CC) {
      onChangeCreditCards({ isCallbackURL: true, newCreditCardCallback: creditCards });
    }
  }

  render() {
    const {
      accountDetail,
      onHandleChange,
      onChangeCreditCards,
      isDisabled,
      fieldObj,
      dataCallback,
      t,
      paymentTermOptions,
      isNoNeedDisableNpnPaying,
      isDisabledAll,
      isDisabledPaymentTerm,
      children,
    } = this.props;

    const isCreaditCard = accountDetail.paymentMethod === 'CREDIT_CARD';
    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics">
          <div className="card-body">
            <div className="group-flex col-md-12">
              <GenericInput
                type="select"
                label={t('label.paymentMethod')}
                wrapperClass="col-md-4"
                value={accountDetail.paymentMethod}
                options={dataSelect.paymentMethod.map((item, index) => ({
                  value: item.value,
                  label: t(`label.${item.label}`),
                  isDisabled: index === 2 && !isNoNeedDisableNpnPaying,
                }))}
                name="paymentMethod"
                required
                onChange={({ value, name }) => onHandleChange({ value, name, fieldObj })}
                disabled={isDisabled || isDisabledAll}
                isClearable
              />
              <GenericInput
                type="select"
                value={accountDetail.paymentTerm || null}
                label={t('label.paymentTerm')}
                wrapperClass="col-md-4"
                options={paymentTermOptions || []}
                name="paymentTerm"
                onChange={({ value, name }) => onHandleChange({ value, name, fieldObj })}
                disabled={isDisabled || isDisabledAll || isDisabledPaymentTerm}
                isClearable
              />
              {!isDisabled &&
                isCreaditCard && [
                  <div className="col-md-4 group-flex" key="cardToken">
                    <GenericInput
                      value={
                        accountDetail.creditCards && !isEmpty(accountDetail.creditCards[0])
                          ? accountDetail.creditCards[0].cardToken
                          : ''
                      }
                      label={t('label.creditCardToken')}
                      wrapperClass="col-md-7"
                      name="cardToken"
                      disabled={isDisabled || isDisabledAll}
                      required
                      onChange={({ name, value }) => onChangeCreditCards({ value, fieldName: name })}
                    />
                    <a
                      href={`${URL_PAYMENT}${window.location.href}`}
                      className="button x-small btn-credit-card"
                      onClick={() => localStorage.setItem('dataCallback', JSON.stringify(dataCallback))}
                    >
                      {t('label.getToken')}
                    </a>
                  </div>,

                  <MonthGroup
                    type="date"
                    key="cardExpiry"
                    required
                    date={
                      accountDetail.creditCards && !isEmpty(accountDetail.creditCards[0])
                        ? accountDetail.creditCards[0].cardExpiry
                        : ''
                    }
                    label={t('label.creditCardExpiry')}
                    dateFormat="MM/yyyy"
                    wrapperClass="col-md-4"
                    name="cardExpiry"
                    showMonthYearPicker
                    isClearable
                    disabled={isDisabledAll}
                    onChange={({ value }) => onChangeCreditCards({ value, fieldName: 'cardExpiry' })}
                  />,
                  <PasswordField
                    key="last4CC"
                    required
                    value={
                      accountDetail.creditCards && !isEmpty(accountDetail.creditCards[0])
                        ? accountDetail.creditCards[0].last4CC
                        : ''
                    }
                    label={t('label.creditCardCVV')}
                    wrapperClass="col-md-4"
                    name="last4CC"
                    disabled={isDisabled || isDisabledAll}
                    onChange={({ name, value }) => onChangeCreditCards({ value, fieldName: name })}
                  />,
                ]}
              {children || null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreatePaymentProfile.propTypes = {
  accountDetail: PropTypes.objectOf(PropTypes.any),
  dataCallback: PropTypes.objectOf(PropTypes.any),
  onHandleChange: PropTypes.func.isRequired,
  onChangeCreditCards: PropTypes.func.isRequired,
  fieldObj: PropTypes.string,
  isDisabled: PropTypes.bool,
};

CreatePaymentProfile.defaultProps = {
  fieldObj: '',
  accountDetail: {},
  dataCallback: {},
  isDisabled: false,
};

export default withTranslation(['common', 'selections'])(CreatePaymentProfile);
