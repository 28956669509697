import { call, put, takeLatest, takeEvery, takeLeading } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import {
  getListCustomers,
  viewListCustomerOnly,
  createAccount,
  getAccountDetail,
  getAccountInformation,
  modifyAccount,
  updateAccountStatus,
  getPriceUnitsByAccountId,
  getServiceUnitsByAccountId,
  getSubscriptionByAccountId,
  getTransactionUnit,
  getArActivity,
  getCustomerActivity,
  searchBillUnits,
  searchBillInvoice,
  getCustomAttributesByObjectType,
  ModifyCustomAttributes,
  createCustomAttributes,
  getConfigCreditProfile,
  modifyConfigCreditProfile,
  createConfigCreditProfile,
  getTransactionUnitById,
  getActivityById,
  getArOpsUnitById,
  manualInvoiceService,
  runPendingBill,
  getFutureCycleCfg,
  modifyFutureCycleCfg,
  createFutureCycleCfg,
  sendInvoice,
  getExternalPOByAccountId,
  createExternalPO,
  modifyExternalPO,
  regenerateInvoice,
  modifyInvoiceStatus,
  getAccountStatement,
  undoManualInvoice,
  searchOrders,
  searchNotesUnits,
  getAccountAssets,
  getSpecialPriceByAccountId,
  modifySpecialPrice,
  createSpecialPrice,
  updateOrderStatus,
  modifyNotesUnit,
  getOrderSummaryByAccountId,
  updateBillingAccount,
  modifyInvoiceDueDate,
  getAccountExchangeRates,
  modifyAccountExchangeRates,
  createAccountExchangeRates,
  getPreviousAccountMerge,
  getPreviousAccountMovement,
  invoiceAccount,
  getPaymentInstallmentByAccountId,
  modifyPaymentInstallment,
  createPaymentInstallment,
  getInvoiceInstallmentsByInvoiceId,
  searchUsageTransaction,
  getTransactionUnitByIdUsage,
  resendInvoiceVendor,
  undoInvoiceCancel,
  reprocessInvoice,
  getOrderHistory,
  searchLadingBill,
  getPacInterfaceRecordById,
  saveAccountNonBillables,
  moveAccount,
  getInvoiceTransactionSummary,
  getPendingManualInvoice,
  deletePendingManualInvoice,
  saveManualInvoice,
  getInvoiceTaxSummary,
  syncProjectApp,
  saveAccountMovementData,
  getNotesUnitById,
  resendPaymentVendor,
  resendNotesVendor,
  getClientAccountStatement,
  getUsedCfdiByTaxRegion,
  getSplitTaxPerLine,
  searchAccountAssets,
  searchSubscriptionByAccountId,
  subscriptionTopUp,
  searchBalanceUnitByAccountId,
  getProvisioningByAccountId,
  getMultiInvPymtInstallmentsByAccountId,
  setupMultiInvoicePaymentInstallment,
  getSharingGroup,
  createSharingGroup,
  modifySharingGroup,
  searchArOpsRequests,
  getArOpsRequestById,
  generatePaymentInstallmentReport,
  getAccountDetailForSelectParent,
  getUnallocatedPayment,
  searchSubscriptionTopUp,
} from '../../api';
import { queryRequest, mutationRequest } from '../../utils/request';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

export function* getCustomersSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, viewListCustomerOnly({ page, size, filter, sort }));
    if (response.searchAccounts) {
      // toast.success(i18n.t('common:label.mandatory'));
      yield put(actions.getCustomersSuccess(response.searchAccounts));
    } else {
      yield put(actions.getCustomersSuccess([]));
    }
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchAccounts });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getCustomersParentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, viewListCustomerOnly({ page, size, filter, sort }));
    yield put(actions.getCustomersSuccess(response.searchAccounts));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchAccounts });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getCustomersIdSaga({ payload, cb }) {
  try {
    const { page, size, filter } = payload;
    const response = yield call(queryRequest, getListCustomers({ page, size, filter }));
    if (cb) cb({ success: true, data: response.searchAccounts });
  } catch (err) {
    if (cb) cb({ success: false });
  }
}

export function* createAccountSaga({ payload, cb, isPopup }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    const response = yield call(mutationRequest, createAccount(dataCreate));
    yield put(actions.createAccountSuccess());
    if (response?.createAccount?.id) {
      toast.success(i18n.t('common:apiMessage.createAccountSuccessfully'));
      if (cb) yield cb({ success: true, id: response.createAccount.id });
    } else if (cb) yield cb({ success: false, id: null });
    yield put(isEndConnected());
    if (!isPopup) yield put(push(`/customers/${response.createAccount.id}/info`));
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, id: null });
  }
}

export function* modifyAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyAccount(payload));
    // yield put(actions.modifyAccountSuccess(response.modifyAccount));
    if (cb) cb({ success: true, data: response.modifyAccount });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.modifyAccountSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* updateAccountStatusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updateAccountStatus(payload));
    yield put(actions.updateAccountStatusSuccess(response.updateStatus));
    if (cb) cb({ success: true });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.updateAccountStatusSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* getAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountDetail(payload));
    if (response.getAccountById) {
      yield put(actions.getAccountDetailSuccess(response.getAccountById));
      // yield put(actions.getSubscription(payload));
      // yield put(actions.getPriceUnits(payload));
      // yield put(actions.getServiceUnitAc(payload));
      // yield put(actions.getBalanceUnit(payload));
      if (cb) cb({ success: true, data: response.getAccountById });
    } else {
      yield put(actions.getAccountDetailFailure(`Can not get detail for account ${payload}`));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAccountDetailFailure(`Can not get detail for account ${payload}`));
  }
}

export function* getAccountInformationSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getAccountInformation(payload));
    if (response.getAccountInformation) {
      if (cb) cb({ success: true, data: response.getAccountInformation });
    } else if (cb) cb({ success: false, data: {} });
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
  }
}

export function* getParentAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountDetail(payload));
    if (response.getAccountById) {
      yield put({
        type: types.GET_PARENT_ACCOUNT_DETAIL_SUCCESS,
        payload: response.getAccountById,
      });
      if (cb) cb();
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* getPriceUnitsSaga({ payload }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPriceUnitsByAccountId(payload));
    if (response.getPriceUnitsByAccountId) {
      yield put(actions.getPriceUnitsSuccess(response.getPriceUnitsByAccountId));
    } else {
      yield put(actions.getPriceUnitsFailure(`Can not get price units for account ${payload}`));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(actions.getPriceUnitsFailure(`Can not get price units for account ${payload}`));
    yield put(isEndConnected());
  }
}

export function* getSubscriptionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getSubscriptionByAccountId(payload));
    if (response.getSubscriptionByAccountId) {
      if (cb) cb({ success: true, data: response.getSubscriptionByAccountId });
      yield put(actions.getSubscriptionSuccess(response.getSubscriptionByAccountId));
    } else {
      if (cb) cb({ success: true, data: null });
      yield put(actions.getSubscriptionSuccess([]));
      yield put(actions.getSubscriptionFailure(`Can not get subscription for account ${payload}`));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getSubscriptionSuccess([]));
    yield put(actions.getSubscriptionFailure(`Can not get subscription for account ${payload}`));
    if (cb) cb({ success: true, data: null });
  }
}

export function* getServiceUnitsAcSaga({ payload }) {
  try {
    const response = yield call(queryRequest, getServiceUnitsByAccountId(payload));
    if (response.getServiceUnitsByAccountId) {
      yield put(actions.getServiceUnitAcSuccess(response.getServiceUnitsByAccountId));
    } else {
      yield put(actions.getServiceUnitAcFailure(`Can not get service units for account ${payload}`));
    }
  } catch (err) {
    yield put(actions.getServiceUnitAcFailure(`Can not get service units for account ${payload}`));
  }
}

export function* getTransactionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, getTransactionUnit({ page, size, filter, sort }));
    yield put(actions.getTransactionUnitSuccess(response.searchTransactionUnits));
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getTransactionUnitFailure('Failed to fetch data. Please try again or check your network!'));
    if (cb) cb();
  }
}

export function* getArActivitySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, getArActivity({ page, size, filter, sort }));
    if (response.searchArOpsUnit) {
      yield put(actions.getArActivitySuccess(response.searchArOpsUnit));
      if (cb) cb({ success: true, data: response.searchArOpsUnit });
    } else {
      yield put(actions.getArActivitySuccess(null));
      if (cb) cb({ success: false, data: null });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getArActivityFailure('Failed to fetch data. Please try again or check your network!'));
    if (cb) cb();
  }
}

export function* getCustomerActivitySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, getCustomerActivity({ page, size, filter, sort }));
    yield put(actions.getCustomerActivitySuccess(response.searchActivities));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchActivities });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getCustomerActivityFailure('Failed to fetch data. Please try again or check your network!'));
    if (cb) cb({ success: false, data: null });
  }
}

export function* getPendingBillSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchBillUnits({ page, size, filter, sort }));
    yield put(actions.getPendingBillSuccess(response.searchBillUnits));
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getPendingBillFailure('Failed to fetch data. Please try again or check your network!'));
    if (cb) cb();
  }
}

export function* getOCBillsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchBillInvoice({ page, size, filter, sort }));
    yield put(actions.getOCBillSuccess(response.searchBillInvoice));
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getOCBillFailure('Failed to fetch data. Please try again or check your network!'));
    if (cb) cb();
  }
}

export function* modifyCustomAttributesByObjectTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    if (dataCreate.id) delete dataCreate.id;
    const res = yield call(mutationRequest, ModifyCustomAttributes(dataCreate));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: res.modifyCustomAttributes });
    toast.success(i18n.t('common:apiMessage.modifyCustomAttributesSuccessfully'));
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}
export function* createCustomAttributesByObjectTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    if (dataCreate.id) delete dataCreate.id;
    dataCreate.objectType = dataCreate.objectType || 'ACCOUNT'; // Hard code object type when create attribute
    const res = yield call(mutationRequest, createCustomAttributes(dataCreate));
    if (cb) cb({ success: true, data: res.createCustomAttributes });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.createCustomAttributesSuccessfully'));
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
    yield put(isEndConnected());
  }
}

export function* getCustomAttributesByObjectTypeSaga({ cb, payload }) {
  try {
    const response = yield call(queryRequest, getCustomAttributesByObjectType(payload));
    if (response.getCustomAttributesByObjectType) {
      if (payload === 'ORDER') {
        yield put(actions.getCustomAttributesByObjectTypeSuccess(response.getCustomAttributesByObjectType));
      }
      if (cb) cb({ success: true, data: response.getCustomAttributesByObjectType });
    } else {
      if (cb) cb({ success: false });
      yield put(actions.getCustomAttributesByObjectTypeSuccess(null));
    }
  } catch (err) {
    if (cb) cb({ success: false });
  }
}

export function* getConfigCreditProfileSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigCreditProfile());
    if (response.getConfigCreditProfile) {
      yield put(actions.getConfigCreditProfileSuccess(response.getConfigCreditProfile));
      if (cb) cb({ success: true, data: response.getConfigCreditProfile });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyConfigCreditProfileSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyConfigCreditProfile(payload));
    if (response.modifyConfigCreditProfile) {
      if (cb) cb({ success: true, data: response.modifyConfigCreditProfile });
      toast.success(i18n.t('common:apiMessage.modifyCreditProfileSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createConfigCreditProfileSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createConfigCreditProfile(payload));
    if (response.createConfigCreditProfile) {
      if (cb) cb({ success: true, data: response.createConfigCreditProfile });
      toast.success(i18n.t('common:apiMessage.createCreditProfileSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getTransactionUnitByIdSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getTransactionUnitById(payload));
    if (response.getTransactionUnitById) {
      if (cb) cb({ success: true, data: response.getTransactionUnitById });
    } else if (cb) cb({ success: false, data: [] });
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getActivityByIdSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getActivityById(payload));
    if (response.getActivityById) {
      if (cb) cb({ success: true, data: response.getActivityById });
    } else if (cb) cb({ success: false, data: [] });
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getArOpsUnitByIdSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getArOpsUnitById(payload));
    if (response.getArOpsUnitById) {
      if (cb) cb({ success: true, data: response.getArOpsUnitById });
    } else if (cb) cb({ success: false, data: [] });
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
  }
}

export function* manualInvoiceServiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, manualInvoiceService(payload));
    if (response.manualInvoiceService) {
      toast.success(i18n.t('common:apiMessage.manualInvoiceSuccessfully'));
      if (cb) cb({ success: true, data: response.manualInvoiceService });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* runPendingBillSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, runPendingBill(payload));
    if (response.runPendingBill) {
      toast.success(i18n.t('common:apiMessage.runPendingBillSuccessfully'));
      if (cb) cb({ success: true, data: response.runPendingBill });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getFutureCycleCfgSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getFutureCycleCfg());
    if (response.getFutureCycleCfg) {
      yield put(actions.getFutureCycleCfgSuccess(response.getFutureCycleCfg));
      if (cb) cb({ success: true, data: response.getFutureCycleCfg });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyFutureCycleCfgSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyFutureCycleCfg(payload));
    if (response.modifyFutureCycleCfg) {
      if (cb) cb({ success: true, data: response.modifyFutureCycleCfg });
      toast.success(i18n.t('common:apiMessage.modifyDelayedBillingSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createFutureCycleCfgSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createFutureCycleCfg(payload));
    if (response.createFutureCycleCfg) {
      if (cb) cb({ success: true, data: response.createFutureCycleCfg });
      toast.success(i18n.t('common:apiMessage.createDelayedBillingSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* sendInvoiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, sendInvoice(payload));
    if (response.sendInvoice) {
      if (cb) cb({ success: true, data: response.sendInvoice });
      toast.success(i18n.t('common:apiMessage.sendInoivceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* regenerateInvoiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, regenerateInvoice(payload));
    if (response.regenerateInvoice) {
      if (cb) cb({ success: true, data: response.regenerateInvoice });
      toast.success(i18n.t('common:apiMessage.regenerateInoivceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getExternalPOByAccountIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getExternalPOByAccountId(payload));
    if (response.getExternalPOByAccountId) {
      if (cb) cb({ success: true, data: response.getExternalPOByAccountId });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createExternalPOSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createExternalPO(payload));
    if (response.createExternalPO) {
      if (cb) cb({ success: true, data: response.createExternalPO });
      toast.success(i18n.t('common:apiMessage.createExternalPOSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyExternalPOSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyExternalPO(payload));
    if (response.modifyExternalPO) {
      if (cb) cb({ success: true, data: response.modifyExternalPO });
      toast.success(i18n.t('common:apiMessage.modifyExternalPOSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyInvoiceStatusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyInvoiceStatus(payload));
    if (response.modifyInvoiceStatus) {
      if (cb) cb({ success: true, data: response.modifyInvoiceStatus });
      toast.success(i18n.t('common:apiMessage.modifyInvoiceStatusSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getAccountStatementSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getAccountStatement(payload));
    if (response.getAccountStatement) {
      if (cb) cb({ success: true, data: response.getAccountStatement });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* undoManualInvoiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, undoManualInvoice(payload));
    if (response.undoInvoice) {
      if (cb) cb({ success: true, data: response.undoInvoice });
      toast.success(i18n.t('common:apiMessage.undoManualInvoiceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchOrderIncompleteSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchOrders(payload));
    if (response.searchOrders) {
      if (cb) cb({ success: true, data: response.searchOrders });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchNotesUnitsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchNotesUnits(payload));
    if (response.searchNotesUnits) {
      if (cb) cb({ success: true, data: response.searchNotesUnits });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getAccountAssetsSaga({ payload }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountAssets(payload));
    if (response.getAccountAssets) {
      yield put(actions.getPriceUnitsSuccess(response.getAccountAssets));
    } else {
      yield put(actions.getPriceUnitsSuccess([]));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* getSpecialPriceByAccountIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getSpecialPriceByAccountId(payload));
    if (response.getSpecialPriceByAccountId) {
      if (cb) cb({ success: true, data: response.getSpecialPriceByAccountId });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createSpecialPriceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createSpecialPrice(payload));
    if (response.createSpecialPrice) {
      if (cb) cb({ success: true, data: response.createSpecialPrice });
      toast.success(i18n.t('common:apiMessage.createSpecialPriceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifySpecialPriceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifySpecialPrice(payload));
    if (response.modifySpecialPrice) {
      if (cb) cb({ success: true, data: response.modifySpecialPrice });
      toast.success(i18n.t('common:apiMessage.modifySpecialPriceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* updateOrderStatusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updateOrderStatus(payload));
    if (response.updateOrderStatus) {
      if (cb) yield cb({ success: true });
      yield put(isEndConnected());
      toast.success(i18n.t('common:apiMessage.updateOrderStatusSuccessfully'));
    }
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* modifyNotesUnitSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyNotesUnit(payload));
    if (response.modifyNotesUnit) {
      if (cb) cb({ success: true, data: response.modifyNotesUnit });
      toast.success(i18n.t('common:apiMessage.updateNotesStatusSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getOrderSummaryByAccountIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOrderSummaryByAccountId(payload));
    if (response.getOrderSummaryByAccountId) {
      if (cb) cb({ success: true, data: response.getOrderSummaryByAccountId });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* updateBillingAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updateBillingAccount(payload));
    if (response.updateBillingAccount) {
      if (cb) cb({ success: true, data: response.updateBillingAccount });
      toast.success(i18n.t('common:apiMessage.updateBillingAccountSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyInvoiceDueDateSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyInvoiceDueDate(payload));
    if (response.modifyInvoiceDueDate) {
      if (cb) cb({ success: true, data: response.modifyInvoiceDueDate });
      toast.success(i18n.t('common:apiMessage.modifyInvoiceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getAccountExchangeRatesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountExchangeRates(payload));
    if (response.getAccountExchangeRates) {
      if (cb) cb({ success: true, data: response.getAccountExchangeRates });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createAccountExchangeRatesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createAccountExchangeRates(payload));
    if (response.createAccountExchangeRates) {
      if (cb) cb({ success: true, data: response.createAccountExchangeRates });
      toast.success(i18n.t('common:apiMessage.createxChangeRatesSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyAccountExchangeRatesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyAccountExchangeRates(payload));
    if (response.modifyAccountExchangeRates) {
      if (cb) cb({ success: true, data: response.modifyAccountExchangeRates });
      toast.success(i18n.t('common:apiMessage.modifyxChangeRatesSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getPreviousAccountMergeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPreviousAccountMerge(payload));
    if (response.getPreviousAccountMerge) {
      if (cb) cb({ success: true, data: response.getPreviousAccountMerge });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* invoiceAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, invoiceAccount(payload));
    if (response.invoiceAccount) {
      if (cb) cb({ success: true, data: response.invoiceAccount });
      toast.success(i18n.t('common:apiMessage.invoicedAccountSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getPreviousAccountMovementSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPreviousAccountMovement(payload));
    if (response.getPreviousAccountMovement) {
      if (cb) cb({ success: true, data: response.getPreviousAccountMovement });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getPaymentInstallmentByAccountIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPaymentInstallmentByAccountId(payload));
    if (response.getPaymentInstallmentByAccountId) {
      if (cb) cb({ success: true, data: response.getPaymentInstallmentByAccountId });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createPaymentInstallmentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createPaymentInstallment(payload));
    if (response.createPaymentInstallment) {
      if (cb) cb({ success: true, data: response.createPaymentInstallment });
      toast.success(i18n.t('common:apiMessage.createPaymentInstallmentSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyPaymentInstallmentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyPaymentInstallment(payload));
    if (response.modifyPaymentInstallment) {
      if (cb) cb({ success: true, data: response.modifyPaymentInstallment });
      toast.success(i18n.t('common:apiMessage.modifyPaymentInstallmentSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getInvoiceInstallmentsByInvoiceIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInvoiceInstallmentsByInvoiceId(payload));
    if (response.getInvoiceInstallmentsByInvoiceId) {
      if (cb) cb({ success: true, data: response.getInvoiceInstallmentsByInvoiceId });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchUsageTransactionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchUsageTransaction({ page, size, filter, sort }));
    yield put(actions.searchUsageTransactionSuccess(response.searchUsageTransaction));
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb();
  }
}

export function* getTransactionUnitByIdUsageSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getTransactionUnitByIdUsage(payload));
    if (response.getTransactionUnitById) {
      if (cb) cb({ success: true, data: response.getTransactionUnitById });
    } else if (cb) cb({ success: false, data: [] });
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
  }
}

export function* resendInvoiceVendorSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, resendInvoiceVendor(payload));
    if (response.resendInvoiceVendor) {
      toast.success(i18n.t('common:apiMessage.invoicedVendoSuccessfully'));
      if (cb) cb({ success: true, data: response.resendInvoiceVendor });
    } else if (cb) cb({ success: false, data: [] });
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
  }
}

export function* undoInvoiceCancelSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, undoInvoiceCancel(payload));
    if (response.undoInvoiceCancel) {
      toast.success(i18n.t('common:apiMessage.undoInvoiceCancelSuccessfully'));
      if (cb) cb({ success: true, data: response.undoInvoiceCancel });
    } else if (cb) cb({ success: false, data: [] });
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
  }
}

export function* reprocessInvoiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, reprocessInvoice(payload));
    if (response.reprocessInvoice) {
      if (cb) cb({ success: true, data: response.reprocessInvoice });
      toast.success(i18n.t('common:apiMessage.processInvoiceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getOrderHistorySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOrderHistory(payload));
    if (response.getOrderHistory) {
      if (cb) cb({ success: true, data: response.getOrderHistory });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchLadingBillSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchLadingBill(payload));
    if (response.searchLadingBill) {
      if (cb) cb({ success: true, data: response.searchLadingBill });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getPacInterfaceRecordByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPacInterfaceRecordById(payload));
    if (response.getPacInterfaceRecordById) {
      if (cb) cb({ success: true, data: response.getPacInterfaceRecordById });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* saveAccountNonBillablesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, saveAccountNonBillables(payload));
    if (response.saveAccountNonBillables) {
      if (cb) cb({ success: true, data: response.saveAccountNonBillables });
      toast.success(i18n.t('common:apiMessage.saveAccountNonBillablesSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* moveAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, moveAccount(payload));
    if (response.moveAccount) {
      if (cb) cb({ success: true, data: response.moveAccount });
      toast.success(i18n.t('common:apiMessage.moveAccountSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getInvoiceTransactionSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInvoiceTransactionSummary(payload));
    if (response.getInvoiceTransactionSummary) {
      if (cb) cb({ success: true, data: response.getInvoiceTransactionSummary });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getPendingManualInvoiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPendingManualInvoice(payload));
    if (response.getPendingManualInvoice) {
      if (cb) cb({ success: true, data: response.getPendingManualInvoice });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* deletePendingManualInvoiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, deletePendingManualInvoice(payload));
    if (response.deletePendingManualInvoice) {
      if (cb) cb({ success: true, data: response.deletePendingManualInvoice });
      toast.success(i18n.t('common:apiMessage.deletePendingManualInvoiceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* saveManualInvoiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, saveManualInvoice(payload));
    if (response.saveManualInvoice) {
      if (cb) cb({ success: true, data: response.saveManualInvoice });
      toast.success(i18n.t('common:apiMessage.saveManualInvoiceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getInvoiceTaxSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInvoiceTaxSummary(payload));
    if (response.getInvoiceTaxSummary) {
      if (cb) cb({ success: true, data: response.getInvoiceTaxSummary });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* syncProjectAppSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, syncProjectApp(payload));
    if (response.syncProjectApp) {
      if (cb) cb({ success: true, data: response.syncProjectApp });
      toast.success(i18n.t('common:apiMessage.syncProjectAppSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* saveAccountMovementDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, saveAccountMovementData(payload));
    if (response.saveAccountMovementData) {
      if (cb) cb({ success: true, data: response.saveAccountMovementData });
      toast.success(i18n.t('common:apiMessage.saveAccountMovementDataSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getNotesUnitByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNotesUnitById(payload));
    if (response.getNotesUnitById) {
      if (cb) cb({ success: true, data: response.getNotesUnitById });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* resendPaymentVendorSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, resendPaymentVendor(payload));
    if (response.resendPaymentVendor) {
      if (cb) cb({ success: true, data: response.resendPaymentVendor });
      toast.success(i18n.t('common:apiMessage.resendPaymentVendorSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* resendNotesVendorSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, resendNotesVendor(payload));
    if (response.resendNotesVendor) {
      if (cb) cb({ success: true, data: response.resendNotesVendor });
      toast.success(i18n.t('common:apiMessage.resendNotesVendorSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getClientAccountStatementSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getClientAccountStatement(payload));
    if (response.getClientAccountStatement) {
      if (cb) cb({ success: true, data: response.getClientAccountStatement.accountStatement });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getUsedCfdiByTaxRegionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getUsedCfdiByTaxRegion(payload));
    if (response.getUsedCfdiByTaxRegion) {
      if (cb) cb({ success: true, data: response.getUsedCfdiByTaxRegion });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getSplitTaxPerLineSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getSplitTaxPerLine(payload));
    if (response.getSplitTaxPerLine) {
      if (cb) cb({ success: true, data: response.getSplitTaxPerLine });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* searchAccountAssetsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchAccountAssets(payload));
    if (response.searchAccountAssets) {
      yield put(actions.getPriceUnitsSuccess(response.searchAccountAssets));
      if (cb) cb({ success: true, data: response.searchAccountAssets });
    } else {
      if (cb) cb({ success: true, data: null });
      yield put(actions.getPriceUnitsSuccess([]));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* searchSubscriptionByAccountIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchSubscriptionByAccountId({ page, size, filter, sort }));
    if (response.searchSubscriptionByAccountId) {
      if (cb) cb({ success: true, data: response.searchSubscriptionByAccountId });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* subscriptionTopUpSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, subscriptionTopUp(payload));
    if (response.subscriptionTopUp) {
      toast.success(i18n.t('common:apiMessage.subscriptionTopUpSuccessfully'));
      if (cb) cb({ success: true, data: response.subscriptionTopUp });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* searchBalanceUnitByAccountIdSaga({ payload }) {
  yield put(isConnecting());
  yield put(actions.getBalanceUnitSuccess({}));
  try {
    const response = yield call(queryRequest, searchBalanceUnitByAccountId(payload));
    if (response.searchBalanceUnitByAccountId) {
      yield put(actions.getBalanceUnitSuccess(response.searchBalanceUnitByAccountId));
    } else {
      yield put(actions.getBalanceUnitSuccess({}));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(actions.getBalanceUnitSuccess({}));
    yield put(isEndConnected());
  }
}

export function* getProvisioningByAccountIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getProvisioningByAccountId(payload));
    if (response.getProvisioningByAccountId) {
      if (cb) cb({ success: true, data: response.getProvisioningByAccountId });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getMultiInvPymtInstallmentsByAccountIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getMultiInvPymtInstallmentsByAccountId(payload));
    if (response.getMultiInvPymtInstallmentsByAccountId) {
      if (cb) cb({ success: true, data: response.getMultiInvPymtInstallmentsByAccountId });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* setupMultiInvoicePaymentInstallmentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, setupMultiInvoicePaymentInstallment(payload));
    if (response.setupMultiInvoicePaymentInstallment) {
      if (cb) cb({ success: true, data: response.setupMultiInvoicePaymentInstallment });
      toast.success(i18n.t('common:apiMessage.setupMultiInvoicePaymentInstallmentSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    // yield put(actions.setupMultiInvoicePaymentInstallmentSuccess(response.setupMultiInvoicePaymentInstallment));
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* getSharingGroupSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getSharingGroup(payload));
    if (response.getSharingGroup) {
      if (cb) cb({ success: true, data: response.getSharingGroup });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* createSharingGroupSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createSharingGroup(payload));
    if (response.createSharingGroup) {
      if (cb) cb({ success: true, data: response.createSharingGroup });
      toast.success(i18n.t('common:apiMessage.createSharingGroupSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* modifySharingGroupSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifySharingGroup(payload));
    if (response.modifySharingGroup) {
      if (cb) cb({ success: true, data: response.modifySharingGroup });
      toast.success(i18n.t('common:apiMessage.modifySharingGroupSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* searchArOpsRequestsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchArOpsRequests({ page, size, filter, sort }));
    if (response.searchArOpsRequests) {
      if (cb) cb({ success: true, data: response.searchArOpsRequests });
    } else if (cb) cb({ success: false, data: null });

    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getArOpsRequestByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getArOpsRequestById(payload));
    if (response.getArOpsRequestById) {
      if (cb) cb({ success: true, data: response.getArOpsRequestById });
    } else if (cb) cb({ success: false, data: null });

    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* generatePaymentInstallmentReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, generatePaymentInstallmentReport(payload));
    if (response.generatePaymentInstallmentReport) {
      if (cb) cb({ success: true, data: response.generatePaymentInstallmentReport });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getAccountDetailForSelectParentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountDetailForSelectParent(payload));
    if (response.getAccountById) {
      if (cb) cb({ success: true, data: response.getAccountById });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: null });
  }
}

export function* getUnallocatedPaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUnallocatedPayment(payload));
    if (response.getUnallocatedPayment) {
      if (cb) cb({ success: true, data: response.getUnallocatedPayment });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: null });
  }
}

export function* getCustomersNoParentFilterSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, viewListCustomerOnly({ page, size, filter, sort }));
    yield put(actions.getCustomersSuccess(response.searchAccounts));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchAccounts });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* searchSubscriptionTopUpSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchSubscriptionTopUp({ page, size, filter, sort }));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchSubscriptionTopUp });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* customersSaga() {
  yield takeLatest(types.GET_CUSTOMERS, getCustomersSaga);
  yield takeLatest(types.GET_CUSTOMERS_PARENT, getCustomersParentSaga);
  yield takeLatest(types.CREATE_ACCOUNT, createAccountSaga);
  yield takeLatest(types.GET_ACCOUNT_DETAIL, getAccountSaga);
  yield takeLatest(types.GET_ACCOUNT_INFORMATION, getAccountInformationSaga);
  yield takeEvery(types.GET_PARENT_ACCOUNT_DETAIL, getParentAccountSaga);
  yield takeLatest(types.MODIFY_ACCOUNT, modifyAccountSaga);
  yield takeLatest(types.UPDATE_ACCOUNT_STATUS, updateAccountStatusSaga);
  yield takeLatest(types.GET_PRICE_UNITS, getPriceUnitsSaga);
  yield takeLatest(types.GET_SERVICE_UNITS_AC, getServiceUnitsAcSaga);
  yield takeLatest(types.GET_SUBSCRIPTION, getSubscriptionSaga);
  yield takeLatest(types.GET_CUSTOMERS_ID, getCustomersIdSaga);
  yield takeLatest(types.GET_TRANSACTION_UNIT, getTransactionSaga);
  yield takeLatest(types.GET_AR_ACTIVITY, getArActivitySaga);
  yield takeLatest(types.GET_CUSTOMER_ACTIVITY, getCustomerActivitySaga);
  yield takeLatest(types.GET_PENDING_BILL, getPendingBillSaga);
  yield takeLeading(types.GET_OC_BILL, getOCBillsSaga);
  yield takeEvery(types.GET_CUSTOM_ATTRIBUTES, getCustomAttributesByObjectTypeSaga);
  yield takeLatest(types.MODIFY_CUSTOM_ATTRIBUTES, modifyCustomAttributesByObjectTypeSaga);
  yield takeLatest(types.CREATE_CUSTOM_ATTRIBUTES, createCustomAttributesByObjectTypeSaga);
  yield takeLatest(types.GET_CONFIG_CREDIT_PROFILE, getConfigCreditProfileSaga);
  yield takeLatest(types.MODIFY_CONFIG_CREDIT_PROFILE, modifyConfigCreditProfileSaga);
  yield takeLatest(types.CREATE_CONFIG_CREDIT_PROFILE, createConfigCreditProfileSaga);
  yield takeLatest(types.GET_TRANSACTION_UNIT_BY_ID, getTransactionUnitByIdSaga);
  yield takeLatest(types.GET_ACTIVITY_BY_ID, getActivityByIdSaga);
  yield takeLatest(types.GET_AR_OPS_UNIT_BY_ID, getArOpsUnitByIdSaga);
  yield takeLatest(types.MANUAL_INVOICE_SERVICE, manualInvoiceServiceSaga);
  yield takeLatest(types.RUN_PENDING_BILL, runPendingBillSaga);
  yield takeLatest(types.GET_FUTURE_CYCLE_CFG, getFutureCycleCfgSaga);
  yield takeLatest(types.CREATE_FUTURE_CYCLE_CFG, createFutureCycleCfgSaga);
  yield takeLatest(types.MODIFY_FUTURE_CYCLE_CFG, modifyFutureCycleCfgSaga);
  yield takeLatest(types.SEND_INVOICE, sendInvoiceSaga);
  yield takeLatest(types.GET_EXTERNAL_BY_ACCOUNT_ID, getExternalPOByAccountIdSaga);
  yield takeLatest(types.CREATE_EXTERNAL_BY_ACCOUNT_ID, createExternalPOSaga);
  yield takeLatest(types.MODIFY_EXTERNAL_BY_ACCOUNT_ID, modifyExternalPOSaga);
  yield takeLatest(types.REGENERATE_INVOICE, regenerateInvoiceSaga);
  yield takeLatest(types.MODIFY_INVOICE_STATUS, modifyInvoiceStatusSaga);
  yield takeLatest(types.GET_ACCOUNT_STATEMENT, getAccountStatementSaga);
  yield takeLatest(types.UNDO_MANUAL_INVOICE, undoManualInvoiceSaga);
  yield takeLatest(types.SEARCH_ORDER_IN_COMPLETE, searchOrderIncompleteSaga);
  yield takeLatest(types.SEARCH_NOTE_UNITS, searchNotesUnitsSaga);
  yield takeLatest(types.GET_ACCOUNT_ASSETS, getAccountAssetsSaga);
  yield takeLatest(types.GET_SPECIAL_PRICE, getSpecialPriceByAccountIdSaga);
  yield takeLatest(types.CREATE_SPECIAL_PRICE, createSpecialPriceSaga);
  yield takeLatest(types.MODIFY_SPECIAL_PRICE, modifySpecialPriceSaga);
  yield takeLatest(types.UPDATE_CUSTOMER_ORDER_STATUS, updateOrderStatusSaga);
  yield takeLatest(types.MODIFY_NOTES_UNIT, modifyNotesUnitSaga);
  yield takeLatest(types.GET_ORDER_SUMMARY_BY_ACCOUNT_ID, getOrderSummaryByAccountIdSaga);
  yield takeLatest(types.UPDATE_BILLING_ACCOUNT, updateBillingAccountSaga);
  yield takeLatest(types.MODIFY_INVOICE_DUE_DATE, modifyInvoiceDueDateSaga);
  yield takeLatest(types.GET_EXCHANGE_RATE_BY_ACCOUNT_ID, getAccountExchangeRatesSaga);
  yield takeLatest(types.MODIFY_EXCHANGE_RATE_BY_ACCOUNT_ID, modifyAccountExchangeRatesSaga);
  yield takeLatest(types.CREATE_EXCHANGE_RATE_BY_ACCOUNT_ID, createAccountExchangeRatesSaga);
  yield takeLatest(types.GET_PREVIOUS_ACCOUNT_MERGE, getPreviousAccountMergeSaga);
  yield takeLatest(types.INVOICE_ACCOUNT, invoiceAccountSaga);
  yield takeLatest(types.GET_PREVIOUS_ACCOUNT_MOVEMENT, getPreviousAccountMovementSaga);
  yield takeLatest(types.GET_PAYMENT_INSTALLMENT_BY_ACCOUNTID, getPaymentInstallmentByAccountIdSaga);
  yield takeLatest(types.CREATE_PAYMENT_INSTALLMENT_BY_ACCOUNTID, createPaymentInstallmentSaga);
  yield takeLatest(types.MODIFY_PAYMENT_INSTALLMENT_BY_ACCOUNTID, modifyPaymentInstallmentSaga);
  yield takeLatest(types.GET_INVOICE_INSTALLMENTS_BY_INVOICEID, getInvoiceInstallmentsByInvoiceIdSaga);
  yield takeLatest(types.SEARCH_USAGE_TRANSACTION, searchUsageTransactionSaga);
  yield takeLatest(types.GET_TRANSACTION_UNIT_BY_ID_USAGE, getTransactionUnitByIdUsageSaga);
  yield takeLatest(types.RESEND_INVOICE_VENDOR, resendInvoiceVendorSaga);
  yield takeLatest(types.UNDO_INVOICE_CANCEL, undoInvoiceCancelSaga);
  yield takeLatest(types.RE_PROCESS_INVOICE, reprocessInvoiceSaga);
  yield takeLatest(types.GET_ORDER_HISTORY, getOrderHistorySaga);
  yield takeLatest(types.SEARCH_LADING_BILL, searchLadingBillSaga);
  yield takeLatest(types.GET_PAC_INTERFACE_RECORD_BY_ID, getPacInterfaceRecordByIdSaga);
  yield takeLatest(types.SAVE_ACCOUNT_NON_BILLABLES, saveAccountNonBillablesSaga);
  yield takeLatest(types.MOVE_ACCOUNT, moveAccountSaga);
  yield takeLatest(types.GET_INVOICE_TRANSACTION_SUMMARY, getInvoiceTransactionSummarySaga);
  yield takeLatest(types.GET_PENDING_MANUAL_INVOICE, getPendingManualInvoiceSaga);
  yield takeLatest(types.DELETE_PENDING_MANUAL_INVOICE, deletePendingManualInvoiceSaga);
  yield takeLatest(types.SAVE_MANUAL_INVOICE, saveManualInvoiceSaga);
  yield takeLatest(types.GET_INVOICE_TAX_SUMMARY, getInvoiceTaxSummarySaga);
  yield takeLatest(types.SYNC_PROJECT_APP, syncProjectAppSaga);
  yield takeLatest(types.SAVE_ACCOUNT_MOVEMENT_DATA, saveAccountMovementDataSaga);
  yield takeLatest(types.GET_NOTES_UNIT_BY_ID, getNotesUnitByIdSaga);
  yield takeLatest(types.RESEND_PAYMENT_VENDOR, resendPaymentVendorSaga);
  yield takeLatest(types.RESEND_NOTES_VENDOR, resendNotesVendorSaga);
  yield takeLatest(types.GET_CLIENT_ACCOUNT_STATEMENT, getClientAccountStatementSaga);
  yield takeLatest(types.GET_USED_CFDI_BY_TAX_REGION, getUsedCfdiByTaxRegionSaga);
  yield takeLatest(types.SEARCH_ACCOUNTS_ASSETS, searchAccountAssetsSaga);
  yield takeLatest(types.SEARCH_SUBSCRIPTION_BY_ACCOUNT_ID, searchSubscriptionByAccountIdSaga);
  yield takeLatest(types.SUBSCRIPTION_TOP_UP, subscriptionTopUpSaga);
  yield takeLatest(types.SEARCH_BALANCE_UNIT_BY_ACCOUNT_ID, searchBalanceUnitByAccountIdSaga);
  yield takeLatest(types.GET_PROVISIONING_BY_ACCOUNT_ID, getProvisioningByAccountIdSaga);
  yield takeLatest(types.GET_MULTI_INV_PYMT_INSTALLMENTS_BY_ACCOUNT_ID, getMultiInvPymtInstallmentsByAccountIdSaga);
  yield takeLatest(types.SETUP_MULTI_INVOICE_PAYMENT_INSTALLMENT, setupMultiInvoicePaymentInstallmentSaga);
  yield takeLatest(types.GET_SHARING_GROUP, getSharingGroupSaga);
  yield takeLatest(types.CREATE_SHARING_GROUP, createSharingGroupSaga);
  yield takeLatest(types.MODIFY_SHARING_GROUP, modifySharingGroupSaga);
  yield takeLatest(types.SEARCH_ALL_AR_OPS_REQUESTS, searchArOpsRequestsSaga);
  yield takeLatest(types.GET_AR_OPS_REQUEST_BY_ID, getArOpsRequestByIdSaga);
  yield takeLatest(types.GET_SPLIT_TAX_PER_LINE, getSplitTaxPerLineSaga);
  yield takeLatest(types.GENERATE_PAYMENT_INSTALLMENT_REPORT, generatePaymentInstallmentReportSaga);
  yield takeLatest(types.GET_ACCOUNT_DETAIL_FOR_SELECT_PARENT, getAccountDetailForSelectParentSaga);
  yield takeLatest(types.GET_UNALLOCATED_PAYMENT, getUnallocatedPaymentSaga);
  yield takeLatest(types.GET_CUSTOMERS_NO_PARENT_FILTER, getCustomersNoParentFilterSaga);
  yield takeLatest(types.SEARCH_SUBSCRIPTION_TOP_UP, searchSubscriptionTopUpSaga);
}
